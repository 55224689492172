
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    background: 'white',
    color: 'black',
    borderRadius: '10px',
    padding: '20px',
    width: '95%'
  }
}));

export const StyledCloseIcon = styled(IconButton)(() => ({
  position: 'absolute',
  top: '17px',
  right: '21px',
  width: '25px',
  height: '25px',
  color: 'black',
  cursor: 'pointer',
}));

export const StyledDialogContentText = styled(DialogContentText)(() => ({
    color: "#808080",
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
    padding: "20px 0"
}));

export const DialogStack = styled(Stack)(() => ({
  backgroundColor: '#F9F9F9',
  borderRadius: '20px',
  marginTop: '20px 0',
  padding: '20px 20px',
  width: '100%',
  justifyContent: "space-around",
  overflowX: "scroll"
}));


export const StyledDialogTitle = styled(DialogTitle)(
    () => ({
        fontSize: "24px",
        padding: 0
    })
)


export const StyledDialogContainer = styled(Box)(() => ({

}));

