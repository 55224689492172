import axios from "axios";
import { STORAGE_KEYS } from "../../../../constants/storage";
import { getDomain } from "../../../../utils";
import { getValueFromLocalStore } from "../../../../utils/localStorage";

const IMPORT_CONTENT = "/api/import"
const BULK_PUBLISH = "/api/articles/publish-bulk"
const BULK_DELETE = "/api/articles/delete-bulk"
const BULK_UNPUBLISH = "/api/articles/unpublish-bulk"

export const importContent = async (url: string): Promise<any> => {
    try {
        const endpoint = getDomain() + `${IMPORT_CONTENT}`;

        const response = await axios.post(
            endpoint,
            { url },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        
        return response.data;
    } catch (error: any) {
        console.log(error)
        throw {
            message: error?.response?.data?.error?.message ?? error?.message,
            status: error.response ? error.response.status : 500,
        };
    }
};

export const bulkPublish = async (articleIds: any[]): Promise<any> => {
    try {
        const endpoint = getDomain() + `${BULK_PUBLISH}`;

        const response = await axios.post(
            endpoint,
            { articleIds },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        
        throw {
            message: error?.response?.data?.error?.message ?? error?.message,
            status: error.response ? error.response.status : 500,
        };
    }
};

export const bulkDelete = async (articleIds: any[]): Promise<any> => {
    try {
        const endpoint = getDomain() + `${BULK_DELETE}`;

        const response = await axios.post(
            endpoint,
            { articleIds },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        
        throw {
            message: error?.response?.data?.error?.message ?? error?.message,
            status: error.response ? error.response.status : 500,
        };
    }
};

export const bulkUnpublish = async (articleIds: any[]): Promise<any> => {
    try {
        const endpoint = getDomain() + `${BULK_UNPUBLISH}`;

        const response = await axios.post(
            endpoint,
            { articleIds },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        
        throw {
            message: error?.response?.data?.error?.message ?? error?.message,
            status: error.response ? error.response.status : 500,
        };
    }
};