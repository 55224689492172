
import { Button, IconButton, Menu, TableCell, TableRow, useTheme } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { FC, MouseEvent, useState } from 'react';
import moment from 'moment';
import { SubscriberData } from './types';
import { StyledMenuItem } from './styles';
import ViewIcon from '../../../../assets/icons/DashboardIcons/View';
import { UploadIcon } from '../../../../assets/icons/UploadIcon';
import { Link } from 'react-router-dom';


interface TableRowProps {
    row: SubscriberData;
    isMobile: boolean;
    index: number;
    // eslint-disable-next-line no-unused-vars
    setLink: (link: string) => void;
    // eslint-disable-next-line no-unused-vars
    setCopyDialog(value: boolean): void;
}

export const CustomTableRow: FC<TableRowProps> = ({ row, isMobile, index, setLink, setCopyDialog }) => {
    const normalize = (str: string) => {
        if (!str) return '-';
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const theme = useTheme();
    const formatDate = (date: Date) => moment(date).format('MMMM D, YYYY');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const copyLink = () => {
        setLink(`${window.location.origin}/${row?.subscriber.uid ?? ''}`)
        setCopyDialog(true)
    }


    const getStatusColor = (status: boolean) => {
        let color = {
            background: '#000',
            border: '#fff',
        };
        switch (status) {
            case false:
                color.background = '#E5E4E533';
                color.border = '#E5E4E5';

                break;
            case true:
                color.background = '#00C71333';
                color.border = '#00C713';
                break;
            default:
                color.background = '#fff';
                color.border = 'none';
                break;
        }
        return color;
    };
    return (
        <TableRow
            key={row.id}
            sx={{
                '& td, & th': {
                    border: 0,
                },

                '& th': {
                    fontSize: isMobile ? '14px' : '16px',
                    fontFamily: 'neue-montreal-regular',
                    lineHeight: '20px',
                    maxWidth: '264px',
                },

                '& td': {
                    fontFamily: 'neue-montreal-regular',
                    fontSize: isMobile ? '12px' : '14px',
                },

                background: '#fff',
                borderRadius: '20px',
                // marginBottom: '8px',
            }}
        >
            <TableCell align="center" sx={{ color: theme.palette.secondary.main }}>
                {row.subscriber?.email}
            </TableCell>
            <TableCell align="center">
                {row?.subscription_date
                    ? formatDate(row?.subscription_date)
                    : '-'}
            </TableCell>
            <TableCell align="center">
                {row?.source}
            </TableCell>
            <TableCell align="center">
                <Button
                    sx={{
                        borderRadius: '8px',
                        textTransform: 'capitalize',
                        padding: '2px 10px',
                        fontFamily: 'neue-montreal-regular',
                        background: getStatusColor(
                            row?.is_active ?? false
                        ).background,
                        fontSize: isMobile ? 12 : 14,
                        border: `1px solid ${getStatusColor(row?.is_active ?? false).border}`,

                        '&:hover': {
                            background: getStatusColor(
                                row?.is_active ?? false
                            ).background,
                        },
                    }}
                >
                    {row?.is_active ? "Active" : "Inactive"}
                </Button>
            </TableCell>
            <TableCell align="center">
                {normalize(row?.subscription_type || '-')}
            </TableCell>
            <TableCell align="center">
                {row.subscriber?.user_profile?.user_location ?? '-'}
            </TableCell>
            <TableCell
                sx={{
                    borderStyle: 'solid',
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                }}
                align="center"
            >
                <IconButton
                    onClick={(e) => handleClick(e)}
                    id={`menu-btn-${index}`}
                    aria-controls={
                        open ? `basic-menu-${index}` : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                        width: 40,
                        height: 40,
                        border: '1px solid #E5E4E5',
                        borderRadius: '10px',
                    }}
                >
                    <MoreHoriz />
                </IconButton>
                <Menu
                    id={`basic-menu-${index}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': `menu-btn-${index}`,
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    sx={{
                        boxShadow: 'unset',
                        '& .MuiPaper-root, .MuiMenu-root, .MuiMenu-paper':
                        {
                            // boxShadow: 'unset',
                            borderRadius: '10px',
                            width: '140px',
                            marginTop: '8px',
                            boxShadow:
                                '0px 4px 10px 0px rgba(0,0,0,0.05)',
                        },
                    }}
                >
                    <StyledMenuItem
                    >
                        <Link to={`/${row?.subscriber?.uid}`} style={{ textDecoration: 'none', color: 'inherit', display: "flex", justifyItems: "center", gap: "6px" }}>
                            <ViewIcon />
                            View Profile
                        </Link>
                    </StyledMenuItem>
                    <StyledMenuItem
                        onClick={copyLink}
                    >
                        <UploadIcon />
                        Share Profile
                    </StyledMenuItem>
                    {/* <StyledMenuItem onClick={() => { }}>
                        <ReportIcon />
                        Report
                    </StyledMenuItem>
                    <StyledMenuItem>
                        <BlockIcon />
                        Block
                    </StyledMenuItem> */}
                </Menu>
            </TableCell>
        </TableRow>
    )
};

export default TableRow;
