import {
    Box,
    Button,
    IconButton,
    Menu,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
} from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import {
    StyledMenuItem,
    StyledPaginationButton,
    StyledPaginationInput,
    StyledTypography,
} from './styles';
import moment from 'moment';
import { Close, East, MoreHoriz, West } from '@mui/icons-material';
import AlertDialog from '../../../common/AlertDialog';
import { DIALOG } from '../../../ContentTable/constants';
import { useLoading } from '../../../../context/LoadingContext';
import { cancelSubscription } from '../../../../apis/Subscription';
import { identifyAndTrackUnSubscribed } from '../../../SegmentAnalytics';
import useAuth from '../../../../hooks/useAuth';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ViewIcon from '../../../../assets/icons/DashboardIcons/View';
import { UploadIcon } from '../../../../assets/icons/UploadIcon';
import { CopyLinkDialog } from '../CopyLinkDialog/Index';

interface Props {
    records: any[];
    isMobile: boolean;
    total: number;
    page: number;
    setPage: any;
    limit: any;
    getContent: any;
}

const CustomTable: FC<Props> = ({
    records,
    isMobile,
    total,
    page,
    setPage,
    limit,
    getContent,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const formatDate = (date: Date) => moment(date).format('MMMM D, YYYY');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentRow, setCurrentRow] = useState<any>();
    const [paginationPage, setPaginationPage] = useState<number>(page + 1);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>, row: any) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };
    const handleClose = () => {
        setAnchorEl(null);
        // setCurrentRow(null);
    };

    const [dialog, setDialog] = useState<{
        open: boolean;
        title: string;
        description: string;
        response: boolean;
        status?: string;
        uid?: any;
        row?: any;
    }>({
        open: false,
        title: '',
        description: '',
        response: false,
        status: '',
        uid: '',
        row: {},
    });

    const onPaginationInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.value === '' || /^\d+$/.test(event.target.value)) {
            const value = Number(event.target.value);
            if (value > getTotalPage()) {
                return;
            }
            if (value < 1) {
                setPaginationPage(paginationPage);
            }
            setPaginationPage(Number(value));
        }
    };

    const { setSiteLoader } = useLoading();
    const { userInfo } = useAuth();

    const unsubscribe = async (dialog: any) => {
        setSiteLoader(true);
        try {
            const response = await cancelSubscription(dialog.uid);
            if (response) {
                identifyAndTrackUnSubscribed({
                    subscription: { type: dialog.row.subscription_type },
                    user: userInfo,
                    creator: { id: dialog.uid },
                });
                await getContent();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSiteLoader(false);
        }
    };

    const initUnsubscribe = () => {
        setDialog({
            open: true,
            title: DIALOG.TITLE.UNSUBSCRIBE,
            description: DIALOG.DESCRIPTION.UNSUBSCRIBE,
            response: false,
            status: 'unsubscribe',
            uid: currentRow?.author?.user.uid,
            row: currentRow,
        });
    };

    const [link, setLink] = useState<string>('');
    const [copyDialog, setCopyDialog] = useState<boolean>(false);

    const handleCopyDialog = () => setCopyDialog(false);

    const copyLink = () => {
        setLink(
            `${window.location.origin}/${currentRow?.author?.user?.uid ?? ''}`,
        );
        setCopyDialog(true);
    };

    const getStatusColor = (status: boolean) => {
        let color = {
            background: '#000',
            border: '#fff',
        };
        switch (status) {
            case false:
                color.background = '#E5E4E533';
                color.border = '#E5E4E5';

                break;
            case true:
                color.background = '#00C71333';
                color.border = '#00C713';
                break;
            default:
                color.background = '#fff';
                color.border = 'none';
                break;
        }
        return color;
    };

    useEffect(() => {
        if (dialog.response && dialog.status === 'unsubscribe') {
            unsubscribe(dialog);
            handleClose();
        }
    }, [dialog.response]);

    const nextPage = () => {
        setPage(page + 1);
        setPaginationPage(paginationPage + 1);
    };

    const prevPage = () => {
        setPage(page - 1);
        setPaginationPage(paginationPage - 1);
    };

    const getTotalPage = () => {
        return Math.ceil(Number(total) / Number(limit));
    };
    return (
        <TableContainer sx={{ height: '100%' }}>
            <Table
                stickyHeader
                sx={{
                    minWidth: 650,
                    maxHeight: '100%',
                    borderCollapse: 'separate',
                    borderSpacing: '0px 8px',
                }}
                aria-label="simple table"
            >
                <TableHead
                    sx={{
                        background: '#fff',
                        borderRadius: '20px',
                        marginBottom: '12px',
                    }}
                >
                    <TableRow
                        sx={{
                            borderRadius: '20px',

                            '& th': {
                                textTransform: 'uppercase',
                                fontFamily: 'neue-montreal-regular',
                                fontSize: isMobile ? '10px' : '14px',
                                border: 0,
                                padding: '14px',
                                minWidth: '120px',
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <TableCell
                            sx={{
                                borderStyle: 'solid',
                                borderTopLeftRadius: '20px',
                                borderBottomLeftRadius: '20px',
                            }}
                            align="left"
                        >
                            Email
                        </TableCell>
                        <TableCell align="left">Subscribed on</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Country</TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                borderStyle: 'solid',
                                borderTopRightRadius: '20px',
                                borderBottomRightRadius: '20px',
                            }}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row, index) => (
                        <TableRow
                            key={row.index}
                            sx={{
                                // '& td, & th': {
                                //     border: 0,
                                // },

                                '& .MuiTableRow-root': {
                                    height: '56px',
                                    padding: '14px',
                                },

                                '& th': {
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontFamily: 'neue-montreal-regular',
                                    lineHeight: '20px',
                                    maxWidth: '264px',
                                },

                                '& td': {
                                    fontFamily: 'neue-montreal-regular',
                                    fontSize: isMobile ? '12px' : '14px',
                                    height: '56px',
                                },

                                // background: '#fff',
                                borderRadius: '20px',
                                padding: '14px',
                                // marginBottom: '8px',
                            }}
                        >
                            <TableCell
                                align="left"
                                sx={{
                                    color: theme.palette.secondary.main,
                                    textDecoration: 'underline',
                                }}
                            >
                                <span>{row?.author?.user?.email || '-'}</span>
                            </TableCell>
                            <TableCell align="left">
                                {formatDate(row?.subscription_date || '')}
                            </TableCell>
                            <TableCell align="left">
                                {capitalize(row?.subscription_type) || '-'}
                            </TableCell>
                            <TableCell align="left">
                                <Button
                                    sx={{
                                        borderRadius: '8px',
                                        textTransform: 'capitalize',
                                        padding: '2px 10px',
                                        fontFamily: 'neue-montreal-regular',
                                        background: getStatusColor(
                                            row?.is_active ?? false,
                                        ).background,
                                        fontSize: isMobile ? 12 : 14,
                                        border: `1px solid ${
                                            getStatusColor(
                                                row?.is_active ?? false,
                                            ).border
                                        }`,

                                        '&:hover': {
                                            background: getStatusColor(
                                                row?.is_active ?? false,
                                            ).background,
                                        },
                                    }}
                                >
                                    {row?.is_active ? 'Active' : 'Inactive'}
                                </Button>
                            </TableCell>

                            <TableCell align="left">
                                {row?.author?.user?.user_profile
                                    ?.user_location || '-'}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    onClick={(e) => handleClick(e, row)}
                                    id={`menu-btn-${index}`}
                                    aria-controls={
                                        open ? `basic-menu-${index}` : undefined
                                    }
                                    aria-haspopup="true"
                                    disabled={row.article_status === 'deleted'}
                                    aria-expanded={open ? 'true' : undefined}
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        border: '1px solid #E5E4E5',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <MoreHoriz />
                                </IconButton>
                                <Menu
                                    id={`basic-menu-${index}`}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': `menu-btn-${index}`,
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    sx={{
                                        boxShadow: 'unset',
                                        '& .MuiPaper-root, .MuiMenu-root, .MuiMenu-paper':
                                            {
                                                // boxShadow: 'unset',
                                                borderRadius: '10px',
                                                width: '140px',
                                                marginTop: '8px',
                                                boxShadow:
                                                    '0px 4px 10px 0px rgba(0,0,0,0.05)',
                                            },

                                        '& .MuiPaper-elevation8 ul': {
                                            padding: '8px 0px !important',
                                        },
                                    }}
                                >
                                    <StyledMenuItem
                                        onClick={() =>
                                            navigate(
                                                `/${currentRow?.author?.user?.uid}`,
                                            )
                                        }
                                    >
                                        <ViewIcon />
                                        View Profile
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={copyLink}>
                                        <UploadIcon />
                                        Share Profile
                                    </StyledMenuItem>
                                    <StyledMenuItem
                                        onClick={() => {
                                            initUnsubscribe();
                                        }}
                                    >
                                        <Close />
                                        Unsubscribe
                                    </StyledMenuItem>
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* Pagination */}
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                mt={'16px'}
            >
                {!isMobile && <Box width={'100px'}></Box>}
                <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                    <StyledPaginationButton
                        disabled={page <= 0}
                        onClick={prevPage}
                    >
                        <West />
                    </StyledPaginationButton>
                    <StyledPaginationButton
                        disabled={page + 1 >= getTotalPage()}
                        onClick={nextPage}
                    >
                        <East />
                    </StyledPaginationButton>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={'5px'}>
                    <StyledTypography>Page</StyledTypography>
                    {/* <StyledTypography
                        style={{
                            padding: '2px 20px',
                            border: '1px solid #E5E4E5',
                            borderRadius: '8px',
                            background: '#fff',
                        }}
                    >
                        {page + 1}
                    </StyledTypography> */}
                    <StyledPaginationInput
                        disableUnderline
                        type="text"
                        value={paginationPage}
                        onChange={onPaginationInputChange}
                        onBlur={() => setPage(Number(paginationPage) - 1)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                setPage(Number(paginationPage) - 1);
                            }
                        }}
                    />
                    <StyledTypography>of</StyledTypography>
                    <StyledTypography> {getTotalPage()}</StyledTypography>
                </Stack>
            </Stack>

            <AlertDialog dialog={dialog} setDialog={setDialog} />
            <CopyLinkDialog
                isOpen={copyDialog}
                onClose={handleCopyDialog}
                title={'Copy User Profile'}
                description={'Copy user profile to clipboard!'}
                link={link}
            />
        </TableContainer>
    );
};

export default CustomTable;
