import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArticleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="16" 
            height="16"
            fill="none"
            {...props}
        >
            <path d="M9.59098 3.88743L12.1127 6.4091M2 14L4.44852 13.5339C4.96768 13.4351 5.44513 13.1824 5.81882 12.8087L13.4558 5.17169C14.1814 4.44612 14.1814 3.26974 13.4558 2.54418C12.7303 1.81861 11.5539 1.81861 10.8283 2.54418L3.19131 10.1812C2.81761 10.5549 2.56493 11.0323 2.46611 11.5515L2 14Z" stroke="#2C36F0" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  )
}

export default ArticleIcon