/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react'
import useAuth from '../hooks/useAuth';

export interface INotificationContext {
    unread: number;
}
export type NotificationContextType = {
    notificationInfo: INotificationContext;
    updateNotification: any
};

export const NotificationContext = React.createContext<NotificationContextType | null>(null);

interface NotificationProviderProps {
    children: React.ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [notificationInfo, setNotificationInfo] = React.useState<INotificationContext>({
        unread: 0,
    });
    const { userInfo } = useAuth()
    useEffect(() => {
        if (userInfo.isUserLoggedIn) {
            import('../apis/Notifications').then(({ fetchUserNotifications }) => {

                fetchUserNotifications(0)
                    .then((response: any) => {
                        setNotificationInfo({
                            ...notificationInfo,
                            unread: response?.unreadCount
                        })
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
        }
    }, [userInfo])
    const updateNotification = (info: INotificationContext) => {
        setNotificationInfo({ ...notificationInfo, ...info })
    }
    return (
        <NotificationContext.Provider value={{ notificationInfo, updateNotification }}>
            {children}
        </NotificationContext.Provider>
    )
}

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotification must be used within NotificationProvider");
    }
    return context;
}