/* eslint-disable no-unused-vars */
import { FC } from "react";
import ContentTable from "./ContentTable";
import { Box, Stack } from "@mui/material";
import { StyledTypography } from "../../styles";
import { Content } from "../interfaces";

export const ContentDisplay: FC<{
    records: Content[];
    page: number;
    setPage: (page: number) => void;
    total: number;
    isMobile: boolean;
    rowsPerPage: number;
    reloadData: () => void;
    handleFilterChange: (event: any) => void;
    filterOptions: { name: string, value: string }[];
    filter: string;
    source: string;
}> = ({
    records,
    page,
    setPage,
    total,
    isMobile,
    rowsPerPage,
    reloadData,
    handleFilterChange,
    filterOptions,
    filter,
    source
}) => {
        return (
            <>
                <ContentTable
                    isMobile={isMobile}
                    records={records}
                    page={page}
                    setPage={setPage}
                    total={total}
                    limit={rowsPerPage}
                    reloadData={reloadData}
                    handleFilterChange={handleFilterChange}
                    filterOptions={filterOptions}
                    filter={filter}
                    source={source}
                />
                {(records.length === 0 && filter === "all") &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '40vh',
                        }}
                    >
                        <Stack
                            direction={'column'}
                            gap={'40px'}
                            alignItems={'center'}
                        >
                            <StyledTypography
                                sx={{
                                    fontSize: isMobile ? '20px' : '24px',
                                    textAlign: 'center',
                                    lineHeight: isMobile ? '28px' : '32px',
                                }}
                            >
                                Once you import your first content, it will appear here.
                            </StyledTypography>

                        </Stack>
                    </Box>
                }
                {(records.length === 0 && filter !== "all") &&
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '40vh',
                    }}
                >
                    <Stack
                        direction={'column'}
                        gap={'40px'}
                        alignItems={'center'}
                    >
                        <StyledTypography
                            sx={{
                                fontSize: isMobile ? '20px' : '24px',
                                textAlign: 'center',
                                lineHeight: isMobile ? '28px' : '32px',
                            }}
                        >
                            No records found
                        </StyledTypography>

                    </Stack>
                </Box>
                }


            </>
        );
    };