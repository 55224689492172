import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ViewIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.9095 13.1718C2.56793 12.4286 2.56793 11.5714 2.9095 10.8282C4.4906 7.38843 7.96659 5 12.0004 5C16.0343 5 19.5102 7.38843 21.0913 10.8282C21.4329 11.5714 21.4329 12.4286 21.0913 13.1718C19.5102 16.6116 16.0343 19 12.0004 19C7.96659 19 4.4906 16.6116 2.9095 13.1718Z"
                stroke="black"
                fill="none"
                strokeOpacity="0.7"
                strokeWidth="1.5"
            />
            <path
                d="M15.0004 12C15.0004 13.6569 13.6573 15 12.0004 15C10.3436 15 9.00042 13.6569 9.00042 12C9.00042 10.3431 10.3436 9 12.0004 9C13.6573 9 15.0004 10.3431 15.0004 12Z"
                stroke="black"
                fill="none"
                strokeOpacity="0.7"
                strokeWidth="1.5"
            />
        </SvgIcon>
    );
};

export default ViewIcon;
