/* eslint-disable no-unused-vars */
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import {
    StyledImg,
    StyledMenuItem,
    StyledPaginationButton,
    StyledPaginationInput,
    StyledTypography,
} from '../../styles';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Check, East, ExpandMore, MoreHoriz, West } from '@mui/icons-material';
import ViewIcon from '../../../../../assets/icons/DashboardIcons/View';
import EditIcon from '../../../../../assets/icons/DashboardIcons/Edit';
import DuplicateIcon from '../../../../../assets/icons/DashboardIcons/Duplicate';
import ArchiveIcon from '../../../../../assets/icons/DashboardIcons/Archive';
import { Content } from '../interfaces';
import CreateArticleIcon from '../../../../../assets/icons/EditIcon';
import EventIcon from '../../../../../assets/icons/EventIcon';
import CreateCommentIcon from '../../../../../assets/icons/CreateContentIcons/CreateCommentIcon';
import PlayArrowIcon from '../../../../../assets/icons/PlayArrowIcon';
import { ActionBar, StyledCheckbox } from './styles';
import { Unpublish } from '../../../../../assets/icons/UnpublishIcon';
import { PublishIcon } from '../../../../../assets/icons/PublishIcon';
import { DeleteIcon } from '../../../../../assets/icons/Delete';
import { StyledCloseIcon } from '../ImportDialog/styles';
import CloseIcon from '../../../../../assets/icons/CloseIcon';
import { bulkDelete, bulkPublish, bulkUnpublish } from '../api';
import { useLoading } from '../../../../../context/LoadingContext';
import { ROUTES } from '../../../../../constants/routeDefinition';
import { useNavigate } from 'react-router';
import { convertTitleToSlug } from '../../../../../helper';
import { CustomTooltip } from '../../../../common/CustomTooltip';
import { duplicateArticle, updateArticle } from '../../../../../apis/Article';
import { CONTENT_STATUS, CONTENT_TYPE } from '../../../../../constants';

export const getContentTypeIcon = (type: string, isMobile: boolean) => {
    let icon;

    switch (type) {
        case 'comment':
            icon = (
                <CreateCommentIcon
                    sx={{
                        width: isMobile ? 24 : 36,
                        height: isMobile ? 24 : 36,
                    }}
                />
            );
            break;

        case 'article':
            icon = (
                <CreateArticleIcon
                    sx={{
                        width: isMobile ? 24 : 36,
                        height: isMobile ? 24 : 36,
                        '& path': { stroke: '#808080' },
                    }}
                />
            );
            break;

        case 'video':
            icon = (
                <PlayArrowIcon
                    sx={{
                        width: isMobile ? 24 : 36,
                        height: isMobile ? 24 : 36,
                        '& path': { fill: '#808080' },
                    }}
                />
            );
            break;

        case 'event':
            icon = (
                <EventIcon
                    sx={{
                        width: isMobile ? 24 : 36,
                        height: isMobile ? 24 : 36,
                        '& path': { stroke: '#808080' },
                    }}
                />
            );
            break;

        default:
            icon = (
                <CreateArticleIcon
                    sx={{
                        width: isMobile ? 24 : 36,
                        height: isMobile ? 24 : 36,
                        '& path': { stroke: '#808080' },
                    }}
                />
            );
            break;
    }

    return icon;
};


interface Props {
    records: Content[];
    isMobile: boolean;
    total: number;
    page: number;
    setPage: any;
    limit: any;
    reloadData: () => void;
    handleFilterChange: (event: any) => void;
    filterOptions: { name: string, value: string }[];
    filter: string;
    source: string
}


const ContentTable: FC<Props> = ({
    records,
    isMobile,
    total,
    page,
    setPage,
    limit,
    reloadData,
    handleFilterChange,
    filterOptions,
    filter,
    source
}) => {

    const formatDate = (date: Date) => moment(date).format('MMMM D, YYYY');
    const getStatusColor = (status: string) => {
        let color = {
            background: '#000',
            border: '#fff',
        };
        switch (status) {
            case 'draft':
                color.background = '#E5E4E533';
                color.border = '#E5E4E5';
                break;

            case 'published':
                color.background = '#00C71333';
                color.border = '#00C713';
                break;

            case 'archived':
                color.background = '#FFD60033';
                color.border = '#FFD600';
                break;
            case 'deleted':
                color.background = '#FF001D33';
                color.border = '#FF001D';
                break;

            default:
                break;
        }

        return color;
    };

    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const navigate = useNavigate();

    const toggleRowSelection = (articleId: number) => {
        setSelectedRows(prevState => ({
            ...prevState,
            [articleId]: !prevState[articleId]
        }));
    };

    const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({});
    // const [currentRows, setCurrentRows] = useState<Record<string, any>>({});

    const [confirmationAction, setConfirmationAction] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [disableActions, setDisableActions] = useState<boolean>(true);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [hoverState, setHoverState] = useState({
        unpublish: false,
        publish: false,
        delete: false
    });
    const [dialog, setDialog] = useState<boolean>(false)
    const [paginationPage, setPaginationPage] = useState<number>(page + 1);
    const { setSiteLoader } = useLoading();

    const onPaginationInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.value === '' || /^\d+$/.test(event.target.value)) {
            const value = Number(event.target.value);
            if (value > getTotalPage()) {
                return;
            }
            if (value < 1) {
                setPaginationPage(paginationPage);
            }
            setPaginationPage(Number(value));
        }
    };


    const handleClick = (event: MouseEvent<HTMLButtonElement>, row: any) => {
        setAnchorEls(prevState => ({
            ...prevState,
            [row.id]: event.currentTarget
        }));
        // setCurrentRows(prevState => ({
        //     ...prevState,
        //     [row.id]: row
        // }));
    };

    const handleClose = (rowId?: number) => {
        if (rowId) {
            setAnchorEls(prevState => ({
                ...prevState,
                [rowId]: null
            }));
            // setCurrentRows(prevState => ({
            //     ...prevState,
            //     [rowId]: null
            // }));
            // setSelectedRows(prevState => ({
            //     ...prevState,
            //     [rowId]: false
            // }));
            setSelectedRows({})

        } else {
            setAnchorEls({});
            setSelectedRows({});
            setSelectedAll(false);
        }
    };

    const closeDialog = () => {
        setDialog(false);
    };

    const handleOnViewClick = (row: Content) => {
        const url = `${ROUTES.CONTENT.BASE_PATH}/${row?.article_id
            }/${convertTitleToSlug(row?.title)}`;
        navigate(url);
    };

    const handleOnEditClick = (row: Content) => {
        navigate(ROUTES.ARTICLE_CONTENT.PATH, {
            state: {
                id: row.id,
                articleStatus: row.article_status,
                contentType: row.content_type,
            },
        });
    };

    const handleOnPublishClick = (row: Content) => {
        const isTitleValid = !row?.title?.trim()
                        ? true
                        : false

        const data: any = {
            title: row.title,
            contentType: CONTENT_TYPE.ARTICLE,
            thumbnailFile:row.thumbnail,
        };
        navigate(ROUTES.PREVIEW_ARTICLE.PATH, {
            state: {

                previewId: row.id,
                articleStatus: row.article_status,
                preview: true,
                originUrl: ROUTES.DASHBOARD_IMPORT_CONTENT.PATH,
                data,
                // origin: ROUTES.DASHBOARD_IMPORT_CONTENT
                // challengeId: state?.challengeId,
            },
        });
    };

    const nextPage = () => {
        setPage(page + 1);
        setPaginationPage(paginationPage + 1);
    };

    const prevPage = () => {
        setPage(page - 1);
        setPaginationPage(paginationPage - 1);
    };

    const getTotalPage = () => {
        return Math.ceil(Number(total) / Number(limit));
    };

    const resetSelect = () => {
        records.forEach(item => {
            setSelectedRows(prevState => ({
                ...prevState,
                [item.id]: false
            }));
        })
    }

    const handleActionConfirmation = () => {

        const articleIds = Object.keys(selectedRows).filter(key => selectedRows[key] === true)
        // Handle action confirmation based on confirmationAction state
        switch (confirmationAction) {

            case 'publish':
                setSiteLoader(true);
                bulkPublish(articleIds)
                    .then()
                    .catch()
                    .finally(async () => {
                        handleClose()
                        setPage(0)
                        setPaginationPage(1)
                        resetSelect()
                        await reloadData()
                        setSiteLoader(false)
                    })

                // Handle publish action
                break;
            case 'unpublish':
                bulkUnpublish(articleIds)
                    .then()
                    .catch()
                    .finally(async () => {
                        handleClose()
                        setPage(0)
                        setPaginationPage(1)
                        resetSelect()
                        await reloadData()
                        setSiteLoader(false)
                    })
                break;
            case 'delete':
                bulkDelete(articleIds)
                    .then()
                    .catch()
                    .finally(async () => {
                        handleClose()
                        setPage(0)
                        setPaginationPage(1)
                        await reloadData()
                        setSiteLoader(false)
                    })
                break;

            case 'duplicate':
                duplicateArticle(+articleIds[0])
                    .then()
                    .catch()
                    .finally(async () => {
                        handleClose()
                        setPage(0)
                        setPaginationPage(1)
                        await reloadData()
                        setSiteLoader(false)
                    })
                break

            case 'archive':
                updateArticle(+articleIds[0], { article_status: CONTENT_STATUS.ARCHIVED })
                    .then()
                    .catch()
                    .finally(async () => {
                        handleClose()
                        setPage(0)
                        setPaginationPage(1)
                        await reloadData()
                        setSiteLoader(false)
                    })
                break
            case 'unarchive':
                updateArticle(+articleIds[0], { article_status: CONTENT_STATUS.DRAFT })
                    .then()
                    .catch()
                    .finally(async () => {
                        handleClose()
                        setPage(0)
                        setPaginationPage(1)
                        await reloadData()
                        setSiteLoader(false)
                    })
                break

            default:
                break;
        }
        setDialog(false);
    };

    const handleButtonClick = (action: string, text: string) => {
        setConfirmationAction(action);
        setDialogText(text);
        setDialog(true);
    };

    const handleMouseEnter = (button: string) => {
        setHoverState(prevState => ({
            ...prevState,
            [button]: true
        }));
    };

    const handleMouseLeave = (button: string) => {
        setHoverState(prevState => ({
            ...prevState,
            [button]: false
        }));
    };

    const handleSelectAll = () => {
        setSelectedAll(!selectedAll)
        records.forEach(item => {
            if (item.article_status.toLowerCase() !== "deleted") {
                setSelectedRows(prevState => ({
                    ...prevState,
                    [item.id]: !selectedAll
                }));
            }
        })
    }

    const hasSupportedStatuses = (status: string): boolean => {
        return records.filter(item => item.article_status.toLowerCase() === status)
            .map(e => e.id)
            .some(id => Object.keys(selectedRows).includes(id.toString()));
    }

    useEffect(() => {
        const enabledKeys = Object.keys(selectedRows).filter(key => selectedRows[key] === true);
        setDisableActions(enabledKeys.length === 0)
        if (enabledKeys.length !== records.filter(item => item.article_status.toLowerCase() !== 'deleted').length) {
            setSelectedAll(false)
        }
        
    }, [selectedRows])

    useEffect(()=> {
        setSelectedRows({});
        setSelectedAll(false)
    }, 
    [filter, source, page])


    return (
        <>
            <TableContainer sx={{ height: '100%', minHeight: "150px" }}>
                <ActionBar gap={3} paddingRight={"40px"} minWidth={"fit-content"}>
                    <Stack sx={{ marginRight: "5%" }} display={"flex"} flexDirection={"row"} gap={"4"}>
                        <StyledCheckbox
                            checked={!!selectedAll}
                            onChange={handleSelectAll}
                            disabled={records.length === 0}
                            checkedIcon={<Check style={{ color: '#2C36F0' }} />}
                        />
                        {
                            (records.length > 0 || filter !== "all") &&
                            <StatusDropdown
                                value={filter}
                                handleChange={handleFilterChange}
                                options={filterOptions}
                            />
                        }


                    </Stack>
                    {
                        !disableActions &&
                        <>
                            <CustomTooltip title={"Unpublish"}>
                                <IconButton
                                    disabled={disableActions || !hasSupportedStatuses("published")}
                                    onClick={() => handleButtonClick("unpublish", "Are you sure you want to Unpublish this content?")}
                                    onMouseEnter={() => handleMouseEnter("unpublish")}
                                    onMouseLeave={() => handleMouseLeave("unpublish")}
                                >
                                    <Unpublish color={(disableActions || !hasSupportedStatuses("published")) ? "#ababab" : hoverState.unpublish ? '#2C36F0' : '#000000B3'} />
                                </IconButton>
                            </CustomTooltip>
                            <CustomTooltip title="Publish">
                                <IconButton
                                    disabled={disableActions || !hasSupportedStatuses("draft")}
                                    onClick={() => handleButtonClick("publish", "Are you sure you want to Publish this content?")}
                                    onMouseEnter={() => handleMouseEnter("publish")}
                                    onMouseLeave={() => handleMouseLeave("publish")}
                                >
                                    <PublishIcon color={(disableActions || !hasSupportedStatuses("draft")) ? "#ababab" : hoverState.publish ? '#2C36F0' : '#000000B3'} />
                                </IconButton>
                            </CustomTooltip>
                            <CustomTooltip title="Delete">
                                <IconButton
                                    disabled={disableActions}
                                    onClick={() => handleButtonClick("delete", "Are you sure you want to Delete this content? This action is not reversible.")}
                                    onMouseEnter={() => handleMouseEnter("delete")}
                                    onMouseLeave={() => handleMouseLeave("delete")}
                                >
                                    <DeleteIcon color={(disableActions) ? "#ababab" : hoverState.delete ? '#2C36F0' : '#000000B3'} />
                                </IconButton>
                            </CustomTooltip>
                        </>
                    }

                </ActionBar>

                {records.length > 0 &&
                    <>
                        <Table
                            stickyHeader
                            sx={{
                                minWidth: 650,
                                maxHeight: '100%',
                                borderCollapse: 'separate',
                                borderSpacing: '0px 8px',
                                height: '100%',
                            }}
                            aria-label="simple table"
                        >
                            <TableHead
                                sx={{
                                    background: '#fff',
                                    borderRadius: '20px',
                                    marginBottom: '12px',
                                }}
                            >
                                <TableRow
                                    sx={{
                                        borderRadius: '20px',

                                        '& th': {
                                            textTransform: 'uppercase',
                                            fontFamily: 'neue-montreal-regular',
                                            fontSize: isMobile ? '10px' : '14px',
                                            border: 0,
                                            padding: '14px',
                                            minWidth: '120px',
                                        },
                                        '& > *': {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        },
                                    }}
                                >
                                    <TableCell align="left" sx={{
                                            borderStyle: 'solid',
                                            borderTopLeftRadius: '20px',
                                            borderBottomLeftRadius: '20px',
                                        }}>
                                            <Stack marginLeft={"10%"}>
                                                Title
                                            </Stack>
                                           
                                        </TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Published Date</TableCell>
                                    <TableCell align="center">Imported</TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            borderStyle: 'solid',
                                            borderTopRightRadius: '20px',
                                            borderBottomRightRadius: '20px',
                                        }}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records.map((row) => (
                                    <TableRow
                                        key={`${row?.article_id}-${row?.article_status}`}
                                        sx={{
                                            '& td, & th': {
                                                border: 0,
                                            },

                                            '& th': {
                                                fontSize: isMobile ? '14px' : '16px',
                                                fontFamily: 'neue-montreal-regular',
                                                lineHeight: '20px',
                                                maxWidth: '200px',
                                            },

                                            '& td': {
                                                maxWidth: "400px",
                                                fontFamily: 'neue-montreal-regular',
                                                fontSize: isMobile ? '12px' : '14px',
                                            },
                                            '& > *': {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            },

                                            background: '#fff',
                                            borderRadius: '20px',
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                borderStyle: 'solid',
                                                borderTopLeftRadius: '20px',
                                                borderBottomLeftRadius: '20px',
                                            }}
                                            align="left"
                                        >
                                            <Stack display={"flex"} flexDirection={"row"} gap={5} alignItems={"center"}>
                                                <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"}>
                                                    <StyledCheckbox
                                                        checked={!!selectedRows[row.id]}
                                                        onChange={() => toggleRowSelection(row.id)}
                                                        checkedIcon={<Check style={{ color: '#2C36F0' }} />}
                                                        disabled={row?.article_status?.toLowerCase() === 'deleted'}
                                                    />

                                                    <Box
                                                        sx={{
                                                            width: isMobile ? '48px' : '64px',
                                                            height: isMobile ? '48px' : '64px',
                                                            borderRadius: '10px',
                                                            border: '1px solid #E5E4E5',
                                                        }}
                                                    >
                                                        {(row?.thumbnail?.url || row?.thumbnailUrl) ? (
                                                            <StyledImg
                                                                src={row?.thumbnail?.url ?? row?.thumbnailUrl}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {getContentTypeIcon(row?.content_type,
                                                                    isMobile)}

                                                            </Box>
                                                        )}
                                                    </Box>

                                                </Stack>
                                                {capitalize(row?.title) || '-'}
                                            </Stack>
                                            
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                sx={{
                                                    borderRadius: '8px',
                                                    textTransform: 'capitalize',
                                                    padding: '2px 10px',
                                                    fontFamily: 'neue-montreal-regular',
                                                    background: getStatusColor(
                                                        row?.article_status,
                                                    ).background,
                                                    fontSize: isMobile ? 12 : 14,
                                                    border: `1px solid ${getStatusColor(row?.article_status)
                                                        .border
                                                        }`,

                                                    '&:hover': {
                                                        background: getStatusColor(
                                                            row?.article_status,
                                                        ).background,
                                                    },
                                                }}
                                            >
                                                {row?.article_status}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            {(row?.published_on && row?.article_status?.toLowerCase() === 'published')
                                                ? formatDate(row?.published_on)
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row?.import_source || '-'}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderStyle: 'solid',
                                                borderTopRightRadius: '20px',
                                                borderBottomRightRadius: '20px',
                                            }}
                                            align="center"
                                        >
                                            <IconButton
                                                onClick={(e) => handleClick(e, row)}
                                                id={`menu-btn-${row.article_id}`}
                                                disabled={row?.article_status?.toLowerCase() === 'deleted'}
                                                // aria-controls={
                                                //     open ? `basic-menu-${row.article_id}` : undefined
                                                // }
                                                aria-haspopup="true"
                                                // aria-expanded={open ? 'true' : undefined}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    border: '1px solid #E5E4E5',
                                                    borderRadius: '10px',
                                                }}
                                            >
                                                <MoreHoriz />
                                            </IconButton>
                                            <Menu
                                                id={`basic-menu-${row.article_id}`}
                                                // anchorEl={anchorEl}
                                                // open={open}
                                                // onClose={handleClose}
                                                anchorEl={anchorEls[row.id]}
                                                open={Boolean(anchorEls[row.id])}
                                                onClose={() => handleClose(row.id)}
                                                MenuListProps={{
                                                    'aria-labelledby': `menu-btn-${row.article_id}`,
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                sx={{
                                                    boxShadow: 'unset',
                                                    '& .MuiPaper-root, .MuiMenu-root, .MuiMenu-paper':
                                                    {
                                                        borderRadius: '10px',
                                                        width: '140px',
                                                        marginTop: '8px',
                                                        boxShadow:
                                                            '0px 4px 10px 0px rgba(0,0,0,0.05)',
                                                    },

                                                    '& .MuiPaper-elevation8 ul': {
                                                        padding: '8px 0px !important',
                                                    },
                                                }}
                                            >
                                                {
                                                    (row?.article_status?.toLowerCase() === "draft") &&
                                                    <StyledMenuItem
                                                        disabled={!row.title}
                                                        onClick={() => handleOnPublishClick(row)}>
                                                        {/* onClick = {() => {
                                                            setSelectedRows({ [row.id]: true });
                                                            handleButtonClick("publish", "Are you sure you want to Publish this content?");
                                                        }}> */}
                                                        <PublishIcon color='#000000B3' />
                                                        Publish
                                                    </StyledMenuItem>
                                                }

                                                {
                                                    (row?.article_status?.toLowerCase() === "published") &&
                                                    <StyledMenuItem onClick={() => {
                                                        setSelectedRows({ [row.id]: true });
                                                        handleButtonClick("unpublish", "Are you sure you want to Unpublish this content?");

                                                    }}>
                                                        <Unpublish color='#000000B3' />
                                                        Unpublish
                                                    </StyledMenuItem>
                                                }
                                                <StyledMenuItem
                                                    disabled={[
                                                        'draft',
                                                        'archived',
                                                    ].includes(row?.article_status)}
                                                    onClick={() => handleOnViewClick(row)}
                                                >
                                                    <ViewIcon />
                                                    View
                                                </StyledMenuItem>
                                                <StyledMenuItem onClick={() => handleOnEditClick(row)}>
                                                    <EditIcon />
                                                    Edit
                                                </StyledMenuItem>
                                                <StyledMenuItem onClick={() => {
                                                    setSelectedRows({ [row.id]: true });
                                                    handleButtonClick("duplicate", "Are you sure you want to duplicate this content?");

                                                }}>
                                                    <DuplicateIcon />
                                                    Duplicate
                                                </StyledMenuItem>
                                                {
                                                    row.article_status !== 'archived' &&
                                                    <StyledMenuItem
                                                        disabled={[
                                                            'archived', 'draft',
                                                        ].includes(row?.article_status)}
                                                        onClick={() => {
                                                            setSelectedRows({ [row.id]: true });
                                                            handleButtonClick("archive", "Are you sure you want to archive this content?");
                                                        }
                                                        }
                                                    >
                                                        <ArchiveIcon />
                                                        Archive
                                                    </StyledMenuItem>
                                                }

                                                {
                                                    row.article_status === 'archived' &&
                                                    <StyledMenuItem
                                                        onClick={() => {
                                                            setSelectedRows({ [row.id]: true });
                                                            handleButtonClick("unarchive", "Are you sure you want to unarchive this content?");
                                                        }
                                                        }
                                                    >
                                                        <ArchiveIcon />
                                                        Unarchive
                                                    </StyledMenuItem>
                                                }

                                                <StyledMenuItem

                                                    disabled={[
                                                        'deleted'
                                                    ].includes(row?.article_status)}
                                                    onClick={() => {
                                                        setSelectedRows({ [row.id]: true });
                                                        handleButtonClick("delete", "Are you sure you want to Delete this content? This action is not reversible.");
                                                    }
                                                    }
                                                >
                                                    <Box sx={{marginRight: "3px", marginLeft: "2px"}}>
                                                        <DeleteIcon color='#000000B3' />
                                                    </Box>
                                                    
                                                    Delete
                                                </StyledMenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* Pagination */}
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                            mt={'16px'}
                        >
                            {!isMobile && <Box width={'100px'}></Box>}
                            <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                                <StyledPaginationButton
                                    disabled={page <= 0}
                                    onClick={prevPage}
                                >
                                    <West />
                                </StyledPaginationButton>
                                <StyledPaginationButton
                                    disabled={page + 1 >= getTotalPage()}
                                    onClick={nextPage}
                                >
                                    <East />
                                </StyledPaginationButton>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} gap={'5px'}>
                                <StyledTypography>Page</StyledTypography>
                                {/* <StyledTypography
                                    style={{
                                        padding: '2px 20px',
                                        border: '1px solid #E5E4E5',
                                        borderRadius: '8px',
                                        background: '#fff',
                                    }}
                                >
                                    {page + 1}
                                </StyledTypography> */}
                                <StyledPaginationInput
                                    disableUnderline
                                    type="text"
                                    value={paginationPage}
                                    onChange={onPaginationInputChange}
                                    onBlur={() => setPage(Number(paginationPage) - 1)}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            setPage(Number(paginationPage) - 1);
                                        }
                                    }}
                                />
                                <StyledTypography>of</StyledTypography>
                                <StyledTypography> {getTotalPage()}</StyledTypography>
                            </Stack>
                        </Stack>
                    </>
                }
                {/* <AlertDialog dialog={dialog} setDialog={setDialog} /> */}
            </TableContainer>
            <ConfirmationDialog
                handleConfirmation={(handleActionConfirmation)}
                handleClose={closeDialog}
                dialogText={dialogText}
                open={dialog}
            />
        </>
    );
};

export default ContentTable;


interface IProps {
    handleConfirmation: () => void;
    handleClose: () => void;
    dialogText: string;
    open: boolean;
}

const ConfirmationDialog: FC<IProps> = ({ handleConfirmation, handleClose, dialogText, open }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <StyledCloseIcon
                aria-label="close"
                onClick={handleClose}
                sx={{ color: '#ff0035' }}
            >
                <CloseIcon />
            </StyledCloseIcon>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: "#000000" }}>
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} padding={"10px 20px"}>
                    <Button onClick={handleClose} color="primary" sx={{ textTransform: "capitalize" }}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmation} color="primary" variant="contained" sx={{ textTransform: "capitalize" }}>
                        Confirm
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

const StatusDropdown: FC<{
    value: string;
    handleChange: any;
    options: { name: string; value: string }[];
    placeholder?: string;
}> = ({
    value,
    handleChange,
    options,
    placeholder,
}) => {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));
        return (
            <>
                <Select
                    IconComponent={ExpandMore}
                    sx={{
                        height: '32px',
                        outline: 'unset',
                        fontSize: isMobile ? '12px' : '14px',
                        fontFamily: 'neue-montreal-regular',
                        borderRadius: '17px',
                        border: 'none', // Remove border on the select element
                        '& .MuiMenu-paper, .MuiPaper-root, .MuiPaper-elevation, .MuiPopover-paper': {},
                        '& fieldset': {
                            border: 'none', 
                        }
                    }}
                    inputProps={{
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    borderRadius: '10px',
                                    background: '#fff',
                                    marginTop: '10px',
                                    boxShadow: '0px 4px 24px 0px #00000040',
                                },
                            },
                            MenuListProps: {
                                sx: {
                                    fontSize: '12px',
                                    fontFamily: 'neue-montreal-regular',

                                    '& .MuiMenuItem-root': {
                                        fontSize: '12px',
                                        color: '#000000B2',
                                        fontFamily: 'neue-montreal-regular',
                                    },
                                },
                            },
                        },
                    }}
                    labelId="dropdown-select"
                    id="demo-select-small"
                    displayEmpty
                    renderValue={(selected) => {
                        console.log(selected)
                        // if (!selected) {
                        //     return placeholder;
                        // }
                        const val = options.find((v) => v.value === selected);
                        return val?.name ?? placeholder;
                    }}
                    value={value}
                    onChange={handleChange}
                >
                    {/* {placeholder && (
                        <MenuItem disabled key={placeholder}>
                            {placeholder}
                        </MenuItem>
                    )} */}
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </>
        );
    };