import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FileInputWrapper, StyledTextField, UploadButton } from './styles';
import { UploadIcon } from '../../../assets/icons/UploadIcon';

interface FileUploadInputProps {
    // eslint-disable-next-line no-unused-vars
    onFileChange: (file: File | null) => void;
}

export const FileUploadInput: React.FC<FileUploadInputProps> = ({ onFileChange }) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const [hovered, setHovered] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFileName(file.name);
            // Pass file to parent component
            onFileChange(file);
        }
    };

    const handleUploadButtonClick = () => {
        if (fileName) {
            // If file is already uploaded, clear it
            setFileName(null);
            // Clear the input
            const fileInput = document.getElementById('file-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = ''; // Clear the input value
            }
            // Remove the file from the parent component
            onFileChange(null);
        } else {
            // Otherwise, trigger file upload action
            document.getElementById('file-upload')?.click();
        }
    };

    return (
        <FileInputWrapper>
            <StyledTextField
                variant="outlined"
                fullWidth
                value={fileName || ''}
                placeholder="No file chosen"
                InputProps={{
                    style: { backgroundColor: 'white' },
                }}
            />
            <UploadButton
                onClick={handleUploadButtonClick}
                startIcon={fileName ? <CloseIcon /> : <UploadIcon color={hovered ? '#fff' : '#000000'} />}
                variant={"outlined"}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {fileName ? 'Clear' : 'Upload'}
            </UploadButton>
            <input
                id="file-upload"
                type="file"
                accept='.csv'
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
        </FileInputWrapper>
    );
};
