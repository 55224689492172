/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { fetchMessageNotifications } from '../apis/Notifications';
import { useSocket } from './Socket';
import { formatMessageTime } from 'helper';
import { getChatRooms } from 'apis/Messages';
import { Room } from 'pages/Messages/sidebar';

export interface INotificationContext {
  unreadCount: number;
  total: number;
  remaining: number;
  data: {
    NEW: {
      id: number;
      type: string;
      message: string;
      triggeredBy: {
        username: string;
        userId: number;
        uid: string;
        profile_image: string;
      };
      chatroom: {
        id: number;
      };
      createdAt: string;
    }[];
  };
}

const initialStates: INotificationContext = {
  unreadCount: 0,
  total: 0,
  remaining: 0,
  data: {
    NEW: [],
  },
};

export type NotificationContextType = {
  messageNotifications: INotificationContext;
  updateNotifications: any;
  refreshNotifications?: any;
};

export const MessageNotificationContext =
  React.createContext<NotificationContextType | null>(null);

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const MessageNotificationProvider: React.FC<
  NotificationProviderProps
> = ({ children }) => {
  const [messageNotifications, setMessageNotifications] =
    React.useState<INotificationContext>(initialStates);
  const { userInfo } = useAuth();
  const { socket } = useSocket();
  const [rooms, setRooms] = React.useState<number[]>([]);

  const updateNotifications = (info: INotificationContext) => {
    setMessageNotifications({ ...messageNotifications, ...info });
  };

  const refreshNotifications = () => {
    fetchMessageNotifications().then((response: any) => {
      setMessageNotifications(response);
    });
  };

  const showNotification = (message: string, timestamp: Date | string) => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      new Notification('New Message', {
        body: `${message}\nReceived at ${formatMessageTime(timestamp)}`,
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification('New Message', {
            body: `${message}\nReceived at ${formatMessageTime(timestamp)}`,
          });
        }
      });
    }
  };

  socket?.on('receiveNotification', (data: any) => {
    if (rooms.includes(data?.chatRoomId)) {
      showNotification(data?.message || 'You have a message.', new Date());
      refreshNotifications();
    }
  });

  useEffect(() => {
    if (userInfo.isUserLoggedIn) {
      fetchMessageNotifications().then((response: any) => {
        setMessageNotifications(response);
      });
      getChatRooms().then((response: Room[]) => {
        setRooms(response.map((room) => room.chat_room_id));
      });
    }
  }, [userInfo]);

  return (
    <MessageNotificationContext.Provider
      value={{
        messageNotifications,
        updateNotifications,
        refreshNotifications,
      }}
    >
      {children}
    </MessageNotificationContext.Provider>
  );
};

export const useMessageNotifications = () => {
  const context = useContext(MessageNotificationContext);
  if (!context) {
    throw new Error(
      'useMessageNotificatione must be used within MessageNotificationProvider',
    );
  }
  return context;
};
