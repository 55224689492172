
import axios from "axios";
import { STORAGE_KEYS } from "../../../constants/storage";
import { getDomain } from "../../../utils";
import { getValueFromLocalStore } from "../../../utils/localStorage";


const SUBSCRIBERS_CSV = '/api/subscribers/import-csv';
const SUBSCRIBER_LIST = '/api/subscribers/import-email-list'
const AUTHOR_API = '/api/author/get-subscribers-by-user-id'


export const uploadSubscriberCSV = async (file: File, referral_link: string): Promise<any> => {
    try {
        const url: string = getDomain() + `${SUBSCRIBERS_CSV}`;
        const formData: FormData = new FormData();
        formData.append('files', file); // Assuming 'file' is the file you want to upload
        formData.append('referral_link', referral_link);

        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getValueFromLocalStore(STORAGE_KEYS.JWT_TOKEN)}`,
            },
        });
        return response.data;
    } catch (error: any) {
        
        throw {
            message: error?.response?.data?.error?.message ?? error?.message,
            status: error.response ? error.response.status : 500,
        };
    }
};

export const uploadSubscriberList = async (emails: string, referral_link: string) => {
    try {
        const url = getDomain() + `${SUBSCRIBER_LIST}`;
        console.log('token', getValueFromLocalStore(STORAGE_KEYS.JWT_TOKEN));
        const response = await axios.post(
            url,
            {
                data: {emails, referral_link }
            },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        throw {
            message: error?.response?.data?.error?.message ?? error?.message,
            status: error.response.status || 500,
        };
    }
};

export const getAllUserSubscribers = async (
    id: number,
    page: number,
    limit: number,
) => {
    try {

        let url = `${getDomain()}${AUTHOR_API}/?id=${id}&page=${page}&limit=${limit}`;
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${getValueFromLocalStore(
                    STORAGE_KEYS.JWT_TOKEN,
                )}`,
            },
        });
        return response.data;
    } catch (error: any) {
        throw {
            message: 'Failed to fetch Subscribers',
            status: error.response.status || 500,
        };
    }
};