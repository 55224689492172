import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
const PlayArrowIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            fill="inherit"
            viewBox="0 0 100 100"
            {...props}
        >
            <path d="m25,90.2c-.95,0-1.91-.22-2.8-.67-2.09-1.06-3.4-3.16-3.4-5.5V15.97c0-2.26,1.19-4.28,3.17-5.38,1.97-1.1,4.38-1.04,6.3.15l50.02,31.04c1.74,1.08,2.83,2.94,2.91,4.98.08,2.04-.85,3.98-2.49,5.2l-50.02,37.02c-1.09.81-2.39,1.22-3.69,1.22Zm0-75c-.17,0-.31.06-.4.11-.18.1-.4.3-.4.66v68.06c0,.38.23.58.42.68.17.08.5.19.85-.07l50.02-37.02c.27-.2.32-.46.31-.64,0-.18-.08-.43-.36-.61L25.42,15.33c-.15-.09-.3-.13-.42-.13Z" />
        </SvgIcon>
    );
};

export default PlayArrowIcon;
