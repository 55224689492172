import { Backdrop, CircularProgress } from '@mui/material';

interface LoaderProps {
    open: boolean;
}

function Loader({ open }: LoaderProps) {
    return (
        <Backdrop open={open} sx={{ zIndex: 6000 }}>
            {open && <CircularProgress color="inherit" size={80} />}
        </Backdrop>
    );
}

export default Loader;
