import {
    Box,
    Button,
    FormControlLabel,
    IconButton,
    MenuItem,
    Typography,
    styled,
} from '@mui/material';

export const ManageContentWrapper = styled(Box)(({ theme }) => ({
    background: '#F9F9F9',
    borderRadius: '20px',
    width: '100%',
    minHeight: '100%',
    padding: '24px 20px',
    [theme.breakpoints.down('md')]: {},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'neue-montreal-regular',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
        // fontSize: '12px',
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    background: '#fff',
    color: '#2C36F0',
    width: 'max-content',
    textTransform: 'capitalize',
    border: '1px solid #2C36F0',
    borderRadius: '10px',
    padding: '8px 24px',
    textAlign: 'center',

    '& hover': {
        background: '#fff',
        color: '#2C36F0',
    },
    [theme.breakpoints.down('md')]: {},
}));

export const StyledImg = styled('img')(({ theme }) => ({
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
        width: '48px',
        height: '48px',
    },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: 'neue-montreal-regular',
    [theme.breakpoints.down('md')]: {},
    '&:hover': {
        backgroundColor: '#D5D7FC',
    },
}));
export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: 14,
        fontFamily: 'neue-montreal-regular',
    },
    [theme.breakpoints.down('md')]: {},
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.secondary.main,
    color: '#fff',
    borderRadius: "20px",
    '&:hover': {
        color: theme.palette.secondary.main,
        background: '#fff',
        border: '1px solid #2C36F0',
    }
}))
