import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
const CloseIcon = (props: any) => {
  return (
    <SvgIcon
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: '15px', height: '15px' }}
      {...props}
    >
      {/* <path d="M14.8844 14.3262C14.9211 14.3628 14.9501 14.4064 14.97 14.4542C14.9898 14.5021 15 14.5535 15 14.6053C15 14.6571 14.9898 14.7085 14.9699 14.7564C14.9501 14.8042 14.921 14.8478 14.8844 14.8844C14.8477 14.9211 14.8042 14.9501 14.7563 14.97C14.7084 14.9898 14.6571 15 14.6052 15C14.5534 15 14.5021 14.9898 14.4542 14.9699C14.4063 14.9501 14.3628 14.921 14.3261 14.8844L7.5 8.05828L0.673868 14.8844C0.599849 14.9584 0.499451 15 0.394763 15C0.290074 15 0.18967 14.9584 0.115637 14.8844C0.041605 14.8104 9.06986e-06 14.71 1.48306e-09 14.6053C-9.0669e-06 14.5006 0.0415697 14.4002 0.115589 14.3262L6.94174 7.5L0.115589 0.67382C0.0415697 0.599787 -9.0669e-06 0.499383 1.48306e-09 0.394694C4.49169e-06 0.342858 0.0102189 0.29153 0.03006 0.243641C0.0499011 0.195752 0.0789803 0.15224 0.115637 0.115589C0.152295 0.0789385 0.195812 0.0498668 0.243704 0.030034C0.291597 0.0102012 0.342926 -4.48873e-06 0.394763 1.48103e-09C0.499451 9.06986e-06 0.599849 0.041605 0.673868 0.115637L7.5 6.94172L14.3261 0.115637C14.3628 0.0789803 14.4063 0.0499011 14.4542 0.03006C14.5021 0.0102189 14.5534 4.49169e-06 14.6052 1.48103e-09C14.6571 -4.48873e-06 14.7084 0.0102012 14.7563 0.030034C14.8042 0.0498668 14.8477 0.0789385 14.8844 0.115589C14.921 0.15224 14.9501 0.195752 14.9699 0.243641C14.9898 0.29153 15 0.342858 15 0.394694C15 0.446531 14.9898 0.497861 14.97 0.545753C14.9501 0.593646 14.9211 0.637163 14.8844 0.67382L8.05826 7.5L14.8844 14.3262Z" /> */}

      <path
        d="M15 1.00009L1 15.0001M15 15L1 1"
        stroke={props.stroke || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloseIcon;
