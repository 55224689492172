import axios from "axios";
import { clearLocalStore, deleteValueInLocalStore, getValueFromLocalStore } from "../utils/localStorage";
import { STORAGE_KEYS } from "../constants/storage";
import { getDomain } from "../utils";

var instance = axios.create();

function requestErrorInterceptor(error){
    return Promise.reject(error);
}
function requestInterceptor(config){
    const token = getValueFromLocalStore(STORAGE_KEYS.JWT_TOKEN);
    if(token){
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
}

function responseSuccess(response){
    return response;
}
function responseError(error){
    if(error?.response?.status === 401){
        deleteValueInLocalStore(STORAGE_KEYS.JWT_TOKEN);
        clearLocalStore();
        window.location.href = '/login'
    }
    return Promise.reject(error);
}

instance.defaults.baseURL = getDomain();
instance.defaults.responseType = "json";

export const interceptorDefault = instance.interceptors.request.use(requestInterceptor, requestErrorInterceptor);

instance.interceptors.response.use(responseSuccess, responseError)
export default instance;