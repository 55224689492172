import axios from 'axios';
import { fetchData } from '..';
import { STORAGE_KEYS } from '../../constants/storage';
import { getDomain } from '../../utils';
import { getValueFromLocalStore } from '../../utils/localStorage';

const AUTHOR_API = '/api/author';

export const saveAccountInfo = async (data: any) => {
    try {
        const payload = {
            userId: getValueFromLocalStore(STORAGE_KEYS.UID),
            ...data,
        };
        const url = getDomain() + `${AUTHOR_API}/save-account-info`;
        const response = await axios.post(
            url,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        
        return response.data;
    } catch (error: any) {
        throw {
            message: error,
            status: error.response.status || 500,
        };
    }
};

export const getAccountInfo = async () => {
    const url =
        getDomain() +
        `${AUTHOR_API}/get-author-account-info?userId=${getValueFromLocalStore(
            STORAGE_KEYS.UID,
        )}`;
    return fetchData({
        apiEndpoint: url,
        errorMessage: 'Failed to fetch Author Account settings',
        requiresAuthorization: true,
    });
};

export const getAuthorPlans = async (authorId: string) => {
    const url =
        getDomain() +
        `${AUTHOR_API}/get-author-payment-plans?userId=${authorId}`;
    return fetchData({
        apiEndpoint: url,
        errorMessage: 'Failed to fetch Author Payment Plans',
    });
};

export const saveAuthorPlans = async (data: any) => {
    try {
        const payload = {
            userId: getValueFromLocalStore(STORAGE_KEYS.UID),
            ...data,
        };
        const response = await axios.post(
            getDomain() + `${AUTHOR_API}/save-payment-plan`,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        throw {
            message: error,
            status: error.response.status || 500,
        };
    }
};

export const getSubscribersWithPagination = async (
    id: number,
    page: number,
    limit: number,
    orderBy?: string,
    order?: string,
) => {
    try {
        let url = `${getDomain()}${AUTHOR_API}/get-subscribers?id=${id}&page=${page}&limit=${limit}${
            orderBy ? `&orderBy=${orderBy}&order=${order}` : ''
        }`;
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${getValueFromLocalStore(
                    STORAGE_KEYS.JWT_TOKEN,
                )}`,
            },
        });
        return response.data;
    } catch (error: any) {
        throw {
            message: 'Failed to fetch Subscribers',
            status: error.response.status || 500,
        };
    }
};

export const getUserSubscribers = async (
    id: number,
    page: number,
    limit: number,
) => {
    try {
        console.log(getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    ))
        let url = `${getDomain()}${AUTHOR_API}/get-user-subscribers?id=${id}&page=${page}&limit=${limit}`;
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${getValueFromLocalStore(
                    STORAGE_KEYS.JWT_TOKEN,
                )}`,
            },
        });
        return response.data;
    } catch (error: any) {
        throw {
            message: 'Failed to fetch Subscribers',
            status: error.response.status || 500,
        };
    }
};
