import { Dialog, styled, Button } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}));
