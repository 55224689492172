
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Select, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    background: 'white',
    color: 'black',
    borderRadius: '10px',
    padding: '20px',
  }
}));

export const StyledCloseIcon = styled(IconButton)(() => ({
  position: 'absolute',
  top: '17px',
  right: '21px',
  width: '25px',
  height: '25px',
  color: 'black',
  cursor: 'pointer',
}));

export const StyledDialogContentText = styled(DialogContentText)(() => ({
    color: "#808080",
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
    padding: "20px 0"
}));

export const DialogStack = styled(Stack)(() => ({
  backgroundColor: '#F9F9F9',
  borderRadius: '20px',
  margin: '20px 0',
  padding: '20px',
}));

export const StyledTypography = styled(Typography)(
    () => ({
        fontSize: '14px',
        '& span': {
            color: "#FA2256"
        }
    })
)

export const StyledDialogTitle = styled(DialogTitle)(
    () => ({
        fontSize: "24px",
        padding: 0
    })
)


export const StyledDialogContainer = styled(Box)(() => ({

}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'white', // Always white background
  '& .MuiOutlinedInput-root': {
    '&:hover, &:focus': {
      borderColor: theme.palette.grey[400], // Light gray border on hover or focus
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:focus': {
    borderRadius: "20px",
  },
  '& .MuiSelect-iconOutlined': {
    paddingRight: theme.spacing(1), // Add padding to the icon
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: 'white',
    flexGrow: 1,
    height: '100%',
    borderRadius: "20px",
    // padding: '8px',
    '& .MuiInputBase-root': {
        height: '100%', // Match button height
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px', // Adjust padding
    },
    '& .MuiOutlinedInput-root': {
        '&:hover, &:focus': {
            borderColor: theme.palette.grey[400], // Light gray border on hover or focus
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:focus': {
        borderRadius: "20px",
    },
}));
