import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { StyledButton } from './styles';

// Define interface for props
interface DropdownMenuProps {
    buttonText: string;
    buttonIcon: React.ReactNode;
    buttonStyle?: React.CSSProperties;
    menuItems: { icon: React.ReactNode; text: string, onClick: () => void }[];
    // eslint-disable-next-line no-unused-vars
    setHoveredItemIndex?: (id: number | null) => void;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
    buttonText,
    buttonIcon,
    buttonStyle,
    menuItems,
    setHoveredItemIndex
}) => {
    const [open, setOpen] = React.useState(false);
    // const [hoveredItemIndex, setHoveredItemIndex] = React.useState<number | null>(null);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const theme = useTheme();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const handleItemMouseEnter = (index: number) => {
        if (setHoveredItemIndex)
            setHoveredItemIndex(index);
    };

    const handleItemMouseLeave = () => {
        if (setHoveredItemIndex)
            setHoveredItemIndex(null);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <StyledButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                startIcon={buttonIcon}
                style={buttonStyle}
                variant="outlined"
            >
                {buttonText}
            </StyledButton>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: 100 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {menuItems.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={item.onClick}
                                            onMouseEnter={() => handleItemMouseEnter(index)}
                                            onMouseLeave={handleItemMouseLeave}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontSize: "14px",
                                                    [theme.breakpoints.down('sm')]: {
                                                        fontSize: "12px",
                                                    }
                                                },
                                                color: "#808080",
                                                [theme.breakpoints.down('sm')]: {
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                },
                                                '&:hover': {
                                                    backgroundColor: '#D5D7FC',
                                                    '& .MuiListItemIcon-root': {
                                                        color: '#000000'
                                                    },
                                                    '& .MuiListItemText-root': {
                                                        color: '#000000'
                                                    }
                                                }
                                            }}
                                        >
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText sx={{padding: 0}}>{item.text}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
