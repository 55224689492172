import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      id='Laye_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox="0 0 24 24"
      fill='none'
      width="24" height="24"
      {...props}
    >
  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> */}
  <path d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"   fill='none'/>
{/* </svg> */}
    </SvgIcon>
  );
};

export default EditIcon;
