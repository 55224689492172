/* eslint-disable no-unused-vars */
import { Box, SelectChangeEvent, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { StyledTypography } from "../styles"
import CustomDropdown from "../CustomDropdown";
import { useRef, useState } from "react";
import { capitalize } from "lodash";
import { ManageContent } from "./ManageContent";
import { ImportDialog } from "./ImportDialog";
import { SegmentTrack } from '../../../../components/SegmentAnalytics';
import useAuth from '../../../../hooks/useAuth';

const Tabs: any[] = ['all', 'Instagram', 'X', 'TikTok', 'YouTube', 'Substack'];

const sortByOptions = [
    {
        name: 'A-Z',
        value: 'az',
    },
    {
        name: 'Z-A',
        value: 'za',
    },
    {
        name: 'Latest',
        value: 'latest',
    },
    {
        name: 'Oldest',
        value: 'oldest',
    },
];

const filterOptions = [
    {
        name: 'All',
        value: 'all',
    },
    {
        name: 'Draft',
        value: 'draft',
    },
    {
        name: 'Published',
        value: 'published',
    },
    {
        name: 'Deleted',
        value: 'deleted',
    },
    {
        name: 'Archived',
        value: 'archived',
    }
]

export const ImportContent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const containerRef = useRef<any>(null);
    const [currentTab, setCurrentTab] = useState<string>(Tabs[0]);
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<any>('latest');
    const [filter, setFilter] = useState<any>('all');
    const { userInfo } = useAuth();

    const onCloseModal = () => setIsImportModalOpen(false)
    const importContentClick = () => {
        setIsImportModalOpen(true)
        SegmentTrack({
            name: 'import_content',
            properties: {
                "userId": userInfo.uid, 
                "date_created": new Date().toISOString(),
                status: "button_clicked"
            }
        })
    }

    const handleSortByChange = (e: SelectChangeEvent) => {
        setSortBy(e?.target?.value);
    };
    const handleFilterChange = (e: SelectChangeEvent) => {
        setFilter(e?.target?.value);
    };


    return (
        <>

            <Stack
                direction={'column'}
                gap={'20px'}
                sx={{
                    width: '100%',
                    padding: '20px 0px 60px 0px',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <Stack
                    width={'100%'}
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent={isMobile ? 'unset' : 'space-between'}
                    alignItems={'center'}
                    gap={isMobile ? '4px' : 'unset'}
                >
                    <Customtab
                        tabs={Tabs}
                        isMobile={isMobile}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                    />
                    <Stack
                        width={isMobile ? '100%' : 'unset'}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={isMobile ? 'flex-end' : 'unset'}
                    >
                        <StyledTypography
                            sx={{
                                color: '#000000B2',
                                fontSize: isMobile ? 12 : 14,
                            }}
                        >
                            Sort by:
                        </StyledTypography>
                        <CustomDropdown
                            value={sortBy}
                            handleChange={handleSortByChange}
                            options={sortByOptions}
                        />
                    </Stack>
                </Stack>
                <Box
                    ref={containerRef}
                    sx={{
                        padding: '0px 0px 20px 0px',
                        height: '100%',
                        overflowY: 'scroll',
                    }}
                >
                    <ManageContent
                        isMobile={isMobile}
                        importSource={currentTab}
                        importContentButton={importContentClick}
                        sortBy={sortBy}
                        filter={filter}
                        handleFilterChange={handleFilterChange}
                        filterOptions={filterOptions}
                        
                    />
                    <ImportDialog
                        isOpen={isImportModalOpen}
                        onClose={onCloseModal}
                    />
                </Box>
            </Stack>

        </>

    )
}

const Customtab = ({ isMobile, currentTab, setCurrentTab, tabs = [], }: any) => {
    return (
        <Box sx={{ width: isMobile ? '100%' : 'auto' }}>
            <Stack
                direction={'row'}
                sx={{
                    width: isMobile ? '100%' : 'auto',
                    overflowX: 'scroll',
                    scrollbarWidth: 'none',
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                {tabs.map((tab: any, index: number) => (
                    <Box
                        key={index}
                        onClick={() => setCurrentTab(tab)}
                        sx={{
                            border: '1px solid #E5E4E5',
                            borderTopRightRadius:
                                index === tabs.length - 1 ? '8px' : '0px',
                            borderBottomRightRadius:
                                index === tabs.length - 1 ? '8px' : '0px',
                            borderTopLeftRadius:
                                index === 0 ? '8px' : '0px',
                            borderBottomLeftRadius:
                                index === 0 ? '8px' : '0px',
                            padding: '7.5px 16px',
                            cursor: 'pointer',
                            background:
                                tab === currentTab
                                    ? '#2C36F0'
                                    : 'transparent',

                            '& a': {
                                textDecoration: 'unset',
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '12px' : '14px',
                                fontFamily: 'neue-montreal-regular',
                                lineHeight: isMobile ? '14.4px' : '16.8px',
                                color:
                                    tab === currentTab
                                        ? '#fff'
                                        : '#000000B2',
                            }}
                        >
                            {capitalize(tab)}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};