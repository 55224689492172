import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

const CustomTooltipContainer = styled('div')({
    position: 'relative',
    display: 'inline-block',
});

const TooltipContent = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#000',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    zIndex: 100,
    top: '100%',
    left: '50%',
    fontSize: "12px",
    transform: 'translate(-50%, 5px)',
    whiteSpace: 'nowrap',
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.2s linear',
    boxShadow: " 0px 4px 24px 0px #00000040"
}));

export const CustomTooltip: React.FC<{ title: string, children: any }> = ({ title, children }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const handleMouseEnter = () => {
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    return (
        <CustomTooltipContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <TooltipContent style={{ visibility: isTooltipVisible ? 'visible' : 'hidden', opacity: isTooltipVisible ? 1 : 0 }}>
                {title}
            </TooltipContent>
        </CustomTooltipContainer>
    );
};