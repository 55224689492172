import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
const CreateCommentIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13 20.6076H19.2759C19.6257 20.6076 19.8674 20.2576 19.7434 19.9304L19.2459 18.6172C18.9845 17.9272 19.1275 17.1516 19.5491 16.5461C20.1826 15.6363 21 14.225 21 12.9999M18 10.5C18 14.6421 14.6421 18 10.5 18H3.72413C3.3743 18 3.13262 17.65 3.25656 17.3228L3.89762 15.6309C4.12684 15.0259 4.03271 14.355 3.75001 13.7731C3.26951 12.784 3 11.6735 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                fill="none"
            />
        </SvgIcon>
    );
};

export default CreateCommentIcon;
