import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Input,
  MenuItem,
  Typography,
  styled,
} from '@mui/material';

export const ManageContentWrapper = styled(Box)(({ theme }) => ({
  background: '#F9F9F9',
  borderRadius: '20px',
  width: '100%',
  minHeight: '100%',
  padding: '24px 20px',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  fontFamily: 'neue-montreal-regular',
  color: '#000000',
  [theme.breakpoints.down('md')]: {
    // fontSize: '12px',
  },
}));

export const StyledPaginationInput = styled(Input)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  width: '50px',
  fontFamily: 'neue-montreal-regular',
  color: '#000000',
  padding: '2px 10px',
  border: '1px solid #E5E4E5',
  borderRadius: '8px',
  background: '#fff',

  '& .MuiInputBase-input': {
    textAlign: 'center',
  },

  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },

  // '& .MuiInputBase-root': {
  //     '&::before': {
  //         borderBottom: 'unset',
  //     },
  // },

  [theme.breakpoints.down('md')]: {
    // fontSize: '12px',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: '#fff',
  color: '#2C36F0',
  width: 'max-content',
  textTransform: 'capitalize',
  border: '1px solid #2C36F0',
  borderRadius: '10px',
  padding: '8px 24px',
  textAlign: 'center',

  '&:hover': {
    background: '#D5D7FC',
    color: '#2C36F0',
  },
  [theme.breakpoints.down('md')]: {},
}));

export const StyledImg = styled('img')(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: '10px',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    width: '48px',
    height: '48px',
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
  fontSize: '12px',
  lineHeight: '18px',
  fontFamily: 'neue-montreal-regular',
  [theme.breakpoints.down('md')]: {},
}));
export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: 14,
    fontFamily: 'neue-montreal-regular',
  },
  [theme.breakpoints.down('md')]: {},
}));

export const StyledPaginationButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid #2C36F0',
  width: 40,
  height: 40,
  borderRadius: '10px',
  padding: '8px',
  '& .MuiSvgIcon-root': {
    width: 16,
    height: 16,
    color: '#2C36F0',
  },

  '&:hover': {
    background: '#2C36F0',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#fff',
    },
  },

  '&:disabled': {
    opacity: '0.5',
    backgroundColor: '#ddd',
    borderColor: '#ddd',
    cursor: 'not-allowed',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  [theme.breakpoints.down('md')]: {},
}));
