import { PriceTypeValues } from '../constants/types';
import { RefObject } from 'react';
import moment, { Moment } from 'moment';
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  differenceInDays,
  parse,
  parseISO,
} from 'date-fns';

export const shouldDeleteImage = (url: string | null, name = 'key') => {
  return url
    ? {}
    : {
        [name]: {
          data: null,
        },
      };
};

export const getFileURL = (file: File | Blob | null) => {
  if (!file) {
    return '';
  }
  return URL.createObjectURL(file);
};

export function abbreviateNumber(value: number) {
  var newValue: number | string = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue: number | string = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (Number(shortValue) % 1 != 0) shortValue = Number(shortValue).toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export const shortNumberFormat = (num: number): string => {
  if (num >= 1000000) {
    return Math.floor(num / 1000000) + 'm';
  } else if (num >= 1000) {
    return Math.floor(num / 1000) + 'k';
  } else {
    return num.toString();
  }
};

export function timeAgo(timeObj: string, shortened = true): string {
  if (timeObj) {
    const now = moment();
    const then = moment(timeObj);

    const secondsAgo = now.diff(then, 'seconds');

    if (secondsAgo < 60) {
      return `${secondsAgo}${
        shortened ? 's' : ` second${secondsAgo > 1 ? 's' : ''}`
      }`; // Seconds
    } else if (secondsAgo < 3600) {
      return `${Math.floor(secondsAgo / 60)}${
        shortened ? 'm' : ` minute${Math.floor(secondsAgo / 60) > 1 ? 's' : ''}`
      }`; // Minutes
    } else if (secondsAgo < 86400) {
      return `${Math.floor(secondsAgo / 3600)}${
        shortened ? 'h' : ` hour${Math.floor(secondsAgo / 3600) > 1 ? 's' : ''}`
      }`; // Hours
    } else if (secondsAgo < 604800) {
      return `${Math.floor(secondsAgo / 86400)}${
        shortened ? 'd' : ` day${Math.floor(secondsAgo / 86400) > 1 ? 's' : ''}`
      }`; // Days
    } else {
      return `${Math.floor(secondsAgo / 604800)}${
        shortened
          ? 'w'
          : ` week${Math.floor(secondsAgo / 604800) > 1 ? 's' : ''}`
      }`; // Weeks
    }
  }
  return '';
}

export const isPriceSelected = (prices: PriceTypeValues[]) => {
  // Define the key you want to check for

  // Variable to track if the key with a true value is found
  let keyFound: any = false;

  keyFound = prices.find((price) => {
    return price.active && price.amount > 0;
  });

  // Check if the key was found or not
  if (!keyFound) {
    // console.log(`No object contains ${targetKey} with a true value.`);
    return false;
  }
  return true;
};

export const generateTicketId = (): string => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset.charAt(randomIndex);
  }

  return result;
};

export const scrollToRef = (scrollRef: RefObject<HTMLDivElement>) => {
  if (scrollRef.current) {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth', // Optional: Add smooth scrolling effect
      block: 'start', // Optional: Scroll to the top of the element
    });
  }
};

export function isDifferenceADay(date1: Moment, date2: Moment): boolean {
  // Calculate the difference in days using the diff method
  const differenceInDays = Math.abs(date1.diff(date2, 'days'));

  // Check if the difference is greater than or equal to 1
  return differenceInDays >= 1;
}

export const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content: any = el.getAttribute('content');
    let re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

export const convertTitleToSlug = (title: string) => {
  if (!title) return 'comment';
  return title
    .trim()
    .toLowerCase()
    .replace(/[\.,-\/#!$%\^&?\*;:{}=\-_`~()"']/g, ' ')
    .split(/[\s]+/)
    .filter((part) => part.length)
    .join('-')
    .slice(0, 50);
};

const parseTimestamp = (timestamp: string): Date => {
  // Try parsing as ISO string first
  let date = parseISO(timestamp);

  // If invalid, try parsing as "dd/MM/yyyy" format
  if (isNaN(date.getTime())) {
    date = parse(timestamp, 'dd/MM/yyyy', new Date());
  }

  // If still invalid, throw an error
  if (isNaN(date.getTime())) {
    throw new Error(
      'Invalid date format. Please use ISO string or dd/MM/yyyy format.',
    );
  }

  return date;
};

export const formatMessageTime = (timestamp: Date | string): string => {
  const date =
    typeof timestamp === 'string' ? parseTimestamp(timestamp) : timestamp;

  if (isToday(date)) {
    return format(date, 'h:mm a');
  }

  if (isYesterday(date)) {
    return 'Yesterday';
  }

  if (isThisWeek(date)) {
    return format(date, 'EEEE'); // Full day name
  }

  if (differenceInDays(new Date(), date) <= 365) {
    return format(date, 'dd/MM/yyyy');
  }

  // For messages older than a year
  return format(date, 'dd/MM/yyyy');
};
