import { ThemeProvider } from '@mui/material';
import React, {  createContext, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import theme, { darkTheme } from '../themes';
// import { SplitTreatments } from '@splitsoftware/splitio-react';
import { getValueFromLocalStore} from '../utils/localStorage';
import { STORAGE_KEYS } from '../constants/storage';

interface ThemeContextProps {
    lightThemeActive: boolean;
    toggleTheme: () => void;
    setLightThemeActive: any;
    updateTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps>({
    lightThemeActive: true,
    toggleTheme: () => { },
    setLightThemeActive: () => { },
    updateTheme: () => { },
});

interface ThemeProviderProps {
    children: React.ReactNode;
}

const INITIAL_STATE = { lightThemeEnabled: true };

const CustomThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const [localState, setUserData] = useLocalStorage(
        'user-theme-state',
        INITIAL_STATE,
    );
    const [lightThemeActive, setLightThemeActive] = useState(
        localState.lightThemeEnabled,
    );

    // const featureName = 'Theme_Flag';
    const userUid = getValueFromLocalStore(STORAGE_KEYS.UID);
    // const [lightThemeActiveSplitVersion, setLightThemeActiveSplitVersion] = useState<any>(null);
    const toggleTheme = () => {
        setUserData({ ...localState, lightThemeEnabled: lightThemeActive === false});

        try {
            import('../components/SegmentAnalytics').then(({ SegmentTrack }) => {
                SegmentTrack({
                    name: 'theme_changed',
                    properties: {
                        "userId": userUid,
                        "date_created": new Date().toISOString(),
                        theme: lightThemeActive === false ? 'Dark' : 'Light'
                    }
                })
            })
        } catch (error) {
            //
        }
        setLightThemeActive(lightThemeActive === false);
     
        // const currentThemeTreatment = getValueFromLocalStore('amakaSplitTreatment');
    //    if(lightThemeActiveSplitVersion !== null){
    //     setLightThemeActiveSplitVersion(null)
    //    }else{
    //     setLightThemeActiveSplitVersion(currentThemeTreatment === 'dark' ? true : false );
    //    }
       
    };

    const updateTheme = () => {
        setLightThemeActive(localState?.lightThemeEnabled);
    };

    // const splitTrackerComp = (data: any) => {
 
    //     const treatmentType = data.treatments[featureName]?.treatment
    //     splitTracker(data, featureName, { eventType: 'split_theme_loaded', flag: SPLIT_TREATMENT.THEME_FLAG });
    //     setValueInLocalStore('amakaSplitTreatment',treatmentType );
    //     return true;
    // }
return  (<ThemeContext.Provider
    value={{
        lightThemeActive,
        toggleTheme,
        setLightThemeActive,
        updateTheme,
    }}
>
    <ThemeProvider theme={lightThemeActive === false ? darkTheme :  theme}>
        {children}
    </ThemeProvider>
</ThemeContext.Provider>);
    // return (<SplitTreatments names={[featureName]} >
    //     {({ treatments, isReady, client }) => { // Passes down a TreatmentsWithConfig object and SplitContext properties like the boolean `isReady` flag.
    //         return splitTrackerComp({ treatments, isReady, featureName, client }) && isReady ?
    //             (<ThemeContext.Provider
    //                 value={{
    //                     lightThemeActive,
    //                     toggleTheme,
    //                     setLightThemeActive,
    //                     updateTheme,
    //                 }}
    //             >
    //                 <ThemeProvider theme={(lightThemeActiveSplitVersion !== null && lightThemeActiveSplitVersion === true) ? theme : (lightThemeActiveSplitVersion !== null && lightThemeActiveSplitVersion === false) ? darkTheme : ((treatments[featureName].treatment === 'light') ? theme : darkTheme)}>
    //                     {children}
    //                 </ThemeProvider>
    //             </ThemeContext.Provider>) :
    //             (<ThemeContext.Provider
    //                 value={{
    //                     lightThemeActive,
    //                     toggleTheme,
    //                     setLightThemeActive,
    //                     updateTheme,
    //                 }}
    //             >
    //                 <ThemeProvider theme={lightThemeActive ? theme : darkTheme}>
    //                     {children}
    //                 </ThemeProvider>
    //             </ThemeContext.Provider>);
    //     }}
    // </SplitTreatments>)
};

export { ThemeContext, CustomThemeProvider };
