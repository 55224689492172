import axios from 'axios';
import { getValueFromLocalStore } from '../utils/localStorage';
import { STORAGE_KEYS } from '../constants/storage';
import { ApiConfig } from './constants';

export const fetchData = async ({
    apiEndpoint,
    errorMessage,
    requiresAuthorization,
}: ApiConfig) => {
    try {
        const config: any = {
            method: 'get',
            url: apiEndpoint,
        };
        if (requiresAuthorization) {
            config.headers = {
                Authorization: `Bearer ${getValueFromLocalStore(
                    STORAGE_KEYS.JWT_TOKEN,
                )}`,
            };
        }
        const response = await axios(config);
        return response.data;
    } catch (error: any) {
        throw new Error(errorMessage);
    }
};

export const postData = async ({
    apiEndpoint,
    errorMessage,
    requiresAuthorization,
    method,
    data
}: ApiConfig) => {
    try {
        const config: any = {
            method: method||'post',
            url: apiEndpoint,
            ...(data && {data:data})
        };
        if (requiresAuthorization) {
            config.headers = {
                Authorization: `Bearer ${getValueFromLocalStore(
                    STORAGE_KEYS.JWT_TOKEN,
                )}`,
            };
        }
        const response = await axios(config);
        return response.data;
    } catch (error: any) {
        throw new Error(error?.message || errorMessage);
    }
};
