import http from '../../config/http.config';
import qs from 'qs';
const CHALLENGES_API = 'api/campaign-details';
const CHALLENGES_ARTICLE_API = 'api/challenge-articles';
type referralUserData = {
  user: {
    firstname: string;
    lastname: string;
    email: string;
  };
  extraData: { uid: string };
  referrer: {
    referralCode: string;
  };
};
export const search = async (filter: any) => {
  try {
    const stringifiedQuery = qs.stringify(filter);

    const response = await http.get(`${CHALLENGES_API}?${stringifiedQuery}`);
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch challenges',
      status: error.response?.status || 500,
    };
  }
};
export const findById = async (id: string) => {
  try {
    const response = await http.get(`${CHALLENGES_API}/${id}`);
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch challenges',
      status: error.response?.status || 500,
    };
  }
};
export const getLeaderBoardDetails = async (id: string) => {
  try {
    const response = await getLeaderBoardDetailsByQuery(id);
    return response;
  } catch (error: any) {
    console.error({ error });
    return {
      message: 'Failed to fetch challenges',
      status: error.response?.status || 500,
    };
  }
};
export const getLeaderBoardDetailsByQuery = async (
  id: string,
  filter?: any,
) => {
  let url = `${CHALLENGES_API}/leaderboard/${id}`;
  if (filter) {
    const query = qs.stringify(filter);
    url += `?${query}`;
  }
  const response = await http.get(url);
  return JSON.parse(JSON.stringify(response.data));
};
export const getLeaderBoardDetailsByUser = async (
  id: string,
  search: string,
) => {
  try {
    if (!search) {
      return [];
    }
    // const response = await http.get(
    // `${CHALLENGES_API}/leaderboard/${id}?search=${search}`,
    // );
    const response = await getLeaderBoardDetailsByQuery(id, {
      search: search,
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    return {
      message: 'Failed to fetch challenges',
      status: error?.response?.status || 500,
    };
  }
};
export const registerParticipant = async (id: any, user: any) => {
  try {
    if (!user.uid) {
      return [];
    }
    const response = await http.post(
      `${CHALLENGES_API}/${id}/participant/register`,
      user,
    );
    return JSON.parse(JSON.stringify(response.data));
  } catch (error: any) {
    return {
      message: 'Failed to fetch challenges',
      status: error.response.status || 500,
    };
  }
};
export const getParticipantCount = async (id: string): Promise<any> => {
  try {
    const getResult = http.get(`${CHALLENGES_ARTICLE_API}/${id}/count`);
    return getResult;
  } catch (error: any) {
    return {
      message: 'Failed to fetch challenges',
      status: error.response.status || 500,
    };
  }
};
export const getLeaderBoardCalculation = async (
  id: string,
  email: string,
): Promise<any> => {
  try {
    if (!id || !email) {
      return {};
    }
    const getResult = http.get(
      `${CHALLENGES_API}/${id}/leaderboard/ranking?email=${email}`,
    );
    return getResult;
  } catch (error: any) {
    return {
      message: 'Failed to fetch challenges',
      status: error.response.status || 500,
    };
  }
};

export const registerReferralByReferralCode = async (
  userData: referralUserData,
  objective: string,
): Promise<any> => {
  try {
    if (!userData.user.email) {
      return {};
    }
    if (!userData.user.firstname || !userData.user.lastname) {
      const newName = userData.user.email.split('@')[0];
      userData.user.firstname = userData.user.firstname || newName;
      userData.user.lastname = userData.user.lastname || newName;
    }
    const getResult = await http.post(
      `${CHALLENGES_API}/participant/referral`,
      { userData, objective },
    );

    return getResult;
  } catch (error: any) {
    return {
      message: 'Failed to fetch challenges',
      status: error.response.status || 500,
    };
  }
};

export const getReferralCount = async (objective: string): Promise<any> => {
  try {
    const getResult = await http.get(
      `${CHALLENGES_API}/participant/referral/count?objective=${objective}`,
    );

    return getResult;
  } catch (error: any) {
    return {
      message: 'Failed to fetch referralCount',
      status: error.response.status || 500,
    };
  }
};
export default {
  search,
  registerParticipant,
  registerReferralByReferralCode,
  getReferralCount,
};
