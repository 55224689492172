import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogStack, StyledCloseIcon, StyledDialog, StyledDialogContentText, StyledDialogTitle, StyledTextField, StyledTypography } from './styles';
import { Stack, Typography } from '@mui/material';
import { StyledAlert } from '../../../Audience/styles';
import CloseIcon from '../../../../../assets/icons/CloseIcon';
import { useLoading } from '../../../../../context/LoadingContext';
import { importContent } from '../api';
import { SegmentTrack } from '../../../../../components/SegmentAnalytics';
import useAuth from '../../../../../hooks/useAuth';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}


export const ImportDialog: React.FC<Props> = ({ isOpen, onClose }) => {
    const [link, setLink] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const { setSiteLoader } = useLoading();
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { userInfo } = useAuth();


    const handleConfirm = () => {
        setSiteLoader(true);
        importContent(link).then(() => {
            setMessage("We're currently processing your content. Once it's complete, you'll receive an email notification. Please refresh your page after receiving the email.")
            setSuccess(true);
            let source = "unknown"
            if (link.includes("tiktok.com")) {
                source = "tiktok"
            }
            else if (link.includes("instagram.com")) {
                source = "instagram"
            }
            else if (link.includes("youtube.com")) {
                source = "youtube"
            }
            else if (link.includes("substack.com")) {
                source = "substack"
            }
            else if (link.includes("x.co") || link.includes("twitter.com")) {
                source = "x"
            }
            SegmentTrack({
                name: 'import_content',
                properties: {
                    "userId": userInfo.uid, 
                    "date_created": new Date().toISOString(),
                    link,
                    source,
                    status: "success"
                }
            })
            setTimeout(() => {
                setSuccess(false);
            }, 10000);

        }).catch((error: Error) => {
            console.log(error)
            setMessage(error.message.toString());
            setError(true);
            SegmentTrack({
                name: 'import_content',
                properties: {
                    "userId": userInfo.uid, 
                    "date_created": new Date().toISOString(),
                    link,
                    "message": error.message,
                    status: "error"
                }
            })
            setTimeout(() => {
                setError(false);
            }, 10000);

        }).finally(() => {
            setSiteLoader(false)
            setLink("")
            onClose()
        })
    };

    const canSubmit = () => {
        const urlPattern: RegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return link && urlPattern.test(link);
    }


    return (
        <>
            <StyledDialog fullWidth={true} maxWidth="sm" open={isOpen} onClose={onClose}>
                <StyledCloseIcon
                    aria-label="close"
                    onClick={onClose}
                    sx={{ color: '#ff0035' }}
                >
                    <CloseIcon />
                </StyledCloseIcon>
                <StyledDialogTitle>
                    Import Content
                </StyledDialogTitle>
                <DialogContent sx={{ color: "black", padding: 0 }}>
                    <StyledDialogContentText>
                        Easily import existing content into your publication list from various websites including TikTok, X, Instagram, Youtube and Substack.

                        <StyledTypography sx={{ color: "#808080", marginTop: "10px" }}>
                            {`When sharing your content on TikTok, Twitter, and Instagram, please provide your profile link. For Substack, include your account link (e.g., https://your.substack.com/feed), and for YouTube, share your channel link (e.g., https://www.youtube.com/channel/<channel_id>)`}
                        </StyledTypography>
                    </StyledDialogContentText>

                    <DialogStack spacing={5}>
                        <Stack spacing={2}>
                            <Typography>
                                Source link
                            </Typography>
                            <StyledTextField
                                onChange={(e: any) => setLink(e.target?.value ?? "")}
                                sx={{ padding: 0 }}
                                placeholder="Paste or type a URL"
                            />
                            <StyledTypography sx={{ color: "#808080" }}>
                                Import your content in just a few steps!
                            </StyledTypography>
                        </Stack>
                    </DialogStack>

                </DialogContent>
                <DialogActions>
                    <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={onClose} variant="outlined" color="primary" sx={{ textTransform: "capitalize" }}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} variant="contained" color="primary" sx={{ textTransform: "capitalize" }} disabled={!canSubmit()}>
                            Import Content
                        </Button>
                    </Stack>

                </DialogActions>
            </StyledDialog >
            {
                (success || error) &&
                <StyledAlert severity={success ? `success` : `error`}>
                    {message}
                </StyledAlert>
            }
        </>
    );
};

