
export const XIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.7812 40.5L30.7847 25.9295L30.8018 25.9432L39.8151 15.5H36.8031L29.4606 24L23.6297 15.5H15.7302L25.063 29.1034L25.0618 29.1023L15.2188 40.5H18.2308L26.394 31.0432L32.8818 40.5H40.7812ZM22.4362 17.7727L36.4621 38.2273H34.0752L20.038 17.7727H22.4362Z" fill="white" />
        </svg>

    )
}

export const FacebookIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="#1877F2" />
            <path d="M29.8068 41.3329V29.1689H33.8895L34.5015 24.4289H29.8082V21.4022C29.8082 20.0289 30.1882 19.0942 32.1562 19.0942L34.6668 19.0929V14.8529C33.452 14.7243 32.2311 14.662 31.0095 14.6662C27.3895 14.6662 24.9122 16.8755 24.9122 20.9329V24.4289H20.8188V29.1689H24.9122V41.3329H29.8068Z" fill="white" />
        </svg>

    )
}

export const InstagramIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="url(#paint0_radial_9865_8961)" />
            <circle cx="28" cy="28" r="28" fill="url(#paint1_radial_9865_8961)" />
            <path d="M22.3998 14.667H33.5998C37.8665 14.667 41.3332 18.1337 41.3332 22.4003V33.6003C41.3332 35.6513 40.5184 37.6183 39.0681 39.0686C37.6178 40.5189 35.6508 41.3337 33.5998 41.3337H22.3998C18.1332 41.3337 14.6665 37.867 14.6665 33.6003V22.4003C14.6665 20.3493 15.4813 18.3823 16.9315 16.932C18.3818 15.4818 20.3488 14.667 22.3998 14.667ZM22.1332 17.3337C20.8601 17.3337 19.6392 17.8394 18.7391 18.7395C17.8389 19.6397 17.3332 20.8606 17.3332 22.1337V33.867C17.3332 36.5203 19.4798 38.667 22.1332 38.667H33.8665C35.1395 38.667 36.3604 38.1613 37.2606 37.2611C38.1608 36.3609 38.6665 35.14 38.6665 33.867V22.1337C38.6665 19.4803 36.5198 17.3337 33.8665 17.3337H22.1332ZM34.9998 19.3337C35.4419 19.3337 35.8658 19.5093 36.1783 19.8218C36.4909 20.1344 36.6665 20.5583 36.6665 21.0003C36.6665 21.4424 36.4909 21.8663 36.1783 22.1788C35.8658 22.4914 35.4419 22.667 34.9998 22.667C34.5578 22.667 34.1339 22.4914 33.8213 22.1788C33.5088 21.8663 33.3332 21.4424 33.3332 21.0003C33.3332 20.5583 33.5088 20.1344 33.8213 19.8218C34.1339 19.5093 34.5578 19.3337 34.9998 19.3337ZM27.9998 21.3337C29.7679 21.3337 31.4636 22.036 32.7139 23.2863C33.9641 24.5365 34.6665 26.2322 34.6665 28.0003C34.6665 29.7684 33.9641 31.4641 32.7139 32.7144C31.4636 33.9646 29.7679 34.667 27.9998 34.667C26.2317 34.667 24.536 33.9646 23.2858 32.7144C22.0355 31.4641 21.3332 29.7684 21.3332 28.0003C21.3332 26.2322 22.0355 24.5365 23.2858 23.2863C24.536 22.036 26.2317 21.3337 27.9998 21.3337ZM27.9998 24.0003C26.939 24.0003 25.9216 24.4218 25.1714 25.1719C24.4213 25.922 23.9998 26.9395 23.9998 28.0003C23.9998 29.0612 24.4213 30.0786 25.1714 30.8288C25.9216 31.5789 26.939 32.0003 27.9998 32.0003C29.0607 32.0003 30.0781 31.5789 30.8283 30.8288C31.5784 30.0786 31.9998 29.0612 31.9998 28.0003C31.9998 26.9395 31.5784 25.922 30.8283 25.1719C30.0781 24.4218 29.0607 24.0003 27.9998 24.0003Z" fill="white" />
            <defs>
                <radialGradient id="paint0_radial_9865_8961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28 2.5) rotate(90) scale(58)">
                    <stop stopColor="#C837AB" />
                    <stop offset="0.13157" stopColor="#C837AB" />
                    <stop offset="0.505" stopColor="#FF543E" />
                    <stop offset="0.91" stopColor="#FFDD55" />
                    <stop offset="1" stopColor="#FFDD55" />
                </radialGradient>
                <radialGradient id="paint1_radial_9865_8961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-10 3) rotate(74.4454) scale(50.3438)">
                    <stop stopColor="#3771C8" />
                    <stop offset="0.13157" stopColor="#3771C8" />
                    <stop offset="1" stopColor="#6600FF" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export const WhatsAppIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="#60D669" />
            <path d="M37.4001 18.5471C36.1777 17.3123 34.7216 16.3333 33.1169 15.6671C31.5122 15.001 29.7909 14.661 28.0534 14.6671C20.7734 14.6671 14.8401 20.6004 14.8401 27.8804C14.8401 30.2137 15.4534 32.4804 16.6001 34.4804L14.7334 41.3337L21.7334 39.4937C23.6667 40.5471 25.8401 41.1071 28.0534 41.1071C35.3334 41.1071 41.2667 35.1737 41.2667 27.8937C41.2667 24.3604 39.8934 21.0404 37.4001 18.5471ZM28.0534 38.8671C26.0801 38.8671 24.1467 38.3337 22.4534 37.3337L22.0534 37.0937L17.8934 38.1871L19.0001 34.1337L18.7334 33.7204C17.6371 31.9697 17.0549 29.9461 17.0534 27.8804C17.0534 21.8271 21.9867 16.8937 28.0401 16.8937C30.9734 16.8937 33.7334 18.0404 35.8001 20.1204C36.8234 21.139 37.6343 22.3506 38.1859 23.685C38.7375 25.0193 39.0188 26.4499 39.0134 27.8937C39.0401 33.9471 34.1067 38.8671 28.0534 38.8671ZM34.0801 30.6537C33.7467 30.4937 32.1201 29.6937 31.8267 29.5737C31.5201 29.4671 31.3067 29.4137 31.0801 29.7337C30.8534 30.0671 30.2267 30.8137 30.0401 31.0271C29.8534 31.2537 29.6534 31.2804 29.3201 31.1071C28.9867 30.9471 27.9201 30.5871 26.6667 29.4671C25.6801 28.5871 25.0267 27.5071 24.8267 27.1737C24.6401 26.8404 24.8001 26.6671 24.9734 26.4937C25.1201 26.3471 25.3067 26.1071 25.4667 25.9204C25.6267 25.7337 25.6934 25.5871 25.8001 25.3737C25.9067 25.1471 25.8534 24.9604 25.7734 24.8004C25.6934 24.6404 25.0267 23.0137 24.7601 22.3471C24.4934 21.7071 24.2134 21.7871 24.0134 21.7737H23.3734C23.1467 21.7737 22.8001 21.8537 22.4934 22.1871C22.2001 22.5204 21.3467 23.3204 21.3467 24.9471C21.3467 26.5737 22.5334 28.1471 22.6934 28.3604C22.8534 28.5871 25.0267 31.9204 28.3334 33.3471C29.1201 33.6937 29.7334 33.8937 30.2134 34.0404C31.0001 34.2937 31.7201 34.2537 32.2934 34.1737C32.9334 34.0804 34.2534 33.3737 34.5201 32.6004C34.8001 31.8271 34.8001 31.1737 34.7067 31.0271C34.6134 30.8804 34.4134 30.8137 34.0801 30.6537Z" fill="white" />
            <path d="M37.4001 18.5471C36.1777 17.3123 34.7216 16.3333 33.1169 15.6671C31.5122 15.001 29.7909 14.661 28.0534 14.6671C20.7734 14.6671 14.8401 20.6004 14.8401 27.8804C14.8401 30.2137 15.4534 32.4804 16.6001 34.4804L14.7334 41.3337L21.7334 39.4937C23.6667 40.5471 25.8401 41.1071 28.0534 41.1071C35.3334 41.1071 41.2667 35.1737 41.2667 27.8937C41.2667 24.3604 39.8934 21.0404 37.4001 18.5471ZM28.0534 38.8671C26.0801 38.8671 24.1467 38.3337 22.4534 37.3337L22.0534 37.0937L17.8934 38.1871L19.0001 34.1337L18.7334 33.7204C17.6371 31.9697 17.0549 29.9461 17.0534 27.8804C17.0534 21.8271 21.9867 16.8937 28.0401 16.8937C30.9734 16.8937 33.7334 18.0404 35.8001 20.1204C36.8234 21.139 37.6343 22.3506 38.1859 23.685C38.7375 25.0193 39.0188 26.4499 39.0134 27.8937C39.0401 33.9471 34.1067 38.8671 28.0534 38.8671ZM34.0801 30.6537C33.7467 30.4937 32.1201 29.6937 31.8267 29.5737C31.5201 29.4671 31.3067 29.4137 31.0801 29.7337C30.8534 30.0671 30.2267 30.8137 30.0401 31.0271C29.8534 31.2537 29.6534 31.2804 29.3201 31.1071C28.9867 30.9471 27.9201 30.5871 26.6667 29.4671C25.6801 28.5871 25.0267 27.5071 24.8267 27.1737C24.6401 26.8404 24.8001 26.6671 24.9734 26.4937C25.1201 26.3471 25.3067 26.1071 25.4667 25.9204C25.6267 25.7337 25.6934 25.5871 25.8001 25.3737C25.9067 25.1471 25.8534 24.9604 25.7734 24.8004C25.6934 24.6404 25.0267 23.0137 24.7601 22.3471C24.4934 21.7071 24.2134 21.7871 24.0134 21.7737H23.3734C23.1467 21.7737 22.8001 21.8537 22.4934 22.1871C22.2001 22.5204 21.3467 23.3204 21.3467 24.9471C21.3467 26.5737 22.5334 28.1471 22.6934 28.3604C22.8534 28.5871 25.0267 31.9204 28.3334 33.3471C29.1201 33.6937 29.7334 33.8937 30.2134 34.0404C31.0001 34.2937 31.7201 34.2537 32.2934 34.1737C32.9334 34.0804 34.2534 33.3737 34.5201 32.6004C34.8001 31.8271 34.8001 31.1737 34.7067 31.0271C34.6134 30.8804 34.4134 30.8137 34.0801 30.6537Z" fill="white" />
        </svg>

    )
}

export const TikTokIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="black" />
            <g clipPath="url(#clip0_9897_19211)">
                <path d="M31.7135 19.5224C34.0479 20.9949 36.9076 21.8614 39.9962 21.8614V16.6167C39.4116 16.6168 38.8286 16.5629 38.2568 16.456V20.5843C35.1684 20.5843 32.3091 19.718 29.9742 18.2456V28.9486C29.9742 34.3028 25.0556 38.6429 18.9887 38.6429C16.725 38.6429 14.6209 38.039 12.873 37.0032C14.8679 38.8033 17.6499 39.9198 20.7276 39.9198C26.7951 39.9198 31.7138 35.5798 31.7138 30.2253V19.5224H31.7135ZM33.8594 14.2311C32.6664 13.081 31.883 11.5946 31.7135 9.95139V9.27686H30.0652C30.4801 11.3654 31.8955 13.1496 33.8594 14.2311ZM16.7104 32.8946C16.0438 32.1235 15.6835 31.18 15.6852 30.2099C15.6852 27.7612 17.9349 25.7756 20.7105 25.7756C21.2277 25.7754 21.7418 25.8455 22.2349 25.9834V20.6214C21.6587 20.5518 21.0773 20.5221 20.4962 20.533V24.7065C20.0029 24.5684 19.4885 24.4984 18.9711 24.4988C16.1956 24.4988 13.946 26.4841 13.946 28.9332C13.946 30.665 15.0704 32.1642 16.7104 32.8946Z" fill="#FF004F" />
                <path d="M29.9743 18.2455C32.3093 19.7179 35.1684 20.5842 38.2568 20.5842V16.4559C36.5329 16.1318 35.0068 15.3369 33.8594 14.2311C31.8953 13.1495 30.4802 11.3652 30.0653 9.27686H25.7357V30.225C25.7258 32.6672 23.48 34.6446 20.7103 34.6446C19.0784 34.6446 17.6284 33.9581 16.7102 32.8945C15.0705 32.1642 13.9459 30.6649 13.9459 28.9333C13.9459 26.4845 16.1955 24.4989 18.971 24.4989C19.5028 24.4989 20.0153 24.572 20.4961 24.7066V20.5331C14.5357 20.6418 9.74219 24.9395 9.74219 30.2252C9.74219 32.8637 10.9358 35.2557 12.8733 37.0034C14.6211 38.039 16.7251 38.6431 18.9889 38.6431C25.0559 38.6431 29.9744 34.3027 29.9744 28.9486L29.9743 18.2455Z" fill="white" />
                <path d="M38.2559 16.4557V15.3397C36.7013 15.3418 35.1774 14.9576 33.8584 14.2311C35.026 15.359 36.5634 16.1369 38.2559 16.4559M30.0643 9.27669C30.0247 9.07714 29.9944 8.87626 29.9733 8.67454V8H23.9951V28.9484C23.9856 31.3903 21.7397 33.3677 18.97 33.3677C18.1848 33.3688 17.4103 33.2067 16.7091 32.8947C17.6274 33.9581 19.0773 34.6444 20.7093 34.6444C23.4788 34.6444 25.7249 32.6673 25.7347 30.2251V9.2768L30.0643 9.27669ZM20.4955 20.533V19.3447C19.9959 19.2844 19.4923 19.2542 18.9881 19.2544C12.9204 19.2544 8.00195 23.5947 8.00195 28.9484C8.00195 32.305 9.93498 35.2631 12.8725 37.0031C10.9351 35.2555 9.74139 32.8634 9.74139 30.225C9.74139 24.9395 14.5348 20.6417 20.4955 20.533Z" fill="#00F2EA" />
            </g>
            <defs>
                <clipPath id="clip0_9897_19211">
                    <rect width="32" height="32" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const LinkedInIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="#0B66C2" />
            <path d="M21.2533 18.667C21.2529 19.3743 20.9716 20.0524 20.4713 20.5523C19.9709 21.0521 19.2925 21.3327 18.5853 21.3324C17.878 21.332 17.1999 21.0507 16.7 20.5504C16.2002 20.05 15.9196 19.3716 15.9199 18.6644C15.9203 17.9571 16.2016 17.279 16.7019 16.7791C17.2023 16.2793 17.8807 15.9987 18.5879 15.999C19.2952 15.9994 19.9733 16.2807 20.4732 16.781C20.973 17.2814 21.2536 17.9598 21.2533 18.667ZM21.3333 23.307H15.9999V40.0004H21.3333V23.307ZM29.7599 23.307H24.4533V40.0004H29.7066V31.2404C29.7066 26.3604 36.0666 25.907 36.0666 31.2404V40.0004H41.3333V29.427C41.3333 21.2004 31.9199 21.507 29.7066 25.547L29.7599 23.307Z" fill="white" />
        </svg>

    )
}