/* eslint-disable no-unused-vars */
import React from 'react';
import { segmentTrackType } from '../../constants/types';
import { SUBSCRIPTION_TYPE } from '../../constants';

export const SegmentTrack = async ({ name, properties }: segmentTrackType) => {
    // track different actions
    await window.analytics.track(name, properties);
};

export const SegmentIdentifyOnboarding = (props: any) => {
    const { id, user, data } = props;
    const userInfo = user?.data?.attributes;
    const userProfileData = userInfo?.users_permissions_user?.data?.attributes;
    window.analytics.identify(id, {
        name: `${data?.firstName} ${data?.lastName}`,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: userProfileData?.email,
        createdAt: userProfileData?.createdAt,
        user_type: 'reader',
        phone: data?.phone,
        handle: data?.handle,
        facebook_link: data?.facebookLink,
        instagram_link: data?.instagramLink,
        other_link: data?.otherLink,
        twitter_link: data?.twitterLink,
        birthday: data?.dob,
        address: {
            country: data?.location,
        },
    });
};

export const TrackOnboardingProfile = async (props: any) => {
    const { id, data } = props;
    await window.analytics.track('onboarding_profile', {
        date_created: new Date().toISOString(),
        first_name: data?.firstName,
        last_name: data?.lastName,
        handle: data?.handle,
        phone: data?.phone,
        country_code: data?.countryCode,
        birthday: data?.dob,
        bio: data?.bio,
        facebook_link: data?.facebookLink,
        instagram_link: data?.instagramLink,
        other_link: data?.otherLink,
        twitter_link: data?.twitterLink,
        cover_image: data?.coverImage,
        profile_image: data?.profileImage,
        address: {
            country: data?.location,
        },
        userId: id,
    });
};

export const SegmentIdentifyOnboardingInterest = (props: any) => {
    const { uid, user } = props;
    const userInfo = user?.data?.attributes;
    const userProfileData = userInfo?.users_permissions_user?.data?.attributes;
    window.analytics.identify(uid || userProfileData.uid, {
        signup_type: props?.type,
        interest_details: props?.interest,
    });
};

export const TrackOnboardingInterest = async (props: any) => {
    await window.analytics.track('onboarding_interest', {
        userId: props.id,
        date_created: new Date().toISOString(),
        interest_details: props?.interest,
        signup_type: props?.type,
    });
};

export const SegmentIdentifyProfileUpdate = async (props: any) => {
    const { id, user, initial, data } = props;
    const userInfo = initial ? user?.data?.attributes : user?.attributes;
    const userProfileData = userInfo?.users_permissions_user?.data?.attributes;
    window.analytics.identify(userProfileData.uid, {
        name: `${data?.firstName} ${data?.lastName}`,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: userProfileData?.email,
        createdAt: userProfileData?.createdAt,
        ...(initial && { user_type: 'reader' }),
        birthday: data?.dob,
        address: {
            country: data?.location,
        },
        phone: data?.phone,
        handle: data?.handle,
        facebook_link: data?.facebookLink,
        instagram_link: data?.instagramLink,
        other_link: data?.otherLink,
        twitter_link: data?.twitterLink,
        last_login_date: userProfileData?.last_loggedin_at,
    });

    await window.analytics.track('profile_updated', {
        userId: userProfileData.uid,
        date_created: new Date().toISOString(),
        first_name: data?.firstName,
        last_name: data?.lastName,
        handle: data?.handle,
        phone: data?.phone,
        country_code: data?.countryCode,
        birthday: data?.dob,
        bio: data?.bio,
        facebook_link: data?.facebookLink,
        instagram_link: data?.instagramLink,
        other_link: data?.otherLink,
        twitter_link: data?.twitterLink,
        cover_image: data?.coverImage,
        profile_image: data?.profileImage,
        address: {
            country: data?.location,
        },
    });
};

export const SegmentIdentifyContent = async (props: any) => {
    const { id, user, content } = props;
    const userInfo = user?.attributes;
    const userProfileData = userInfo?.users_permissions_user?.data?.attributes;
    await window.analytics.identify(id || userProfileData.uid, {
        user_type: 'creator',
        last_upload_date: content?.publishedAt,
    });
};

export const TrackContentUploaded = async (props: any) => {
    const { id, user, content } = props;
    const userInfo = user?.attributes;
    const userProfileData = userInfo?.users_permissions_user?.data?.attributes;

    await window.analytics.track('content_uploaded', {
        date_created: content?.createdAt,
        content_title: content?.title,
        content_id: content?.id,
        content_creator: `${userProfileData?.first_name} ${userProfileData?.last_name}`,
        content_type: content?.content_type,
        content_price_type: content?.is_exclusive ? 'paid' : 'free',
        content_category: content?.category?.name,
        userId: id,
        user_type: 'creator',
    });
};

export const TrackContentViewed = async (props: any) => {
    const { id, content } = props;

    await window.analytics.track('content_viewed', {
        contentId: content?.id,
        date_created: content?.publishedAt,
        content_title: content?.title,
        ...(content?.user?.first_name && {
            content_creator: `${content?.user?.first_name || ''} ${
                content?.user?.last_name || ''
            }`,
        }),
        content_type: content?.content_type,
        content_price_type: content?.is_exclusive ? 'paid' : 'free',
        content_category: content?.category?.name,
        userId: id,
        ...(!id && { creatorId: content?.user?.uid }),
    });
};

export const TrackContentActions = async (props: any) => {
    const { id, content, content_text, name, extra } = props;
    if( !name){
        return;
    }
    await window.analytics.track(name, {
        contentId: content?.id,
        content_text,
        date_created: content.publishedAt,
        content_title: content?.title,
        content_creator: `${content?.user?.first_name} ${content?.user?.last_name}`,
        content_type: content?.content_type,
        content_price_type: content?.is_exclusive ? 'paid' : 'free',
        content_category: content?.category?.name,
        userId: id,
        ...extra,
    });
};

export const TrackGigActions = async (props: any) => {
    const { id, gig, name, extra } = props;
    await window.analytics.track(name, {
        date_created: new Date().toISOString(),
        gig,
        userId: id,
        ...extra,
    });
};

export const TrackSubscriptionAction = async (props: any) => {
    const {
        name,
        userId,
        articleId,
        title,
        contentType,
        source,
        author_name,
        subscriptionType,
    } = props;
    await window.analytics.track(name, {
        userId,
        articleId,
        date_recorded: new Date().toISOString(),
        content_type: contentType,
        title,
        source,
        author_name,
        subscription_type: subscriptionType,
    });
};

export const identifyAndTrackSignUp = async (props: any) => {
    const { user } = props;

    await window.analytics.identify(user.uid, {
        name: `${user?.first_name} ${user?.last_name}`,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        createdAt: user?.createdAt,
        user_type: 'reader',

        last_login_date: user?.last_loggedin_at,
    });

    await window.analytics.track('signed_up', {
        userId: user.uid,
        date_created: new Date().toISOString(),
        last_login_date: user?.last_loggedin_at,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        createdAt: user?.createdAt,
        source: props.source || null,
    });
};

export const identifyAndTrackLogin = async (props: any) => {
    const { user } = props;

    await window.analytics.identify(user.uid, {
        last_login_date: new Date().toISOString(),
    });

    await window.analytics.track('logged_in', {
        userId: user.uid,
        date_created: new Date().toISOString(),
        last_login_date: new Date().toISOString(),
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        createdAt: user?.createdAt,
    });
};

export const identifyAndTrackLogout = async (props: any) => {
    const { user } = props;
    if (!user?.uid) return;

    await window.analytics.identify(user.uid);
    //    , {
    //         name: `${user?.first_name} ${user?.lastName}`,
    //         first_name: user?.firstName,
    //         last_name: user?.lastName,
    //         email: user?.email,

    //         last_login_date: user?.lastLoggedInAt,
    //     });

    await window.analytics.track('logged_out', {
        userId: user.uid,
        date_created: new Date().toISOString(),
        last_login_date: user?.lastLoggedInAt,
        first_name: user?.firstName,
        last_name: user?.lastName,
        email: user?.email,
    });
};

export const identifyAndTrackSubscription = async (props: any) => {
    const { user, creator, subscription, subscribeLabel, subscribeFrom } =
        props;

    const commonAttr = {
        subscription_type:
            subscription.type != SUBSCRIPTION_TYPE.FREE
                ? 'paid'
                : SUBSCRIPTION_TYPE.FREE,
        subscription_period:
            subscription.type != SUBSCRIPTION_TYPE.FREE
                ? null
                : subscription.type,
        subscription_fee: subscription.amount || 0,
    };

    await window.analytics.identify(user.uid, commonAttr);

    await window.analytics.track(subscribeLabel, {
        userId: user.uid,
        subscribeFrom,
        last_login_date: user?.lastLoggedInAt,
        first_name: user?.firstName,
        last_name: user?.lastName,
        email: user?.email,
        ...commonAttr,
        date_created: new Date().toISOString(),
        revenue: subscription.amount || 0,
        creator_name: creator?.authorName,
        creator_id: creator.authorId,
        currency: subscription.currency || 'USD',
    });
};

export const identifyAndTrackUnSubscribed = async (props: any) => {
    const { user, creator, subscription } = props;
    const commonAttr = {
        date_created: new Date().toISOString(),
        subscriber: user.uid,
        creator_id: creator.id,
        last_login_date: user?.lastLoggedInAt,
    };

    await window.analytics.identify(user.uid);

    await window.analytics.track('unsubscribed', {
        userId: user.uid,
        subscription_type: subscription.type || 'free',
        first_name: user?.firstName,
        last_name: user?.lastName,
        email: user?.email,
        ...commonAttr,
    });
};
export const commonIdentify = async ({
    uid,
    data,
}: {
    uid: string;
    data: any;
}) => {
    window.analytics.identify(uid, data);
};
