import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import { DialogStack, StyledCloseIcon, StyledDialog, StyledDialogContentText, StyledDialogTitle, StyledTextField, StyledTypography } from './styles';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import { Stack, Typography } from '@mui/material';
import { SimpleSelect } from '../../../common/SimpleSelect';
import { FileUploadInput } from '../../../common/FileUploadInput';
import { uploadSubscriberCSV, uploadSubscriberList } from '../apis';
import { useLoading } from '../../../../context/LoadingContext';
import { StyledAlert } from '../styles';
import { SegmentTrack } from '../../../../components/SegmentAnalytics';
import useAuth from '../../../../hooks/useAuth';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    refCode: string;
    link: string;
}

const UPLOAD_ENUM = {
    UPLOAD_CSV: 'csv',
    COPY_PASTE_EMAILS: 'copy-paste'
};

const selectOptions = [
    { label: 'Upload CSV', value: UPLOAD_ENUM.UPLOAD_CSV },
    { label: 'Copy and paste a list of email addresses', value: UPLOAD_ENUM.COPY_PASTE_EMAILS },
]


export const ImportDialog: React.FC<Props> = ({ isOpen, onClose, refCode, link }) => {
    const [selectedOption, setSelectedOption] = useState(selectOptions[0].value)
    const [file, setFile] = useState<File | null>()
    const [emails, setEmails] = useState<string>()
    const [message, setMessage] = useState<string>("")
    const { setSiteLoader } = useLoading();
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { userInfo } = useAuth();


    const handleOptionChange = (event: SelectChangeEvent<any>) => {
        setSelectedOption(event.target.value);

    };

    const handleConfirm = () => {
        const url = `${link}?ref=${refCode}&utm_source=CSV`
        if (selectedOption === UPLOAD_ENUM.UPLOAD_CSV) {
            setSiteLoader(true);
            uploadSubscriberCSV(file!, url).then(() => {
                setMessage("Successful uploaded subscribers")
                setSuccess(true);
                SegmentTrack({
                    name: 'import_subscribers',
                    properties: {
                        "userId": userInfo.uid,
                        "source": "CSV",
                        "type": "file",
                        "date_created": new Date().toISOString(),
                        link
                    }
                })
                setTimeout(() => {
                    setSuccess(false);
                }, 10000);

            }).catch((error: Error) => {
                setMessage(error.message.toString());
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 10000);

            }).finally(() => {
                setSiteLoader(false)
                setFile(undefined)
                onClose()
                // import('../SegmentAnalytics').then(({ SegmentTrack }) => {
                //     SegmentTrack({
                //         name: 'content_prompt_input_click_new_homepage',
                //         properties: {
                //             userId: userInfo.uid,
                //             date_created: new Date().toISOString(),
                //             contentType: contentType.TITLE,
                //         },
                //     });
                // });
            })

        }
        else if (selectedOption === UPLOAD_ENUM.COPY_PASTE_EMAILS) {
            setSiteLoader(true);
            uploadSubscriberList(emails!, url).then(() => {
                setMessage("Successful uploaded subscribers")
                setSuccess(true);
                SegmentTrack({
                    name: 'import_subscribers',
                    properties: {
                        "userId": userInfo.uid,
                        "source": "CSV",
                        "type": "text",
                        "data": emails,
                        "date_created": new Date().toISOString(),
                        link,
                        status: "success"
                    }
                })
                setTimeout(() => {
                    setSuccess(false);
                }, 10000);

            }).catch(error => {

                setMessage(error.message)
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 10000);
            }).finally(
                () => {
                    setSiteLoader(false)
                    setEmails(undefined)
                    onClose()
                })
        }
    };
    const handleFileChange = (file: File | null) => {
        // Handle file upload logic here
        setFile(file)
    };

    const validRequest = () => {
        if (selectedOption === UPLOAD_ENUM.UPLOAD_CSV) {
            return file !== undefined
        }
        else if (selectedOption === UPLOAD_ENUM.COPY_PASTE_EMAILS) {
            return emails !== undefined && emails !== ""
        }
        return false
    }

    return (
        <>
            <StyledDialog fullWidth={true} maxWidth="sm" open={isOpen} onClose={onClose}>
                <StyledCloseIcon
                    aria-label="close"
                    onClick={onClose}
                    sx={{ color: '#ff0035' }}
                >
                    <CloseIcon />
                </StyledCloseIcon>
                <StyledDialogTitle>
                    Import Subscribers
                </StyledDialogTitle>
                <DialogContent sx={{ color: "black", padding: 0 }}>
                    <StyledDialogContentText>
                        Seamlessly import existing subscribers to your publication&apos;s list.
                    </StyledDialogContentText>

                    <DialogStack spacing={5}  >
                        <Stack spacing={2}>
                            <Typography>
                                Import method
                            </Typography>
                            <SimpleSelect
                                value={selectedOption}
                                onChange={handleOptionChange}
                                options={selectOptions}
                            />
                        </Stack>

                        {
                            selectedOption === UPLOAD_ENUM.UPLOAD_CSV &&
                            <Stack spacing={2}>
                                <Typography>
                                    Upload Email List CSV
                                </Typography>
                                <FileUploadInput onFileChange={handleFileChange} />
                                <StyledDialogContentText>
                                    <Typography fontSize={12} color={"#808080"}>
                                        The entire CSV will be scanned for emails. Please only include emails that should be subscribed to your newsletter in a column titled <b>Email</b>.
                                    </Typography>

                                </StyledDialogContentText>
                            </Stack>
                        }

                        {
                            selectedOption === UPLOAD_ENUM.COPY_PASTE_EMAILS &&
                            <Stack spacing={2}>
                                <Typography>
                                    Copy and Paste Email List
                                </Typography>
                                <StyledTextField
                                    onChange={(e) => setEmails(e.target.value)}
                                    sx={{ padding: 0 }}
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    placeholder="Enter email addresses, separated by commas"
                                />
                            </Stack>
                        }

                    </DialogStack>
                    <StyledDialogContentText>
                        <StyledTypography>
                            <span color="#FA2256">Important: &nbsp;</span>
                            All subscribers being imported must have knowingly consented and opted into your newsletter, otherwise you are at risk of violating our user policy. You will not be billed if your list exceeds limit of your plan.
                        </StyledTypography>
                    </StyledDialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={onClose} variant="outlined" color="primary" sx={{ textTransform: "capitalize" }}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} variant="contained" color="primary" disabled={!validRequest()} sx={{ textTransform: "capitalize" }}>
                            Import Subscribers
                        </Button>
                    </Stack>

                </DialogActions>
            </StyledDialog >
            {
                (success || error) &&
                <StyledAlert severity={success ? `success` : `error`}>
                    {message}
                </StyledAlert>
            }
        </>
    );
};

