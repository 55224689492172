import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

interface TableHeaderProps {
    isMobile: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({ isMobile }) => {
    return (
        <TableHead
            sx={{
                borderRadius: '20px',
                // marginBottom: '12px',
                zIndex: 20,
            }}
        >
            <TableRow
                sx={{
                    borderRadius: '20px',

                    '& th': {
                        textTransform: 'uppercase',
                        fontFamily: 'neue-montreal-regular',
                        fontSize: isMobile ? '10px' : '14px',
                        border: 0,
                        padding: '14px',
                        minWidth: '120px',
                        top: "-25px",


                    },
                }}
            >
                <TableCell align="left">Email</TableCell>
                <TableCell align="center">Subscribed On</TableCell>
                <TableCell align="center">Acquisition Source</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Country</TableCell>
                <TableCell
                    align="center"
                    sx={{
                        borderStyle: 'solid',
                        borderTopRightRadius: '20px',
                        borderBottomRightRadius: '20px',
                    }}
                ></TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
