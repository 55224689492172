import {
    Button,
    styled,
    Checkbox,
    Stack,
    Tooltip
} from '@mui/material';

export const StyledImportButton = styled(Button)(({ theme }) => ({
    // Default styles
    // backgroundColor: '#D5D7FC', 
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#D5D7FC',
        color: "#2C36F0"
    },
    '& svg': {
        color: '#000000', // Black icon color
        height: theme.spacing(3), // Adjust icon size as needed
        width: theme.spacing(3), // Adjust icon size as needed
    },
    '& .MuiButton-startIcon': {
        marginRight: theme.spacing(1), // Adjust icon spacing as needed
    },
    // Outlined variant styles
    '&.MuiButton-outlined': {
        borderWidth: '2px', // Increase border width
    },
    // Responsive styles
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        textWrap: "nowrap",
        '& svg': {
            height: theme.spacing(2),
            width: theme.spacing(2),
        },
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(0.5), // Adjust icon spacing for small screens
        },
    },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  width: 32, // Adjust width as needed
  height: 32, // Adjust height as needed
  '& .MuiSvgIcon-root': {
    color: '#E5E4E5',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      color: '#E5E4E5',
    },
  },
  '& .MuiIconButton-root': {
    border: `1px solid ${theme.palette.grey[300]}`, // Border color
    '&:hover': {
      backgroundColor: 'transparent', // Ensure the background remains transparent on hover
    },
  },
}));

export const ActionBar = styled(Stack)(({ theme }) => ({
    background: '#fff',
    borderRadius: '20px',
    padding: "10px",
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      
    }
}))

export const StyledTooltip = styled(Tooltip)(()=> ({
  backgroundColor: "red",
  color: "#000",
  boxShadow:" 0px 4px 24px 0px #00000040"
}))
