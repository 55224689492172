import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Link } from 'react-router-dom';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        background: 'white',
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            width: '402px',
            margin: '24px 0 0',
            height: 'calc(100vh - 24px)',
            maxHeight: 'unset'
        },
    },
    '& .MuiDialogContent-root': {
        width: '464px',
        padding: '40px 28px 32px 36px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export const StyledShareContainer = styled(Box)(() => ({
    height: '64px',
    width: '64px',
    background: 'rgba(34, 34, 34, 0.06)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const StyledTitle = styled(Typography)(() => ({
    fontFamily: 'Termina-medium,sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#222222',
}));

export const StyledSubTitle = styled(Typography)(() => ({
    fontFamily: 'Termina-regular,sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
    color: '#888888',
}));

export const StyledUrlContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '48px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    paddingLeft: '16px',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const StyledTitleContainer = styled(Box)(() => ({
    padding: '32px 32px 0px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}));

export const StyledLink = styled(Link)(() => ({
    textDecoration: 'inherit',
    color: 'inherit',
    height: '64px',
    width: '64px',
    background: 'rgba(34, 34, 34, 0.06)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const StyledShareLink = styled(Typography)(() => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '10px',
    fontFamily: 'Termina-regular,sans-serif',
    fontStyle: 'normal',
    color: '#8D9CA5',
    fontWeight: 400,
}));

export const StyledCloseIcon = styled(IconButton)(() => ({
    position: 'absolute',
    top: '17px',
    right: '21px',
    width: '15px',
    height: '15px',
    color: 'black',
    cursor: 'pointer',
}));

interface ContainerProps {
    imgUrl: string;
}

export const StyledShareImage = styled(Box)<ContainerProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '200px',
    height: '345px',
    margin: '0 auto',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        flex: 'auto',
    },
    'img': {
        maxHeight: '100%',
        objectFit: 'cover',
        display: 'block',
    }
    // backgroundImage: `url(${imgUrl})`,
    // backgroundPosition: '50% 45%',
}));

export const StyledShareBox = styled(IconButton)(() => ({
    display: 'flex',
    flexDirection: 'column',
    '.share-icon': {
        height: '50px',
        width: '50px',
        background: 'rgba(34, 34, 34, 0.06)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    'p': {
        color: 'black',
        fontSize: '12px',
        margin: '5px 0 0',
    }
}));