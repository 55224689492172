import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
const EventIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
 
            sx={{ height: '24px', width: '24px' }}
            {...props}
            fill="none"
        >
{/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> */}
  <path d="M3 7.5C3 5.29086 4.79086 3.5 7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V7.5Z" stroke="black" strokeWidth="1.2" fill='none'/>
  <path d="M3 9H21" stroke="black" strokeWidth="1.2" strokeLinecap="round"/>
  <path d="M8 2L8 5" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M16 2V5" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  <circle cx="12" cy="15" r="1" fill="black"/>
  <circle cx="16" cy="15" r="1" fill="black"/>
  <circle cx="8" cy="15" r="1" fill="black"/>
{/* </svg> */}
        </SvgIcon>
    );
};

export default EventIcon;