
type iProps = {
    color?: string;
}


export const PlusIcon = ({ color = "#2C36F0" }: iProps) => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0001 4.16699V18.8337M18.3334 11.5003L3.66675 11.5003" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        </svg>

    )
}