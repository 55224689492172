import { identifyAndTrackLogout } from '../components/SegmentAnalytics';
import { AMK_LS_KEYS} from '../constants/app';
import { STORAGE_KEYS } from '../constants/storage';

// Function to read data from localStorage
export const getAllAMKValueFromLocalStore = () => {
    const item: any = localStorage.getItem(AMK_LS_KEYS.MAIN_APP);
    if (!item) {
        return null;
    }
    const jsonItem = JSON.parse(item);
    return jsonItem
};
export const setAllAMKValueFromLocalStore = (data:string) => {
    localStorage.setItem(AMK_LS_KEYS.MAIN_APP, data);
};
// Function to read data from localStorage
export const getValueFromLocalStore = (key: string) => {
    // const item: any = localStorage.getItem(AMK_LS_KEYS.MAIN_APP);
    // if (!item) {
    //     return null;
    // }
    // const jsonItem = JSON.parse(item);
    const jsonItem = getAllAMKValueFromLocalStore();
    return jsonItem ? jsonItem[key] : null;
};

// Function to write data to localStorage
export const setValueInLocalStore = (key: string, value: any) => {
    const item: any = localStorage.getItem(AMK_LS_KEYS.MAIN_APP);
    if (!item) localStorage.setItem(AMK_LS_KEYS.MAIN_APP, JSON.stringify({}));

    const jsonItem = { ...JSON.parse(item) };
    jsonItem[key] = value;
    localStorage.setItem(AMK_LS_KEYS.MAIN_APP, JSON.stringify(jsonItem));
};

// Function to delete data from localStorage
export const deleteValueInLocalStore = (key: string) => {
    const item: any = localStorage.getItem(AMK_LS_KEYS.MAIN_APP);
    if (!item) return;

    const jsonItem = { ...JSON.parse(item) };
    delete jsonItem[key];
    localStorage.setItem(AMK_LS_KEYS.MAIN_APP, JSON.stringify(jsonItem));
};

export const clearLocalStore = () => {
    const user = JSON.parse(
        JSON.stringify(localStorage.getItem(AMK_LS_KEYS.MAIN_APP)),
    );

    identifyAndTrackLogout({ user });
    localStorage.removeItem(AMK_LS_KEYS.MAIN_APP);

    if (user) {
     const   referralCode = JSON.parse(user)?.referralCode;
        console.log({ referralCode }, ' resaved');
        if(referralCode){
          setValueInLocalStore(STORAGE_KEYS.REFERRAL_CODE, referralCode);  
        }

        
    }
};
