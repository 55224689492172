/* eslint-disable no-unused-vars */
import { FC, useEffect, useState } from 'react';
import {
    ManageContentWrapper
} from '../../styles';
import useAuth from '../../../../../hooks/useAuth';
import { useLoading } from '../../../../../context/LoadingContext';
import { ManageContentHeader } from './ManageContentHeader';
import { ContentDisplay } from './ContentDisplay';
import { fetchContentWithPagination } from '../../../../../apis/Article';
import { ContentResponse } from '../interfaces';


// Interface for props
interface ManageContentProps {
    isMobile: boolean;
    importContentButton: () => void;
    sortBy: string;
    importSource: string;
    filter: string;
    handleFilterChange: (event: any) => void;
    filterOptions: { name: string, value: string }[];
}


// ManageContent component
export const ManageContent: FC<ManageContentProps> = ({
    isMobile,
    importContentButton,
    sortBy,
    importSource,
    filter,
    handleFilterChange,
    filterOptions,
}) => {
    // State variables and functions
    const [records, setRecords] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const rowsPerPage = 10;

    const { setSiteLoader } = useLoading();

    const { userInfo } = useAuth();
    const contentType = "all"

    const getContentData = async () => {
        setSiteLoader(true);
        let orderBy = '';
        let order = '';
        switch (sortBy) {
            case 'az':
                orderBy = 'title';
                order = 'asc';
                break;

            case 'za':
                orderBy = 'title';
                order = 'desc';
                break;

            case 'latest':
                orderBy = 'publishedAt';
                order = 'desc';
                break;

            case 'oldest':
                orderBy = 'publishedAt';
                order = 'asc';
                break;

            default:
                break;
        }
        fetchContentWithPagination(
            contentType,
            +userInfo?.id,
            page,
            rowsPerPage,
            orderBy,
            order,
            filter,
            undefined,
            undefined,
            importSource,
            true
        ).then((data: ContentResponse) => {

            setRecords(data.data)
            setTotal(data.total)
        }).catch(error => console.log(error)
        ).finally(() => setSiteLoader(false))
    }

    useEffect(() => {
        getContentData()
    }, [userInfo, importSource, page, sortBy, filter])

    return (
        <ManageContentWrapper>
            {/* Header */}
            <ManageContentHeader
                page={page}
                records={records}
                total={total}
                isMobile={isMobile}
                rowsPerPage={rowsPerPage}
                importContentButton={importContentButton}
            />
            {/* Content display or message */}
            <ContentDisplay
                records={records}
                isMobile={isMobile}
                page={page}
                setPage={setPage}
                total={total}
                rowsPerPage={rowsPerPage}
                reloadData={getContentData}
                handleFilterChange={handleFilterChange}
                filterOptions={filterOptions}
                filter={filter}
                source={importSource}
            />

        </ManageContentWrapper>
    );
};
