/* eslint-disable no-unused-vars */
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Wrapper } from '../styles';
import { getSubscribersWithPagination } from '../../../../apis/Author';
import { useLoading } from '../../../../context/LoadingContext';
import useAuth from '../../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import CustomTable from './CustomTable';
import { StyledTypography } from './styles';

const DashboardAudienceSubscriptions = () => {
    const { setSiteLoader } = useLoading();
    const { userInfo } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string>('subscription_date');
    const [order, setOrder] = useState<string>('desc');
    const [subscribers, setSubscribers] = useState<any[]>([]);
    const fetchSubscribers = async () => {
        setSiteLoader(true);
        try {
            const response = await getSubscribersWithPagination(
                Number(userInfo.id),
                page,
                limit,
                sortBy,
                order,
            );
            setSubscribers(response.data);
            setTotal(response.total);
        } catch (error) {
            console.error(error);
        } finally {
            setSiteLoader(false);
        }
    };

    useEffect(() => {
        if (userInfo.id) {
            fetchSubscribers();
        }
    }, [userInfo.id, page, sortBy, limit]);

    return (
        <Wrapper sx={{ height: '85vh', overflow: 'hidden' }}>
            <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Stack>
                    <StyledTypography
                        sx={{
                            fontSize: isMobile ? 14 : 16,
                            fontWeight: 500,
                            fontFamily: 'Neue Montreal',
                        }}
                    >
                        Your Subscriptions
                    </StyledTypography>
                </Stack>
            </Stack>
            <Stack
                height={'100%'}
                alignItems={'center'}
                justifyItems={'center'}
                sx={{ padding: '20px 0' }}
            >
                {subscribers?.length ? (
                    <CustomTable
                        records={subscribers}
                        isMobile={isMobile}
                        total={total}
                        page={page}
                        setPage={setPage}
                        limit={limit}
                        getContent={fetchSubscribers}
                    />
                ) : (
                    <>
                        <StyledTypography
                            sx={{
                                fontSize: isMobile ? '20px' : '24px',
                                textAlign: 'center',
                                lineHeight: isMobile ? '28px' : '32px',
                                color: '#000000B3',
                            }}
                        >
                            No subscription records found
                        </StyledTypography>
                    </>
                )}
            </Stack>
        </Wrapper>
    );
};

export default DashboardAudienceSubscriptions;
