import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import { CustomThemeProvider } from './context/ThemeContext';
// import { CssBaseline } from '@mui/material';

// import { LoadingProvider } from './context/LoadingContext';
// import { NotificationProvider } from './context/NotificationContext';
// import algoliasearch from 'algoliasearch/lite';
// import { ALGOLIA, ALGOLIA_INDEX } from './constants';

// export const searchClient = algoliasearch(
//     ALGOLIA.APPLICATION_ID,
//     ALGOLIA.PUBLIC_KEY,
// );

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    // <CustomThemeProvider>
    //     <CssBaseline />
    //     <LocalizationProvider dateAdapter={AdapterMoment}>
    //     <UserContextProvider index={index} searchClient={searchClient}>
    //         <LoadingProvider>
    //             <NotificationProvider>
                    <App />
    //             </NotificationProvider>
    //         </LoadingProvider>
    //     </UserContextProvider>
    //     </LocalizationProvider>
    // </CustomThemeProvider> 
    );
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
