import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { getDomain } from 'utils';
import useAuth from '../hooks/useAuth';
import { updateLastSeen } from 'apis/Messages';

const USER_CHAT_STATE = {
  ACTIVE: 'ACTIVE',
  NON_ACTIVE: 'NON-ACTIVE',
  IDLE: 'IDLE',
};

type ISocketContext = {
  socket: Socket | null;
};

const SocketContext = createContext<ISocketContext | undefined>(undefined);

type SocketProviderProps = {
  children: ReactNode;
};

export const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { userInfo } = useAuth();

  useEffect(() => {
    if (userInfo.isUserLoggedIn) {
      const socketInstance = io(getDomain());
      setSocket(socketInstance);
      socketInstance.emit(
        'join',
        {
          data: {
            userId: userInfo.id,
            userName: userInfo.email,
            token: userInfo['jwt-token'],
            state: 'ACTIVE',
          },
        },
        (error: any) => {
          if (error) return alert(error);
        },
      );

      updateLastSeen(USER_CHAT_STATE.ACTIVE).then(() => {});
    }

    return () => {
      socket?.disconnect();
    };
  }, [userInfo]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): ISocketContext => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
