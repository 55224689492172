import http from '../../config/http.config';
import { getValueFromLocalStore } from '../../utils/localStorage';
import { STORAGE_KEYS } from '../../constants/storage';

export const getChatRooms = async () => {
  try {
    const response = await http.get('api/get-user-chatrooms', {
      headers: {
        Authorization: `Bearer ${getValueFromLocalStore(
          STORAGE_KEYS.JWT_TOKEN,
        )}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch chatrooms',
      status: error.response?.status || 500,
    };
  }
};

export const getMessageTemplates = async () => {
  try {
    const response = await http.get('api/contract-actions-templates', {
      headers: {
        Authorization: `Bearer ${getValueFromLocalStore(
          STORAGE_KEYS.JWT_TOKEN,
        )}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch chatrooms',
      status: error.response?.status || 500,
    };
  }
};

export const getMessages = async (chatRoomId: number) => {
  try {
    const response = await http.get(`api/chat-rooms/${chatRoomId}`, {
      headers: {
        Authorization: `Bearer ${getValueFromLocalStore(
          STORAGE_KEYS.JWT_TOKEN,
        )}`,
      },
    });
    return response.data.results;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch messages',
      status: error.response?.status || 500,
    };
  }
};

export const createChatRoom = async ({
  partnerId,
  currentUserId,
  gigId,
}: {
  partnerId: number;
  currentUserId: number;
  gigId?: number;
}) => {
  try {
    const response = await http.post(
      `api/chat-rooms`,
      {
        user1: partnerId,
        user2: currentUserId,
        gigId,
      },
      {
        headers: {
          Authorization: `Bearer ${getValueFromLocalStore(
            STORAGE_KEYS.JWT_TOKEN,
          )}`,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch messages',
      status: error.response?.status || 500,
    };
  }
};

export const updateLastSeen = async (state: any) => {
  try {
    const response = await http.put(
      `/api/users/update-user-chat-state`,
      {
        state,
      },
      {
        headers: {
          Authorization: `Bearer ${getValueFromLocalStore(
            STORAGE_KEYS.JWT_TOKEN,
          )}`,
        },
      },
    );
    return response;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch messages',
      status: error.response?.status || 500,
    };
  }
};
