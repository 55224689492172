import { styled } from '@mui/system';
import Select from '@mui/material/Select';

export const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: 'white', // Always white background
    '& .MuiSelect-icon': {
        marginRight: theme.spacing(1), // Adjust icon spacing as needed
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px', 
    },
    '& .MuiSelect-iconOutlined': {
        paddingRight: theme.spacing(1), // Add padding to the icon
    },
    '& .MuiOutlinedInput-root': {
        '&:hover, &:focus': {
            borderColor: theme.palette.grey[400], // Light gray border on hover or focus
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:focus': {
        borderRadius: "20px",
    },
}));