type iProps = {
    color?: string;
};

export const SocialPostIcon = ({ color = '#808080' }: iProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 6C3 3.79086 4.79086 2 7 2H17C19.2091 2 21 3.79086 21 6V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V6Z"
                stroke={color}
                strokeWidth="1.3"
                strokeLinejoin="round"
            />
            <path
                d="M8 17H12"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path
                d="M8 12H16"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path
                d="M8 7H16"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
            />
        </svg>
    );
};
