import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { StyledSelect } from './styles';
import { SelectChangeEvent } from '@mui/material';
import { SelectDropdownIcon } from '../../../assets/icons/SelectDropdownIcon';

interface Option {
    value: any;
    label: string;
}

interface SimpleSelectProps {
    value: any;
    // eslint-disable-next-line no-unused-vars
    onChange: (event: SelectChangeEvent<any>) => void;
    options: Option[];
}

export const SimpleSelect: React.FC<SimpleSelectProps> = ({ value, onChange, options }) => {
    return (
        <StyledSelect
            value={value}
            onChange={onChange}
            variant="outlined"
            disableUnderline
            IconComponent={SelectDropdownIcon}
            fullWidth
        >
            {options.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#D5D7FC',
                            '& .MuiListItemIcon-root': {
                                color: '#000000'
                            }
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#D5D7FC',
                            '& .MuiListItemIcon-root': {
                                color: '#000000'
                            }
                        }
                    }}
                >
                    {option.label}
                </MenuItem>
            ))}
        </StyledSelect>
    );
};
