import { styled, Button, IconButton, MenuList } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
    // Default styles
    textTransform: "capitalize",
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#D5D7FC',
        color: "#2C36F0"
    },
    '& svg': {
        color: '#000000', // Black icon color
        height: theme.spacing(3), // Adjust icon size as needed
        width: theme.spacing(3), // Adjust icon size as needed
    },
    '& .MuiButton-startIcon': {
        marginRight: theme.spacing(1), // Adjust icon spacing as needed
    },
    // Outlined variant styles
    '&.MuiButton-outlined': {
        borderWidth: '2px', // Increase border width
    },
    // Responsive styles
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        textWrap: "nowrap",
        '& svg': {
            height: theme.spacing(2),
            width: theme.spacing(2),
        },
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(0.5), // Adjust icon spacing for small screens
        },
    },
}));

export const StyledMenuItem = styled(MenuList)(({theme}) => ({
    '& .MuiTypography-root': {
        fontSize: "14px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px",
        }
    },
    color: "#808080",
    '&:hover': {
        backgroundColor: '#D5D7FC',
        '& .MuiListItemIcon-root': {
            color: '#000000'
        },
        '& .MuiListItemText-root': {
            color: '#000000'
        }
    }
}))

export const StyledIconButton  = styled(IconButton)(() => ({
    borderRadius: "10px",
    backgroundColor: '#2C36F0',
    padding: "5px",
    '&:hover': {
        backgroundColor: '#242FB4',
    }
}))
