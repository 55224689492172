import { ExpandMore } from '@mui/icons-material';
import {
    FormControl,
    MenuItem,
    Select,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FC } from 'react';

interface Props {
    value: string;
    handleChange: any;
    options: { name: string; value: string }[];
    placeholder?: string;
}

const CustomDropdown: FC<Props> = ({
    value,
    handleChange,
    options,
    placeholder,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <FormControl sx={{ m: 1, width: 160 }} size="small">
                <Select
                    IconComponent={ExpandMore}
                    sx={{
                        height: '32px',
                        outline: 'unset',
                        fontSize: isMobile ? '12px' : '14px',
                        fontFamily: 'neue-montreal-regular',
                    }}
                    inputProps={{
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    border: 'none',
                                    '& .MuiMenuItem-root': {
                                    },
                                    
                                },
                            },

                        },
                    }}
                    labelId="dropdown-select"
                    id="demo-select-small"
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected) {
                            return placeholder;
                        }
                        const val = options.find((v) => v.value === selected);
                        return val?.name;
                    }}
                    value={value}
                    onChange={handleChange}
                >
                    {placeholder && (
                        <MenuItem disabled key={placeholder}>
                            {placeholder}
                        </MenuItem>
                    )}
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default CustomDropdown;
