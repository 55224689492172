
import {
    styled,
    Box,
    Alert,
} from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#F9F9F9',
    borderRadius: '20px',
    padding: '24px 20px',
    minHeight: "600px",
    height: "80vh",
    marginTop: "20px",
    marginBottom: "50px",
    paddingBottom: "400x",
    overflowY: "scroll",

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '16px 12px',
    },
}));

export const StyledAlert = styled(Alert)<{isdesktop?:number}>(({isdesktop}) => ({
    fontFamily: 'Neue Montreal',
    fontSize: '14px',
    position: 'absolute',
    top: 1,
    right:  isdesktop === 1 ? '30%':0,
    transform:  isdesktop === 1 ? 'translateX(-30%)': '',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2500
}));


