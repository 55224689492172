/* eslint-disable no-undef */
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledDialog, StyledButton } from './styles';

interface AlertDialogProps {
    dialog: {
        open: boolean;
        title: string;
        description: string;
        response: boolean;
    };
    setDialog: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            title: string;
            description: string;
            response: boolean;
        }>
    >;
}
export default function AlertDialog(props: AlertDialogProps) {
    const { dialog, setDialog } = props;

    const handleClose = (response: boolean) => {
        setDialog({ ...dialog, response, open: false });
    };

    return (
        <StyledDialog open={dialog.open} onClose={() => handleClose(false)}>
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialog.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={() => handleClose(false)}>
                    No
                </StyledButton>
                <StyledButton onClick={() => handleClose(true)} autoFocus>
                    Yes
                </StyledButton>
            </DialogActions>
        </StyledDialog>
    );
}
