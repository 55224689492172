interface Data {
    title: string;
    article_status: string;
    published_on: string;
    thumbnail: any;
    edit: any;
    views?: number;
    likes?: number;
    comments?: number;
    star?: any;
    menu?: string;
}

interface HeadCell {
    id: keyof Data;
    label: string;
    sort?: Boolean;
}

export const headCells: readonly HeadCell[] = [
    {
        id: 'star',
        label: '',
    },
    {
        id: 'edit',
        label: '',
    },
    {
        id: 'thumbnail',
        label: '',
    },
    {
        id: 'title',
        label: 'TITLE',
        sort: true,
    },
    {
        id: 'article_status',
        label: 'VISIBILITY',
        sort: true,
    },
    {
        id: 'published_on',
        label: 'PUBLISHED DATE',
        sort: true,
    },
    {
        id: 'likes',
        label: 'LIKES',
        sort: true,
    },
    {
        id: 'comments',
        label: 'Thoughts',
        sort: true,
    },
    {
        id: 'menu',
        label: '',
    },
];

export const DIALOG = {
    TITLE: {
        ARCHIVE: 'Archive Content',
        DELETE: 'Delete Content',
        UNSUBSCRIBE: 'Unsubscribe',
        DELETE_COMMENT: 'Delete Comment',
        ACCOUNT: 'Account'
    },
    DESCRIPTION: {
        ARCHIVE: 'This content will be archived. Do you want to proceed?',
        DELETE: 'This content will be deleted. Do you want to proceed?',
        UNSUBSCRIBE: 'Unsubscribe from this channel',
        DELETE_COMMENT: 'Do you want to delete this comment?',
        ACCOUNT: 'Your account will be permanently deleted. All contents related to this account will be lost'
    },
};
