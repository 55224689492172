import qs from 'qs';
// http://localhost:1337/api/infeed-campaigns?populate[infeed_ad][populate][banner]=url&populate[infeed_ad][populate][user][populate][user_profile][populate]=profile_image
const getInfeedAdQuery = () =>
    qs.stringify(
        {
            populate: {
                infeed_ad: {
                    populate: {
                        content_link:{
                            fields:['id', 'article_id'],
                            // populate:{
                            //     // likes:{count:true},
                            //     // bookmarks:{count:true}
                            //     likes:{
                            //         populate:{
                            //             user:{
                            //                 fields:['id']
                            //             }
                            //         }
                            //     },
                            //     bookmarks:{
                            //         populate:{
                            //             user:{
                            //                 fields:['id']
                            //             }
                            //         }
                            //     }
                            // }
                        },
                        banner: {
                            fields: ['url']
                        },
                        user: {
                            fields: ['id', 'first_name', 'last_name', 'uid', 'username'],
                            populate: {
                                user_profile: {
                                    fields: ['id'],
                                    populate: {
                                        profile_image: {
                                            fields: ['url']
                                        }
                                    }
                                }
                            }
                        }
                    },
                },
            },
            filters: {
                $and: [
                    {
                        start_date: {
                            $lt: new Date().toISOString()
                        }
                    }
                ]
            }
        },
        {
            encodeValuesOnly: true,
        },
    );
export default getInfeedAdQuery;

// qs.stringify(
//     {
//         populate: {
//             infeed_ad: {
//                 populate: [
//                     'banner',
//                     'user'
//                 ],
//             },
//         },
//     },
//     {
//         encodeValuesOnly: true,
//     },
// );