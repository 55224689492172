import moment from 'moment';
import { TicketSummaryType } from '../components/UserChannel/types';
import { ROUTES } from '../constants/routeDefinition';
import { clearLocalStore } from './localStorage';

export const getSocialAuthApi = (provider: string) => {
  return `/api/connect/${provider}`;
};

export const getSocialAuthCallbackUrl = (provider: string) => {
  return `/api/auth/${provider}/callback`;
};

export const loadEpomSnippetScript = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn77.aj2566.bid/c0e30733.js';
  script.async = true;
  script.dataset.dynamic = 'true';
  if (document.querySelector('ins')) {
    document.querySelector('ins')?.parentNode?.append(script);
  }
};
export const loadViralLoopSnippet = () => {
  const SCRIPT_SRC = 'https://app.viral-loops.com/widgetsV2/core/loader.js';
  const scriptExists = document.head.querySelector(
    `script[src="${SCRIPT_SRC}"]`,
  );
  if (scriptExists) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = SCRIPT_SRC;
    script.id = 'viral-loops-loader';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};
export const loadViralLoopScript = (campaignID: string) => {
  const SCRIPT_SRC = 'https://app.viral-loops.com/widgetsV2/core/loader.js';
  const scriptExists = document.head.querySelector(
    `script[src="${SCRIPT_SRC}"]`,
  );
  if (scriptExists) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = SCRIPT_SRC;
    script.id = 'viral-loops-loader';
    script.dataset.campaignId = campaignID;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const setMetaTag = (property: string, value: any) => {
  // Get an element if it exists already
  let element: any = document.querySelector(`meta[${property}]`);

  // Check if the element exists
  if (element) {
    // If it does just change the content of the element
    element.setAttribute('content', value);
  } else {
    // It doesn't exist so lets make a HTML element string with the info we want
    element = `<meta ${property} content="${value}" />`;

    // And insert it into the head
    document.head.insertAdjacentHTML('beforeend', element);
  }
};

export const setMetaTagsForSite = (
  title_ = '',
  description_ = '',
  image = '',
) => {
  const title =
    title_ || 'A social publishing platform for Pan-African creators';
  const description =
    description_ ||
    'A social publishing platform empowering Pan-African creators to reach a global audience and earn through subscriptions, events and more';
  const imageUrl =
    image ||
    'https://amaka-s3-bucket-prod.s3.amazonaws.com/icon_512x512_fa76b09539.png?updated_at=2023-05-01T02:58:27.825Z';

  document.title = title;
  setMetaTag('name="description"', description);

  // Google / Search Engine Tags
  setMetaTag('itemprop="name"', title);
  setMetaTag('itemprop="description"', description);
  setMetaTag('itemprop="image"', imageUrl);

  // Facebook
  setMetaTag('property="og:url"', window.location.href);
  setMetaTag('property="og:title"', title);
  setMetaTag('property="og:description"', description);
  setMetaTag('property="og:image"', imageUrl);
  setMetaTag('property="og:image:alt"', description);

  // Twitter
  setMetaTag('name="twitter:title"', title);
  setMetaTag('name="twitter:description"', description);
  setMetaTag('name="twitter:card"', description);
  setMetaTag('name="twitter:image"', imageUrl);
  setMetaTag('name="twitter:image:src"', imageUrl);
  setMetaTag('name="twitter:image:alt"', title);
};
export const parseSnippetTemplate = (
  snippet: string,
  { params, searchParams }: { params: any; searchParams: any },
) => {
  if (!snippet) {
    return '';
  }
  if (
    params &&
    typeof params === 'object' &&
    Object.keys(params).length !== 0
  ) {
    if (snippet.includes('{{FILTER}}')) {
      return snippet.replace('{{FILTER}}', params.id);
    }
  } else if (searchParams && searchParams.get) {
    if (snippet.includes('{{FILTER}}')) {
      const filter_query = searchParams.get('filter_query');

      return snippet.replace('{{FILTER}}', filter_query);
    }
  }

  return snippet;
};
export const validateTicketSummary = (summary: TicketSummaryType) => {
  if (!summary) {
    return { error: true, message: 'No summary object', field: 'all' };
  }
  if (!summary.contact?.firstName) {
    return {
      error: true,
      message: 'First Name is required',
      field: 'first_name',
    };
  }
  if (summary.contact?.firstName.length < 3) {
    return {
      error: true,
      message: 'First Name is too short',
      field: 'first_name',
    };
  }
  if (summary.contact?.firstName.length > 50) {
    return {
      error: true,
      message: 'First Name is too long',
      field: 'first_name',
    };
  }

  if (!summary.contact?.lastName) {
    return {
      error: true,
      message: 'Last Name is required',
      field: 'last_name',
    };
  }
  if (summary.contact?.lastName.length < 3) {
    return {
      error: true,
      message: 'Last Name is too short',
      field: 'last_name',
    };
  }
  if (summary.contact?.lastName.length > 50) {
    return {
      error: true,
      message: 'Last Name is too long',
      field: 'last_name',
    };
  }
  if (!summary.contact?.email) {
    return {
      error: true,
      message: 'Email is required',
      field: 'email',
    };
  }
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(summary.contact?.email)) {
    return {
      error: true,
      message: 'Invalid Email Address',
      field: 'email',
    };
  }
  if (summary.contact?.email !== summary.contact.confirmEmail) {
    return {
      error: true,
      message: 'Email does not match',
      field: 'confirm_email',
    };
  }
  if (!summary.contact?.countryCode) {
    return {
      error: true,
      message: 'Country Code is required',
      field: 'phone',
    };
  }
  if (
    !summary.contact?.phone ||
    summary.contact.phone.length < 6 ||
    summary.contact.phone.length > 15 ||
    !Number(summary.contact.phone)
  ) {
    return {
      error: true,
      message: 'Invalid Phone Number',
      field: 'phone',
    };
  }
  if (summary.contact.company.length > 200) {
    return {
      error: true,
      message: 'Company Name too long',
      field: 'company',
    };
  }

  if (!summary.currency) {
    return { error: true, message: 'Currency is required', field: 'all' };
  }
  // if (!summary.total || parseFloat((summary.total?.toString())) <= 0) {
  //     return { error: true, message: 'Invalid total' };
  // }
  if (parseFloat(summary.total?.toString()) > 1000) {
    return { error: true, message: 'maximum exceeded' };
  }
  if (!summary.tickets) {
    return { error: true, message: 'Ticket is required', field: 'all' };
  }
  // if (!summary.title || summary.title.length > 50) {
  //     return { error: true, message: 'Title is required', field: 'all' };
  // }

  return { success: true };
};
export const parseTicketUrl = (id: string) => {
  return `${ROUTES.EVENTS.PATH}/${id}/buy-a-ticket`;
};

export const logout = () => {
  clearLocalStore();
  window.location.href = '/';
};
export const checkResetPasswordCodeExpiryTime = (time: string) => {
  try {
    const plainTime = parseInt(atob(time));
    return new Date().getTime() < plainTime;
  } catch (error) {
    return false;
  }
};
export const getCategories = async () => {
  const { listCategories } = await import('../apis/Content');

  const response = await listCategories();

  return response.data;
};

export const checkIsBooked = async (event: any, uid: string) => {
  if (!event) {
    return false;
  }
  try {
    if (
      event.attributes?.content_link?.data?.attributes?.user?.data?.attributes
        ?.uid === uid
    ) {
      return true;
    }
    const id = event.id;

    const { getEventParticipants } = await import('../apis/Event');

    // import { getEventParticipants } from "../apis/Event";
    const result = await getEventParticipants({
      participant: { user_id: uid },
      event: id,
    });
    const _isBooked = result.data?.length > 0;
    return _isBooked;
  } catch (error) {
    return false;
  }
};

export const getDomain = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'http://localhost:1337';
    // return 'https://dev-be.amaka.studio';
    case 'qa':
      // return 'http://localhost:1337'
      return 'https://qa-be.amaka.studio';
    case 'production':
      return 'https://be.amaka.studio';
    case 'preprod':
      return 'https://beta-be.amaka.studio';
    default:
      return process.env.REACT_APP_DEFAULT_BE_URL || 'http://localhost:1337';
  }
};

export const cdnlink = (link: string, type = '', cdn = '') => {
  let _cdn = cdn;
  if (!cdn) {
    _cdn =
      process.env.REACT_APP_ENV !== 'production'
        ? 'https://de2o16hkessb0.cloudfront.net'
        : 'https://d1vo0u8hn31k9w.cloudfront.net';
  }
  if (!link) return link;

  const linkArr = link.split('amazonaws.com/');
  if (Array.isArray(linkArr) && linkArr[1]) {
    let key = linkArr[1];
    if (type !== '') {
      key = `${type}_${key}`;
    }
    return `${_cdn}/${key}`;
  }

  return link;
  // https://de2o16hkessb0.cloudfront.net/{type_}woman_stretching_near_a_cliff_720p_c597a3695e.mp4
};
export function getBaseUrl(): string {
  const environment = process.env.REACT_APP_ENV;

  switch (environment) {
    case 'dev':
      return 'https://dev-fe.amaka.studio';
    case 'qa':
      return 'https://qa-fe.amaka.studio';
    case 'preprod':
      return 'https://beta.amaka.studio';
    case 'production':
      return 'https://amaka.studio';
    default:
      return 'http://localhost:3000';
  }
}
export const formatTime = (time: number) => {
  //formarting duration of video
  if (isNaN(time)) {
    return '00:00';
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, '0')} `;
  } else return `${minutes}:${seconds}`;
};
export const mapTags = (defaultTags: string[]) => {
  if (!defaultTags || !defaultTags.length) return '';
  return defaultTags.map((tag: string) => `#${tag}`).join(' ');
};

export const APPLINKS = {
  APPLE: 'https://apps.apple.com/by/app/amaka-studio/id6450927971',
  GOOGLE:
    'https://play.google.com/store/apps/details?id=studio.amaka.twa&pcampaignid=web_share',
};

export const isNewUser = (createdat: Date) => {
  return moment().diff(moment(createdat), 'minutes') < 3;
};
