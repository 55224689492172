import React, { lazy, Suspense } from 'react';
// import HomeIcon from '../assets/icons/HomeIcon';
const BrandIcon = lazy(() => import('../assets/icons/BrandIcon'));
const ReaderIcon = lazy(() => import('../assets/icons/ReaderIcon'));
const CreatorIcon = lazy(() => import('../assets/icons/CreatorIcon'));

const OrganisationIcon = lazy(() => import('../assets/icons/OrganisationIcon'));

export const CONTENT_TYPE = {
  VIDEO: 'video',
  ARTICLE: 'article',
  INFEED_AD: 'infeed_ad',
};

export const AUTH_FORM = {
  REGISTER: 'Register',
  ONBOARDING: 'Onboarding',
  INIT: 'Initialization',
  LOGIN: 'Login',
  RESET: 'ResetPassword',
  FORGOT: 'ForgotPassword',
};

export const TEXT = {
  SIDE_DRAWER: {
    LOGIN_MESSAGE: 'Login to like, comment and subscribe',
    CONTACT_US: 'Contact us',
    COPYRIGHT_MESSAGE: [
      {
        id: 1,
        title: 'About AMAKA',
        link: 'https://more.amaka.studio',
      },
      {
        id: 2,
        title: 'For Creators',
        link: 'https://more.amaka.studio/creator-solutions',
      },
      {
        id: 3,
        title: 'For Brands',
        link: 'https://more.amaka.studio/our-story-our-creators',
      },

      {
        id: 4,
        title: 'Terms & Conditions',
        link: 'https://more.amaka.studio/terms-and-conditions',
      },
      {
        id: 5,
        title: 'Privacy and Consent',
        link: 'https://more.amaka.studio/privacy-policy',
      },
      {
        id: 6,
        title: 'Copyright',
        link: 'https://more.amaka.studio/terms-and-conditions',
      },

      // {
      //     id: 7,
      //     title: 'Policy & Safety',
      //     link: 'https://more.amaka.studio/cookie-policy',
      // },

      {
        id: 7,
        title: 'Cookie Policy',
        link: 'https://more.amaka.studio/cookie-policy',
      },
      // 'About',
      // 'Copyright',
      // 'Creators',
      // 'Advertise',
      // 'Developers',
      // 'Terms Privacy',
      // 'Policy & Safety',
      // 'How Amaka works',
      // 'Test new features',
    ],
    COPYRIGHT_FOOTER: `© ${new Date().getFullYear()} AMAKA Studio Ltd`,
  },
};

export const TYPE_OF_USERS = [
  {
    title: 'READER',
    icon: (
      <Suspense fallback={<></>}>
        <ReaderIcon />
      </Suspense>
    ),
  },
  {
    title: 'CREATOR',
    icon: (
      <Suspense fallback={<></>}>
        <CreatorIcon />
      </Suspense>
    ),
  },
  {
    title: 'BRAND',
    icon: (
      <Suspense fallback={<></>}>
        <BrandIcon />
      </Suspense>
    ),
  },
  {
    title: 'ORGANISATION',
    icon: (
      <Suspense fallback={<></>}>
        <OrganisationIcon />
      </Suspense>
    ),
  },
];

export const BANNER_LOCATION = {
  HOME: 'Home',
  LOGIN: 'Login',
  REGISTER: 'Register',
  PAYWALL: 'Paywall',
  CATEGORY: 'Category',
  PROFILE: 'Profile',
};

export const ERROR_CODE = {
  VALIDATION: { CODE: 'ValidationError', STATUS: 422 },
  APPLICATION: { CODE: 'ApplicationError', STATUS: 500 },
};

export const colorPickerColors = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#000000',
  '#FFFFFF',
];

export const DESKTOP_HEADER_HEIGHT = '80px';
export const MOBILE_HEADER_HEIGHT = '50px';
export const NEW_DESKTOP_HEADER_HEIGHT = '80px';
export const NEW_MOBILE_HEADER_HEIGHT = '50px';

export const CONTENT_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
};

export const ALGOLIA = {
  APPLICATION_ID: '8DL1Z8TD4J',
  PUBLIC_KEY: '3aa7ac92c4936b045d43d4b861df0cca',
};

export const ALGOLIA_INDEX = {
  // ARTICLE: `qa`,
  ARTICLE: `article(${process.env.REACT_APP_ENV || 'dev'})`,
};

export const PUBLISHED = 'published';
export const ARCHIVED = 'archived';
export const DELETED = 'deleted';
export const DRAFT = 'draft';
export const EDITORIAL = 'editorial';
export const SPONSORED = 'Sponsored';
export const CHALLENGE = 'challenges';
export const TRENDING = 'Trending';
export const FOLLOWING = 'Following';
export const BOOKMARKED = 'bookmarked';

export const SUBSCRIPTION_TYPE = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  FREE: 'free',
};
export const ALGOLIA_ATTRIBUTES = [
  'title',
  'description',
  'content_type',
  'background_color',
  'is_sponsored',
  'is_exclusive',
  'tags',
  'publishedAt',
  'published_on',
  'is_pinned_to_author_profile',
  'article_status',
  'createdAt',
  'video',
  'thumbnail',
  'user',
  'category',
  'article_id',
  'id',
  'thumbnailUrl',
  'bookmarks',
];

export const REDIRECT_FROM = {
  LIKE: 'like',
  FLAG: 'flag',
  COMMENT: 'comment',
  CREATE_CONTENT: 'createContent',
  BOOKMARK: 'bookmark',
  ARTICLE: 'article',
  CHALLENGES: 'challenges',
  CREATE_EVENT: 'createEvent',
  SUBSCRIPTION: 'subscription',
  GIGS: 'gigs',
};
export const BANNERTYPE = {
  SNIPPET: 'SNIPPET',
};

export const NOTIFICATION_TYPE = {
  LIKE: 'LIKE',
  SHARE: 'SHARE',
  SUBSCRIBE: 'SUBSCRIBE',
  COMMENT: 'THOUGHT',
  FLAG: 'FLAG',
  PUBLISH: 'PUBLISH',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  BOOKMARK: 'BOOKMARK',
};
export const BANNERENUM = {
  SNIPPET: 'SNIPPET',
};

export const BANNER_DEFAULT_PROFILE_URL =
  window.location.host == 'amaka.studio'
    ? `https://amaka.studio/images/DefaultProfileImageBlack.svg`
    : 'https://qa-fe.amaka.studio/images/DefaultProfileImageBlack.svg';
export const CAMPAIGN_OBJECTIVES = {
  CONTENT_PAGE_VIEW: 'CONTENT_PAGE_VIEW',
  AUTHOR_SUBSCRIBE_ON_CONTENT_FREE: 'AUTHOR_SUBSCRIBE_ON_CONTENT_FREE',
  AUTHOR_SUBSCRIBE_ON_CONTENT_PAID: 'AUTHOR_SUBSCRIBE_ON_CONTENT_PAID',
  LIKE_ON_CONTENT: 'LIKE_ON_CONTENT',
  COMMENT_ON_CONTENT: 'COMMENT_ON_CONTENT',
  BOOKMARKED_CONTENT: 'BOOKMARKED_CONTENT',
  SUBSCRIBE_ON_AUTHOR_PROFILE_FREE: 'SUBSCRIBE_ON_AUTHOR_PROFILE_FREE',
  SUBSCRIBE_ON_AUTHOR_PROFILE_PAID: 'SUBSCRIBE_ON_AUTHOR_PROFILE_PAID',
  SIGN_UP: 'SIGN_UP',
  ONBOARDING: 'ONBOARDING',
  BRAND_REFERRAL: 'BRAND_REFERRAL',
};

export const LANGUAGE_PROFICIENCY = [
  'Native or Bilingual',
  'Advanced',
  'Fluent',
  'Beginner',
];

export const DEFAULT_GIGS_CURRENCY = 'USD';
