import axios from 'axios';

import { STORAGE_KEYS } from '../../constants/storage';
import { getDomain } from '../../utils';
import {
  getValueFromLocalStore,
  setValueInLocalStore,
} from '../../utils/localStorage';
import http from '../../config/http.config';
import getInfeedAdQuery from './queries';
import { uniqueId } from 'lodash';

const ARTICLES_API = 'api/articles';

const INFEED_CAMPAIGN_API = 'api/infeed-campaigns';
const TRACK_IMPRESSION_API = 'api/articles/impressions';
const TRACK_ENGAGEMENT_API = 'api/articles/engagements/create';

export const fetchContentWithPagination = async (
  article: string,
  userId: number,
  page: number,
  limit: number,
  orderBy?: string,
  order?: string,
  status?: string,
  startDate?: string,
  endDate?: string,
  importSource?: string,
  imported?: boolean,
) => {
  console.log({
    article,
    userId,
    page,
    limit,
    orderBy,
    order,
    status,
    startDate,
    endDate,
    importSource,
    imported,
  });
  try {
    const url = `${getDomain()}/${ARTICLES_API}?contentType=${article}&userId=${userId}&page=${page}&limit=${limit}${
      orderBy
        ? `&orderBy=${orderBy}&order=${order}`
        : '&orderBy=createdAt&order=desc'
    }${status ? `&status=${status}` : ''}${
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
    }${importSource ? `&importSource=${importSource}` : ''}${
      imported ? `&imported=${imported}` : ''
    }`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${getValueFromLocalStore(
          STORAGE_KEYS.JWT_TOKEN,
        )}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to fetch Articles published by User',
      status: error.response.status || 500,
    };
  }
};

export const starArticle = async (articleId: number, starred: Boolean) => {
  try {
    await axios.put(
      getDomain() + `/${ARTICLES_API}/${articleId}?action=starred`,
      {
        data: { is_pinned_to_author_profile: starred },
      },
      {
        headers: {
          Authorization: `Bearer ${getValueFromLocalStore(
            STORAGE_KEYS.JWT_TOKEN,
          )}`,
        },
      },
    );
  } catch (error: any) {
    throw {
      message: error,
      status: error.response.status || 500,
    };
  }
};

export const updateArticle = async (articleId: number, data: {}) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${getValueFromLocalStore(STORAGE_KEYS.JWT_TOKEN)}`,
    },
  };
  try {
    await axios.put(
      getDomain() + `/${ARTICLES_API}/${articleId}`,
      { data },
      headers,
    );
  } catch (error: any) {
    throw { message: error, status: error.response.status || 500 };
  }
};

export const duplicateArticle = async (id: number) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${getValueFromLocalStore(STORAGE_KEYS.JWT_TOKEN)}`,
    },
  };
  try {
    const response = await axios.post(
      getDomain() + `/${ARTICLES_API}/duplicate`,
      { id },
      headers,
    );

    return response;
  } catch (error: any) {
    throw { message: error, status: error.response.status || 500 };
  }
};

export const fetchinfeedContent = async () => {
  try {
    const query = getInfeedAdQuery();
    const url = `${getDomain()}/${INFEED_CAMPAIGN_API}?${query}`;
    const response = await http.get(url);

    return response.data;
  } catch (error: any) {
    console.error({ error }, 'fetchinfeedContent');
  }
};
// export const trackImpression = async (articleId: string) => {
//     try {
//         const data = {

//             articleId,
//         };
//         const url = `${getDomain()}/${TRACK_IMPRESSION_API}`;
//         const response = await axios.post(url, { data });

//         console.log(response);

//         return response.data;
//     } catch (error: any) {
//         console.error({ error }, 'fetchinfeedContent');
//     }
// };

export const trackImpression = async (
  impressionData: any,
  isTrigger = false,
) => {
  const currentTime = new Date().getTime();

  const newFlushTime = currentTime + 60 * 60 * 1000;

  const storageItems = getValueFromLocalStore(STORAGE_KEYS.IMPRESSION);

  if (storageItems) {
    if (
      isTrigger &&
      (currentTime > storageItems.flushTime || storageItems.data.length > 3)
    ) {
      if (storageItems.data.length > 0) {
        // Call Api Endpoint
        await trackBulkImpression(storageItems.data);
        // Flush Items
        storageItems.data = [];
        // Set New Flush Time
        storageItems.flushTime = newFlushTime;
        // setValueInLocalStore(STORAGE_KEYS.IMPRESSION, storageItems);
        // return;
      }
    }
    if (impressionData && impressionData.articleId) {
      storageItems.data.push(impressionData);
    }

    setValueInLocalStore(STORAGE_KEYS.IMPRESSION, storageItems);
  } else {
    if (!impressionData || impressionData.length == 0) {
      return;
    }
    const initialData = {
      data: [impressionData],
      flushTime: newFlushTime,
    };
    setValueInLocalStore(STORAGE_KEYS.IMPRESSION, initialData);
  }
};

export const trackUserInteraction = ({
  article,
  authorId,
  type,
}: {
  article: string;
  authorId: string;
  type?: string;
}) => {
  trackImpression({
    articleId: article,
    authorId: authorId,
    userId: getValueFromLocalStore(STORAGE_KEYS.UID) || uniqueId('guest'),
    type,
  });
};

export const trackBulkImpression = async (articles: any) => {
  try {
    const url = `${getDomain()}/${TRACK_IMPRESSION_API}`;
    const data = {
      userId: getValueFromLocalStore(STORAGE_KEYS.UID) || uniqueId('guest'),
      articles: articles,
    };
    const response = await axios.post(url, { data: data });

    console.log(response.data);

    return response.data;
  } catch (error: any) {
    console.error({ error });
  }
};

export const TrackEngagement = async (data: any) => {
  try {
    const url = `${getDomain()}/${TRACK_ENGAGEMENT_API}`;
    const response = await axios.post(url, { data: data });

    return response.data;
  } catch (error: any) {
    console.error({ error });
  }
};

