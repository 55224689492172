/** Reference: https://javascript.plainenglish.io/using-typescript-in-react-context-3912472b933 */
import React, { createContext, useEffect, useState } from 'react';
import { AMK_LS_KEYS } from '../constants/app';
import useLocalStorage from '../hooks/useLocalStorage';

interface UserInfo {
  isUserLoggedIn?: boolean;
  email: string;
  'jwt-token': string;
  lastLoggedInAt: string;
  uid: string;
  showBanner: boolean;
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  ref?: string;
}

interface UserContextProps {
  userInfo: UserInfo;
  setUserData: Function;
  index: any;
  searchClient?: any;
}

const initialUserState = {
  isUserLoggedIn: false,
  email: '',
  'jwt-token': '',
  lastLoggedInAt: '',
  uid: '',
  showBanner: true,
  id: '',
  firstName: '',
  lastName: '',
  userName: '',
};

const UserContext = createContext<UserContextProps>({
  userInfo: initialUserState,
  setUserData: () => {},
  index: () => {},
  searchClient: () => {},
});

const UserContextProvider = ({ index, children, searchClient }: any) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(initialUserState);
  const [localState, setLocalState] = useLocalStorage(
    AMK_LS_KEYS.MAIN_APP,
    initialUserState,
  );

  const setUserData = (updateUserData: UserInfo) => {
    setUserInfo({ ...localState, ...updateUserData });
    setLocalState({
      ...localState,
      ...updateUserData,
    });
  };

  useEffect(() => {
    if (localState) {
      setUserInfo(localState);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserData,
        index,
        searchClient,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
