/* eslint-disable no-undef */

import { createTheme, colors } from '@mui/material';

declare module '@mui/material/Typography' {
    // eslint-disable-next-line no-unused-vars
    interface TypographyPropsVariantOverrides {
        createContent: true;
        videoUpload: true;
        contentTitle: true;
    }
}

const theme = {
    typography: {
        fontFamily: ['Neue Montreal','termina-medium', 'sans-serif'].join(','),
        fontSize: 14,
        subtitle2: {
            fontSize: '11px',
            lineHeight: '25px',
        },
        createContent: {
            fontSize: '11px',
            fontFamily: ['termina-demi', 'sans-serif'].join(','),
        },
        videoUpload: {
            fontSize: '12px',
            fontFamily: ['termina-demi', 'sans-serif'].join(','),
        },
        contentTitle: {
            fontSize: '55px',
            fontFamily: ['Canela-light', 'sans-serif'].join(','),
            lineHeight: '65px',
        },
        small: {
            fontSize: '14px',
            lineHeight: '20px', 
            fontFamily: ['Neue Montreal','termina-medium', 'sans-serif'].join(',')
        },
        extraSmall: {
            fontSize: '12px',
            lineHeight: '18px',
            fontFamily: ['Neue Montreal','termina-medium', 'sans-serif'].join(',')
        },
    },
    status: {
        success: colors.green[500],
        danger: colors.red[500],
        warn: colors.yellow[500],
    },
};

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        text: {
            secondary: 'white',
        },
        primary: {
            main: '#000',
            contrastText: '#fff',
            light: '#fff'
        },
        secondary: {
            main: '#2C36F0',
        },
        error: {
            main: '#FF001D',
        },
    },
    shape: {
        borderRadius: 10
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    paddingTop: 13,
                    paddingBottom: 13,
                    fontFamily: 'forma-djr-regular',
                    fontSize: '16px',
                },
                endAdornment: {
                    top: 'calc(50% - 20px)',
                },
                noOptions: {
                    color: '#000',
                    fontFamily: 'forma-djr-regular',
                    fontSize: '16px',
                },
                input: {
                    fontFamily: 'forma-djr-regular',
                    fontSize: '16px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                // disabled: {
                root: {
                    '&.Mui-disabled': {
                        color: '#717375',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    paddingBottom: 12,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
                indicator: {
                    alignItems: 'flex-start',
                    height: 8,
                    borderRadius: 4,
                },
                scroller: {
                    borderBottom: '1px solid #000000',
                },
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    // Common styles for all buttons
                    borderRadius: '10px',
                    padding: '10px 20px',
                },
                containedPrimary: {
                    // Styles for primary contained buttons
                    backgroundColor: '#2C36F0',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#242FB4',
                    },
                },
                outlinedPrimary: {
                    // Styles for primary contained buttons
                    backgroundColor: '#fff',
                    border: "1px solid #2C36F0",
                    color: '#2C36F0',
                    '&:hover': {
                        backgroundColor: '#242FB4',
                        color: "#fff",
                        border: "1px solid #2C36F0",
                    },
                },
            },
        },
    },
    ...theme,
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: 'black',
        },
        text: {
            secondary: 'black',
        },
        primary: {
            main: '#fff',
            contrastText: '#000',
            light: '#1A1A1A',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
        error: {
            main: '#FF001D',
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    borderBottom: '1px solid #ffffff',
                    paddingTop: 13,
                    paddingBottom: 13,
                    fontFamily: 'forma-djr-regular',
                    fontSize: '16px',
                    color: '#717375',
                },
                endAdornment: {
                    top: 'calc(50% - 20px)',
                },
                noOptions: {
                    color: '#fff',
                    fontFamily: 'forma-djr-regular',
                    fontSize: '16px',
                },
                input: {
                    fontFamily: 'forma-djr-regular',
                    fontSize: '16px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                // disabled: {
                //     color: '#717375',
                // },
                root: {
                    '&.Mui-disabled': {
                        color: '#717375',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    paddingBottom: 12,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 10,
                },
                indicator: {
                    alignItems: 'flex-start',
                    height: 8,
                    borderRadius: 4,
                },
                scroller: {
                    borderBottom: '1px solid #ffffff',
                },
                root: {
                    borderBottom: 'none',
                },
            },
        },
    },
    ...theme,
});

export default lightTheme;
