import React, { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { DialogStack, StyledCloseIcon, StyledDialog, StyledDialogContentText, StyledDialogTitle } from './styles';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import { IconButton, Stack, Typography } from '@mui/material';
import { FacebookIcon, LinkedInIcon, WhatsAppIcon, XIcon } from '../../../common/SocialMediaIcons';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { StyledShareImage } from '../../../common/ShareDialog/style';
import { useLoading } from '../../../../context/LoadingContext';
import { StyledAlert } from '../styles';
import { UploadIcon } from '../../../../assets/icons/UploadIcon';
import { isMobileSafari } from 'react-device-detect';
import { SegmentTrack } from '../../../../components/SegmentAnalytics';
import useAuth from '../../../../hooks/useAuth';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    refCode: string;
    link: string;
    isMobile: boolean;
}

type PlatformKey = keyof typeof shareButtons;

const shareButtons = {
    X: TwitterShareButton,
    Facebook: FacebookShareButton,
    // Instagram: ShareOnInstagram,
    WhatsApp: WhatsappShareButton,
    LinkedIn: LinkedinShareButton,
    // TikTok: ShareOnTikTok
};

interface ShareProps {
    text: string;
    title: string;
    url: string;
}

export const SocialDialog: React.FC<Props> = ({ isOpen, onClose, refCode, link, isMobile }) => {
    const [copied, setCopied] = React.useState<boolean>(false);
    const title = "Join My Community!";
    const quote = "Hey there! Would love for you to join my awesome community! Click the link below to stay updated with the latest content and exciting updates!";
    const { setSiteLoader } = useLoading();
    const [url, setUrl] = useState<string>("")
    const [shareImage] = useState(null)
    const { userInfo } = useAuth();

    const getShareImage = async () => {
        setSiteLoader(true);
        setSiteLoader(false);
    };

    useEffect(() => {
        getShareImage();
        setUrl(`${link}?ref=${refCode}`)
    }, [refCode])

    const shareVia = ({ text, title, url }: ShareProps) => {
        return (

            <IconButton
    
                onClick={() => {
                    if (!navigator.share) {
                        navigator.clipboard.writeText(url).then(() => setCopied(true));
                    } else {
                        navigator.share({
                            title: title,
                            text: text,
                            url: url
                        });
                    }
                    SegmentTrack({
                        name: 'import_subscribers',
                        properties: {
                            "userId": userInfo.uid,
                            "source": "share",
                            platform: "mobile",
                            text,
                            title,
                            "link": url,
                            "date_created": new Date().toISOString(),
                        }
                    })
                }}
            >
                <Stack 
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    padding="15px"
                    marginTop="-8px"
                    sx={{ height: "55px", width: "auto", backgroundColor: "#D5D7FC", borderRadius: "50%"}}>
                    <UploadIcon />
                </Stack>
                
            </IconButton>
        )
    }

    const renderPlatformButton = (platform: PlatformKey) => {
        if (platform === 'X' && isMobile) {
            return (
                <Stack key={platform} alignItems={"center"}>
                    {shareVia({
                        text: quote,
                        title: title,
                        url: `${url}&utm_source=${platform}`
                    })}
                    <Typography>Share Via</Typography>
                </Stack>
            );
        } else {
            const ShareButtonComponent = shareButtons[platform];
            return (
                <Stack key={platform} alignItems={"center"}>
                    <ShareButtonComponent
                        url={`${url}&utm_source=${platform}`}
                        title={title}
                        quote={quote}
                        onClick={() => {
                            console.log("segment clicked")
                            SegmentTrack({
                                name: 'import_subscribers',
                                properties: {
                                    "userId": userInfo.uid,
                                    "source": "share",
                                    platform,
                                    title,
                                    quote,
                                    "link": `${url}&utm_source=${platform}`,
                                    "date_created": new Date().toISOString(),
                                }
                            })
                        }}
                    >
                        {platform === 'X' ? <XIcon /> :
                            platform === 'Facebook' ? <FacebookIcon /> :
                                platform === 'WhatsApp' ? <WhatsAppIcon /> :
                                    platform === 'LinkedIn' ? <LinkedInIcon />
                                        : <></>
                        }
                    </ShareButtonComponent>
                    <Typography>{platform}</Typography>
                </Stack>
            );
        }
    };

    return (
        <>
        <StyledDialog fullWidth={true} maxWidth="sm" open={isOpen} onClose={onClose}>
            <StyledCloseIcon aria-label="close" onClick={onClose} sx={{ color: '#ff0035' }}>
                <CloseIcon />
            </StyledCloseIcon>
            <StyledDialogTitle>Social Post or Story</StyledDialogTitle>
            <DialogContent sx={{ color: "black", padding: 0 }}>
                    <StyledDialogContentText>Invite your friends to join using your favorite social media platform!</StyledDialogContentText>
                <div style={{ whiteSpace: 'nowrap' }}>
                    
                    <DialogStack spacing={3} direction={"row"}>
                        {isMobileSafari && <Stack sx={{height: "100%", width: "100px"}} />}
                        {Object.keys(shareButtons).map((platform) => (
                            renderPlatformButton(platform as PlatformKey)
                        ))}
                    </DialogStack>
                </div>
                {shareImage && (
                    <StyledShareImage
                        imgUrl={shareImage}
                        aria-label="Share Image"
                    >
                        <img src={shareImage} alt="Share Image" />
                    </StyledShareImage>
                )}
            </DialogContent>
        </StyledDialog>
            {copied && (
                <StyledAlert
                    sx={{ zIndex: 10000 }}
                    onClose={() => setCopied(false)}
                    severity="success"
                >
                    Link Copied
                </StyledAlert>
            )}
        </>
    );
};

