/* eslint-disable no-unused-vars */
import React, { createContext, useState } from 'react';

interface GigListingContextType {
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
  setGigId: (arg: number) => void;
  filters: {
    experience_level: string;
    budget_type: string;
    gig_duration: string;
    date_posted: string;
    location: string;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      experience_level: string;
      budget_type: string;
      gig_duration: string;
      date_posted: string;
      location: string;
    }>
  >;
}

export const GigListingContext = createContext<GigListingContextType>({
  id: 0,
  setId: () => {},
  setGigId: () => {},
  filters: {
    experience_level: '',
    budget_type: '',
    gig_duration: '',
    date_posted: '',
    location: '',
  },
  setFilters: () => {},
});

interface GigListingContextProviderType {
  children: React.ReactNode;
}

export const GigListingContextProvider = ({
  children,
}: GigListingContextProviderType) => {
  const [id, setId] = useState(0);
  const [filters, setFilters] = useState({
    experience_level: '',
    budget_type: '',
    gig_duration: '',
    date_posted: '',
    location: '',
  });

  const setGigId = (gigId: number) => {
    setId(gigId);
  };

  return (
    <GigListingContext.Provider
      value={{ id, setId, setGigId, filters, setFilters }}
    >
      {children}
    </GigListingContext.Provider>
  );
};
