import axios from 'axios';
import { fetchData } from '..';
import { getDomain } from '../../utils';
import { getValueFromLocalStore } from '../../utils/localStorage';
import { STORAGE_KEYS } from '../../constants/storage';

const SUBSCRIPTION_API = 'api/subscription';
const SUBSCRIPTIONS_API = 'api/subscriptions';

export const getSubscription = async (userId: string, authorId: string) => {
    try {
        if (!userId || !authorId) {
            return {};
        }
        const url = `${getDomain()}/${SUBSCRIPTION_API}/get-subscription-info?userId=${userId}&authorId=${authorId}`;
        const response = fetchData({
            apiEndpoint: url,
            errorMessage: 'Failed to fetch subscription data',
            requiresAuthorization: true,
        });
        return response;
    } catch (error) {
        return null;
    }
};

export const getSubscripitonsByAuthorId = async (
    userId: number,
    page: number,
    limit: number,
    orderBy?: string,
    order?: string,
) => {
    const url = `${getDomain()}/${SUBSCRIPTIONS_API}?userId=${userId}&page=${page}&limit=${limit}${
        orderBy ? `&orderBy=${orderBy}&order=${order}` : ''
    }`;
    return fetchData({
        apiEndpoint: url,
        errorMessage: 'Failed to fetch subscription data',
        requiresAuthorization: true,
    });
};

export const saveSubscriptionInfo = async (data: any) => {
    try {
        const payload = {
            subscriberId: getValueFromLocalStore(STORAGE_KEYS.UID),
            ...data,
        };
        const url = getDomain() + `/${SUBSCRIPTION_API}/save-subscription-info`;
        const response = await axios.put(
            url,
            {
                data: payload,
            },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        throw {
            message: error,
            status: error.response.status || 500,
        };
    }
};

export const cancelSubscription = async (authorId: string) => {
    try {
        const url = getDomain() + `/${SUBSCRIPTION_API}/cancel-subscription`;
        const response = await axios.put(
            url,
            {
                data: {
                    authorId: authorId,
                    subscriberId: getValueFromLocalStore(STORAGE_KEYS.UID),
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${getValueFromLocalStore(
                        STORAGE_KEYS.JWT_TOKEN,
                    )}`,
                },
            },
        );
        return response.data;
    } catch (error: any) {
        throw {
            message: error,
            status: error.response.status || 500,
        };
    }
};

export const sendNewSubscriberNotification = async (data: any) => {
    try {
        const apiUrl =
            'https://sendnotifications-tzr467ixna-uc.a.run.app/sendNewSubscriberNotification'; // Firebase Cloud function URL
        const response = await axios.post(apiUrl, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log(
            'Firebase Cloud Messaging triggered new user notification successfully!',
        );

        return response;
    } catch (error: any) {
        console.error('Error:', error);
        throw {
            message: 'Failed to trigger like FCM',
            status: error.response.status || 500,
        };
    }
};
