import React, { createContext, useContext, useState } from "react";
import Loader from "../components/common/Loader";

interface LoadingContextProps {
  loading: boolean;
  setSiteLoader: any
}

const LoadingContext = createContext<LoadingContextProps>({
  loading: false,
  setSiteLoader: () => { },
});

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [loading, setSiteLoader] = useState<boolean>(false);
  const value = { loading, setSiteLoader };
  return (
    <LoadingContext.Provider value={value}>
      {children}
      <Loader open={loading} />
    </LoadingContext.Provider>
  );
}

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}