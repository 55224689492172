/* eslint-disable no-unused-vars */
import { Stack } from "@mui/material";
import { FC } from "react";
import { StyledButton, StyledTypography } from "../../styles";
import { PlusIcon } from "../../../../../assets/icons/PlusIcon";


export const ManageContentHeader: FC<{
    page: number;
    records: any[];
    total: number;
    isMobile: boolean;
    rowsPerPage: number;
    importContentButton: () => void;
}> = ({
    page,
    records,
    total,
    isMobile,
    rowsPerPage,
    importContentButton
}) => {
        return (
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginBottom={'20px'}
            >
                {/* Result count */}
                <StyledTypography
                    sx={{
                        fontSize: isMobile ? 14 : 16,
                        fontWeight: 500,
                    }}
                >
                    Showing {page * rowsPerPage + records.length} of {total} results
                </StyledTypography>

                <Stack>
                    <StyledButton
                        onClick={importContentButton}
                        startIcon={<PlusIcon color={'#2C36F0'} />}
                        variant="outlined"
                    >
                        Import Content
                    </StyledButton>

                </Stack>

            </Stack>
        );
    };