import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Wrapper } from "./styles"
import { DropdownMenu } from "../../common/DropdownMenu"
import { CSVIcon } from "../../../assets/icons/CSVIcon"
import { SocialPostIcon } from "../../../assets/icons/SocialPostIcon"
import { ChainIcon } from "../../../assets/icons/ChainIcon"
import { FC, useEffect, useState } from "react"
import { ImportDialog } from "./ImportDialog/ImportDialog"
import { PlusIcon } from "../../../assets/icons/PlusIcon"
import { SocialDialog } from "./SocialDialog/Index"
import { CopyLinkDialog } from "./CopyLinkDialog/Index"
import SubscriberFetcher from "./SubscribersTable"
import useAuth from "../../../hooks/useAuth"
import { registerReferralByReferralCode } from "../../../apis/Challenges"
import { CAMPAIGN_OBJECTIVES } from "../../../constants"
import { ViralLoopsResult } from "./types"

export const DashboardAudience: FC = () => {
    const theme = useTheme();
    const { userInfo } = useAuth();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
    const [socialImportDialog, setSocialImportDialog] = useState<boolean>(false);
    const [referralDialog, setReferralDialog] = useState<boolean>(false);
    const [subscribeUrl, setSubscribeUrl] = useState<string>("")

    const handleCloseImport = () => setOpenImportDialog(false);
    const handleSocialImport = () => setSocialImportDialog(false);
    const handleReferralImport = () => setReferralDialog(false);
    const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);

    const [refCode, setRefCode] = useState<string>(userInfo.ref ?? "")

    const generateRef = async () => {
        try {
            const payload = {
                user: {
                    firstname: userInfo.firstName,
                    lastname: userInfo.lastName,
                    email: userInfo.email,
                },
                extraData: { uid: userInfo.uid },
                referrer: {
                    referralCode: "",
                },
            };
            const result = await registerReferralByReferralCode(
                payload,
                CAMPAIGN_OBJECTIVES.ONBOARDING,
            ) as ViralLoopsResult;
            setRefCode(result?.data?.referralCode ?? "")


        } catch (error) {
            console.log(error)
            /* empty */
        }
    };
    useEffect(() => {
        if (!userInfo.ref) {
            generateRef(); //regenerate ref if not available
        }
        else {
            setRefCode(userInfo.ref)
        }
        setSubscribeUrl(`${window.location.origin}/subscribe/${userInfo.uid}`)
    }, [userInfo.id]);

    return (
        <Wrapper>
            <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={"center"}>
                <Stack>
                    <Typography fontWeight={'bold'}>Subscribers</Typography>

                    {!isMobile && <Typography>Subscribers that make up this segment</Typography>}
                </Stack>

                <Stack>
                    <DropdownMenu
                        buttonText="Import Subscribers"
                        buttonIcon={<PlusIcon color={'#2C36F0'} />}
                        setHoveredItemIndex={setHoveredItemIndex}
                        menuItems={[
                            {
                                icon: <CSVIcon color={hoveredItemIndex === 0 ? "#2C36F0" : "#808080"} />,
                                text: 'CSV Import',
                                onClick: () => setOpenImportDialog(true),
                            },
                            {
                                icon: <SocialPostIcon color={hoveredItemIndex === 1 ? "#2C36F0" : "#808080"} />,
                                text: 'Social Post or Story',
                                onClick: () => setSocialImportDialog(true),
                            },
                            {
                                icon: <ChainIcon color={hoveredItemIndex === 2 ? "#2C36F0" : "#808080"} />,
                                text: 'Get a Referral Link',
                                onClick: () => setReferralDialog(true),
                            },
                        ]}
                    />

                </Stack>

            </Stack>
            {isMobile && <Stack sx={{ marginTop: "10px" }}><Typography>Subscribers that make up this segment</Typography></Stack>}
            <Stack height={'100%'} alignItems={'center'} justifyItems={'center'} sx={{ padding: '20px 0' }}>
                <SubscriberFetcher isMobile={isMobile} />
            </Stack>
            <ImportDialog link={subscribeUrl} refCode={refCode} isOpen={openImportDialog} onClose={handleCloseImport} />
            <SocialDialog link={subscribeUrl} refCode={refCode} isOpen={socialImportDialog} onClose={handleSocialImport} isMobile={isMobile} />
            <CopyLinkDialog
                isOpen={referralDialog}
                onClose={handleReferralImport}
                title="Get Your Unique Referral Link"
                description="Share your referral link with friends to earn rewards when they sign up!"
                link={`${subscribeUrl}?ref=${refCode}&utm_source=${encodeURIComponent('Referral Link')}`}
                segmentTrackData={{ name: 'invite_referrals', properties: { ref: refCode, userId: userInfo.uid } }}
            />
        </Wrapper>
    );
};
