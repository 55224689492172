import { ReactNode } from 'react';
import ArticleIcon from '../../assets/icons/CreateContentPrompt/ArticleIcon';
import VideoIcon from '../../assets/icons/CreateContentPrompt/VideoIcon';
import CommentIcon from '../../assets/icons/CreateContentPrompt/ComentIcon';
// import EventIcon from '../../assets/icons/CreateContentPrompt/EventIcon';
// import JobIcon from '../../assets/icons/CreateContentPrompt/JobIcon';
// import EmbedIcon from '../../assets/icons/CreateContentPrompt/EmbedIcon';
// import PollIcon from '../../assets/icons/CreateContentPrompt/PollIcon';
// import { APP_FEATURE } from '../../constants/config';

export interface ContentType {
    ID: number;
    TYPE: string;
    TITLE: string;
    ICON: ReactNode;
    ROUTE: string;
    ACTIVE: boolean;
}

export const CONTENT_TYPES: ContentType[] = [
    {
        ID: 1,
        TYPE: 'article',
        TITLE: 'Article',
        ICON: <ArticleIcon />,
        ROUTE: '/article/content',
        ACTIVE: true,
    },
    {
        ID: 2,
        TYPE: 'comment',
        TITLE: 'Thought',
        ICON: <CommentIcon />,
        ROUTE: '/article/content',
        ACTIVE: true,
    },
    {
        ID: 3,
        TYPE: 'video',
        TITLE: 'Video',
        ICON: <VideoIcon />,
        ROUTE: '/article/content',
        ACTIVE: true,
    },
    // {
    //     ID: 4,
    //     TYPE: 'poll',
    //     TITLE: 'Poll',
    //     ICON: <PollIcon />,
    //     ROUTE: '/article/content',
    //     ACTIVE: true,
    // },
    // {
    //     ID: 5,
    //     TYPE: 'event',
    //     TITLE: 'Event',
    //     ICON: <EventIcon />,
    //     ROUTE: '/event/create',
    //     ACTIVE: APP_FEATURE.EVENT,
    // },
    // {
    //     ID: 6,
    //     TYPE: 'job',
    //     TITLE: 'Job',
    //     ICON: <JobIcon />,
    //     ROUTE: '/article/content',
    //     ACTIVE: false,
    // },
    // {
    //     ID: 7,
    //     TYPE: 'embeded',
    //     TITLE: 'Embeded',
    //     ICON: <EmbedIcon />,
    //     ROUTE: '/article/content',
    //     ACTIVE: false,
    // },
];
