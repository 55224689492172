import { Navigate } from 'react-router';
import { ROUTES } from '../../../constants/routeDefinition';
import { getValueFromLocalStore } from '../../../utils/localStorage';
import { STORAGE_KEYS } from '../../../constants/storage';
function PublicRoute({ children }: any) {
    const isUserLoggedIn = getValueFromLocalStore(
        STORAGE_KEYS.IS_USER_LOGGED_IN,
    );
    if (isUserLoggedIn) {
        return <Navigate to={ROUTES.LAYOUT.PATH} replace />;
    }

    return children;
}
export default PublicRoute;
