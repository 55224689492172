export const STORAGE_KEYS = {
    UID: 'uid',
    IS_USER_LOGGED_IN: 'isUserLoggedIn',
    JWT_TOKEN: 'jwt-token',
    EMAIL: 'email',
    LAST_ACTIVE_DATE: 'lastLoggedInAt',
    ID: 'id',
    REDIRECT_URL: 'redirectUrl',
    REDIRECT_FROM: 'redirectFrom',
    REFFERAL: 'refferalCode',
    CLOSE_CHALLENGE: 'closeChallenge',
    REFERRAL_CODE: 'referralCode',
    USERAMK_REFERRAL_CODE: 'useramkReferralCode',
    SUBSCRIPTION_INFO: 'subscriptionInfo',
    APP_VERSION: 'app_version',
    IMPRESSION: 'impressions',
    IS_GOOGLE_SIGNUP: 'is_google_signup',
};
