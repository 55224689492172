import { Navigate, useLocation } from 'react-router';
import { ROUTES} from '../../../constants/routeDefinition';
import { getValueFromLocalStore, setValueInLocalStore } from '../../../utils/localStorage';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { STORAGE_KEYS } from '../../../constants/storage';
const ProtectedRoutes = ({ children }: any) => {
    const isUserLoggedIn = getValueFromLocalStore(
        STORAGE_KEYS.IS_USER_LOGGED_IN,
    );
    const [searchParams] = useSearchParams();
    const location = useLocation()

    const referralCode = (searchParams.get(STORAGE_KEYS.REFERRAL_CODE) || '').toString();
    const ref = (searchParams.get('ref') || '').toString();

    useEffect(() => {
        handleReferrals();
    }, []);
    
    const handleReferrals = ()=>{
        if (referralCode) {
            setValueInLocalStore(STORAGE_KEYS.REFERRAL_CODE, referralCode)
        }
        if(ref){
            setValueInLocalStore(STORAGE_KEYS.USERAMK_REFERRAL_CODE, ref)
        }
    }
    if (!isUserLoggedIn) {
        const redirectFrom = location.pathname + location.search;

        return <Navigate to={ROUTES.LOGIN.PATH} replace state={{
            redirect: redirectFrom,
            redirectFrom: redirectFrom,
          }} />;
    }
    return children;
};

export default ProtectedRoutes;
