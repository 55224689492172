
type iProps = {
    color?: string;
}

export const ChainIcon = ({ color = "#808080" }: iProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1213 9.87891L9.8787 14.1215" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
            <path d="M10.3332 7.00003L11.9999 5.33336C13.8409 3.49239 16.8256 3.49239 18.6666 5.33335V5.33335C20.5076 7.17431 20.5076 10.1591 18.6666 12.0001L16.9999 13.6667" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
            <path d="M13.6665 17L11.9999 18.6666C10.1589 20.5076 7.17411 20.5076 5.33315 18.6666V18.6666C3.49219 16.8257 3.49219 13.8409 5.33315 11.9999L6.99983 10.3333" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
        </svg>
    )

}