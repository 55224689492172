import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const VideoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="16" 
            height="16"
            fill="none"
            {...props}
        >
            <path d="M11.3074 9.15763L5.99485 12.1934C5.10598 12.7013 4 12.0595 4 11.0357V4.96421C4 3.94045 5.10598 3.29862 5.99485 3.80655L11.3074 6.84231C12.2032 7.35417 12.2032 8.64577 11.3074 9.15763Z" stroke="#2C36F0" strokeWidth="1.2" strokeLinejoin="round"/>
    </SvgIcon>
  )
}

export default VideoIcon