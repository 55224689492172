import Routes from './constants/routes';
// import { CssBaseline } from '@mui/material';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import packageJson from '../package.json';
import algoliasearch from 'algoliasearch/lite';
import { UserContextProvider } from './context/UserContext';
import { CustomThemeProvider } from './context/ThemeContext';

import 'swiper/swiper-bundle.css';
import { ALGOLIA, ALGOLIA_INDEX } from './constants';
import aa from 'search-insights';
import algoliarecommend from '@algolia/recommend';
import { useEffect } from 'react';
import {
  clearLocalStore,
  getAllAMKValueFromLocalStore,
  getValueFromLocalStore,
  setAllAMKValueFromLocalStore,
  setValueInLocalStore,
} from './utils/localStorage';
import { LoadingProvider } from './context/LoadingContext';
import { setMetaTagsForSite } from './utils';
import { NotificationProvider } from './context/NotificationContext';
import { nanoid } from 'nanoid';
import { STORAGE_KEYS } from './constants/storage';
import { CssBaseline } from '@mui/material';
import { SocketProvider } from './context/Socket';
import { TemplatesProvider } from './context/Templates';
import { GigListingContextProvider } from './context/GigListingContext';
import { Toaster } from 'sonner';
import { MessageNotificationProvider } from 'context/MessageNotificationContext';
import { PostHogProvider } from 'posthog-js/react';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const router = createBrowserRouter(createRoutesFromElements(Routes));

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  ui_host: process.env.REACT_APP_PUBLIC_POSTHOG_UI_HOST,
};

export const searchClient = algoliasearch(
  ALGOLIA.APPLICATION_ID,
  ALGOLIA.PUBLIC_KEY,
);
export const recommendClient = algoliarecommend(
  ALGOLIA.APPLICATION_ID,
  ALGOLIA.PUBLIC_KEY,
);
const index = searchClient.initIndex(ALGOLIA_INDEX.ARTICLE);

aa('init', {
  appId: ALGOLIA.APPLICATION_ID,
  apiKey: ALGOLIA.PUBLIC_KEY,
});

function App() {
  // Create the config for the SDK factory.
  // const sdkConfig: SplitIO.IBrowserSettings = {
  //     core: {
  //         authorizationKey: 'YOUR_CLIENT_SIDE_SDK_KEY',
  //         // key represents your internal user id, or the account id that
  //         // the user belongs to.
  //         // This could also be a cookie you generate for anonymous users.
  //         key: userInfo.uid || nanoid()
  //     }
  // };
  useEffect(() => {
    setMetaTagsForSite();
  }, []);
  // const userId = getValueFromLocalStore(STORAGE_KEYS.UID) || nanoid();

  // const client = factory.client();
  // client.on(client.Event.SDK_READY, function() {
  //     setTimeout(()=>{
  //         const treatment = client.getTreatment(userId,SPLIT_TREATMENT.THEME_FLAG);
  //         const queue =  client.track('user','split_theme_loaded',0,{userId,treatment:SPLIT_TREATMENT.THEME_FLAG});
  //         console.log('Iqueur', {queue,treatment},client.Event.SDK_READY);
  //     },1000)

  let version = getValueFromLocalStore(STORAGE_KEYS.APP_VERSION);
  console.log(`Checking version`, version > packageJson.version);

  // })

  const caching = async () => {
    try {
      const { fetchAppDetails } = await import('./apis/app');

      let response = await fetchAppDetails();
      const uiVersion = getValueFromLocalStore(STORAGE_KEYS.APP_VERSION);
      const version = response?.data?.attributes?.version;
      if (version != uiVersion) {
        const tempAMK = getAllAMKValueFromLocalStore();
        if ('caches' in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          // window.location.reload();
        }

        clearLocalStore();

        setAllAMKValueFromLocalStore(JSON.stringify(tempAMK));

        setValueInLocalStore(STORAGE_KEYS.APP_VERSION, version);
        console.log(`AMAKA VERSION: ${version}`);
      }
    } catch (error) {
      //silently ignore
    }
  };
  useEffect(() => {
    if (!getValueFromLocalStore(STORAGE_KEYS.UID)) {
      setValueInLocalStore(STORAGE_KEYS.UID, nanoid());
    }
    setTimeout(caching, 3000);

    // loadEpomSnippetScript();
  }, []);

  return (
    // <SplitFactory factory={factory} >
    // <StrictMode>
    <CustomThemeProvider>
      <CssBaseline />
      {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
      <UserContextProvider index={index} searchClient={searchClient}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <SocketProvider>
            <TemplatesProvider>
              <LoadingProvider>
                <MessageNotificationProvider>
                  <NotificationProvider>
                    <GigListingContextProvider>
                      {/* <InstantSearch
                                searchClient={searchClient}
                                indexName={ALGOLIA_INDEX.ARTICLE}>
                                <Configure
                                    clickAnalytics
                                    enablePersonalization
                                    hitsPerPage={5}
                                /> */}

                      <RouterProvider router={router} />
                      {/* </InstantSearch> */}
                    </GigListingContextProvider>
                  </NotificationProvider>
                </MessageNotificationProvider>
              </LoadingProvider>
            </TemplatesProvider>
          </SocketProvider>
        </PostHogProvider>
      </UserContextProvider>

      {/* </LocalizationProvider> */}
      <Toaster
        theme="light"
        duration={5000}
        offset={16}
        richColors
        closeButton
        toastOptions={{
          style: {
            fontSize: '1rem',
            borderRadius: '0.8rem',
          },
        }}
      />
    </CustomThemeProvider>
    //</StrictMode>
    // </SplitFactory>
  );
}
export default App;
