import axios from 'axios';
import { getDomain } from '../../utils';
import { getValueFromLocalStore } from '../../utils/localStorage';
import { STORAGE_KEYS } from '../../constants/storage';
import { fetchData } from '..';
import { NOTIFICATIONS_LIMIT } from '../constants';

const NOTIFICATIONS_API = 'api/notification';

export const fetchUserNotifications = async (page: number) => {
  const url = `${getDomain()}/${NOTIFICATIONS_API}/fetch-user-notifications?userId=${getValueFromLocalStore(
    STORAGE_KEYS.UID,
  )}&limit=${NOTIFICATIONS_LIMIT}&page=${page}`;

  return fetchData({
    apiEndpoint: url,
    errorMessage: 'Failed to fetch user notifications',
    requiresAuthorization: true,
  });
};

export const fetchMessageNotifications = async () => {
  const url = `${getDomain()}/${NOTIFICATIONS_API}/fetch-user-message-notifications?userId=${getValueFromLocalStore(
    STORAGE_KEYS.UID,
  )}&limit=${50}&pageSize=50`;

  return fetchData({
    apiEndpoint: url,
    errorMessage: 'Failed to fetch user notifications',
    requiresAuthorization: true,
  });
};

export const markAllNotificationsAsRead = async () => {
  try {
    const url = `${getDomain()}/${NOTIFICATIONS_API}/mark-notifications-as-done?userId=${getValueFromLocalStore(
      STORAGE_KEYS.UID,
    )}`;
    return fetchData({
      apiEndpoint: url,
      errorMessage: 'Failed to mark notifications as read',
      requiresAuthorization: true,
    });
  } catch (error: any) {
    throw {
      message: error,
      status: error.response.status || 500,
    };
  }
};

export const markChatRoomMessagesAsRead = async (chatRoomId: number) => {
  try {
    const url = `${getDomain()}/${NOTIFICATIONS_API}/mark-message-notifications-as-done?userId=${getValueFromLocalStore(
      STORAGE_KEYS.UID,
    )}&chatRoomId=${chatRoomId}`;
    return fetchData({
      apiEndpoint: url,
      errorMessage: 'Failed to mark notifications as read',
      requiresAuthorization: true,
    });
  } catch (error: any) {
    throw {
      message: error,
      status: error.response.status || 500,
    };
  }
};

export const markNotificationAsRead = async (id: number) => {
  try {
    const response = await axios.put(
      `${getDomain()}/api/notifications/${id}`,
      {
        data: {
          is_read: true,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${getValueFromLocalStore(
            STORAGE_KEYS.JWT_TOKEN,
          )}`,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to mark notification as read',
      status: error.response.status || 500,
    };
  }
};

export const sendNotification = async (
  notificationType: string,
  authorId: string,
  articleId: string,
  userId?: string,
) => {
  try {
    const response = await axios.post(
      `${getDomain()}/${NOTIFICATIONS_API}/send-notification`,
      {
        data: {
          userId: userId ?? undefined,
          authorId: authorId,
          articleId: articleId,
          notificationType: notificationType,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw {
      message: 'Failed to send notification',
      status: error.response.status || 500,
    };
  }
};
