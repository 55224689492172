import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import useAuth from '../hooks/useAuth';
import { getMessageTemplates } from 'apis/Messages';

type Template = {
  id: number;
  attributes: {
    name: string;
    creator_template?: string;
    brand_template?: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
};

type ITemplatesContext = {
  messageTemplates: Template[];
};

const TemplatesContext = createContext<ITemplatesContext | undefined>(
  undefined,
);

type TemplatesProviderProps = {
  children: ReactNode;
};

export const TemplatesProvider = ({ children }: TemplatesProviderProps) => {
  const [messageTemplates, setMessageTemplates] = useState<Template[]>([]);
  const { userInfo } = useAuth();

  useEffect(() => {
    if (userInfo.isUserLoggedIn) {
      getMessageTemplates().then((response: any) => {
        if (response.data) {
          setMessageTemplates(response.data);
        }
      });
    }
  }, [userInfo]);

  return (
    <TemplatesContext.Provider value={{ messageTemplates }}>
      {children}
    </TemplatesContext.Provider>
  );
};

export const useMessageTemplates = (): ITemplatesContext => {
  const context = useContext(TemplatesContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a TemplatesProvider');
  }
  return context;
};
