
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';


export const FileInputWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px; // Add some spacing between input and button
`;

export const StyledTextField = styled(TextField)(() => ({
    backgroundColor: 'white',
    flexGrow: 1,
    height: '100%',
    borderRadius: "20px",
    '& .MuiInputBase-root': {
        height: '100%', // Match button height
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px', // Adjust padding
    },
    '& .MuiOutlinedInput-root': {
        '&:hover, &:focus': {
            borderColor: "#bdbdbd", // Light gray border on hover or focus
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:focus': {
        borderRadius: "20px",
    },
}));

export const UploadButton = styled(Button)`
    border: 1px solid #2C36F0;
    margin: 0;
    padding: 8px 10px;
    height: 100%; // Match input height
    min-width: auto;
    color: #000000;
    background-color: #ffffff;
    &:hover {
        background-color: #242FB4;
        color: #fff;
    }
`;