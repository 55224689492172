import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CommentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="16" 
            height="16"
            fill="none"
            {...props}
        >
        <path d="M7.66667 12.7384H11.8506C12.0838 12.7384 12.2449 12.505 12.1623 12.287L11.8306 11.4115C11.6563 10.9515 11.7517 10.4344 12.0327 10.0308C12.455 9.42422 13 8.48332 13 7.66661M11 6C11 8.76142 8.76142 11 6 11H1.48275C1.24954 11 1.08841 10.7667 1.17104 10.5486L1.59841 9.42061C1.75123 9.01728 1.68847 8.57003 1.50001 8.18207C1.17967 7.52269 1 6.78233 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" stroke="#2C36F0" strokeWidth="1.2" strokeLinecap="round"/>
    </SvgIcon>
  )
}

export default CommentIcon